<template>
    <div>
      <v-card>
          <v-card-title>Cotización</v-card-title>
          <v-card-text>
              <v-simple-table>
                  <template v-slot:default>
                      <thead>
                          <tr>
                              <th class="text-left">
                                  Nombre
                              </th>
                              <th class="text-left">
                                  Descripción
                              </th>
                              <th class="text-left">
                                  Fecha
                              </th>
                              <th class="text-left">
                                Tipo de cambio
                              </th>
                              <th>
                                Fecha tipo de cambio
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>{{ bid.name }}</td>
                              <td>{{ bid.description }}</td>
                              <td>{{ formatDate.dayMonthAndYear(bid.date)}}</td>
                              <td>{{ bid.exchange.dollar_exchange | VMask(currencyMask)}}</td>
                              <td>{{ formatDate.dayMonthAndYear(bid.exchange.date_exchange) }}</td>
                          </tr>
                      </tbody>
                  </template>
              </v-simple-table>
          </v-card-text>
      </v-card>
    </div>
  </template>
  
<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
    export default {
        props: {
            bid: Object
        },
        data() {
            return {
                currencyMask
            }
        },


    }
</script>

<style>

</style>