<template>
    <v-container fluid>
        <v-card>
            <v-row class="card-margin-supplier">
                <v-col cols="12">
                    <v-form 
                        ref="formMaterial"
                        @submit.prevent="getMaterials()"
                    >
                        <v-data-table
                            :headers="headers"
                            item-key="id"
                            :items="materials.data"
                            class="elevation-1 row-height"
                            :hide-default-footer="true"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="order"
                        >
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="card-header-padding">
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Materiales.
                                            </h5>
                                            <p class="text-sm text-body font-weight-light mb-0">
                                            Nombres de materiales por proveedor.
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="search"
                                            input-alternative input-focused-alternative input-icon
                                            class="ml-5"
                                            outlined
                                            placeholder="Buscar"
                                            background-color="#f3f4f9"
                                        >
                                            <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                        </v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-btn icon>
                                            <v-icon dark>
                                                fas fa-filter
                                            </v-icon>
                                        </v-btn>
                                    </v-col>     -->
                                    <v-col cols="12" lg="9" class="btn-padding d-flex justify-end">
                                        <v-btn to="/proveedores" outlined color="red" class="mr-4">
                                            Regresar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item="{ item}">
                                <tr>
                                    <td>
                                        {{ item.code }}
                                    </td>
                                    <td>
                                        {{ item.concept }}
                                    </td>
                                    <td>
                                        {{ item.suppliers.name }}
                                    </td>
                                    <td>
                                        {{ item.types_materials.type }}
                                    </td>
                                    <td>
                                        {{ item.units.name }}
                                    </td>
                                    <td>
                                        {{ item.prices.unit_price_pesos }}
                                    </td>
                                    <td>
                                        {{ item.prices.unit_price_dollars }}
                                    </td>
                                    <td>
                                        {{ item.prices.parity }}
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:footer>
                                <v-row class="card-padding">
                                    <v-col cols="12" lg="3">
                                        <span class="text-body me-3 text-sm d-flex">Mostrando del {{ materials.from }} de {{ materials.total }} registros</span>
                                    </v-col>
                                    <v-col cols="12" lg="9" class="ml-auto d-flex justify-end">
                                        <v-pagination
                                        prev-icon="fa fa-angle-left"
                                        next-icon="fa fa-angle-right"
                                        class="pagination"
                                        color="blue"
                                        v-model="materials.current_page"
                                        :length="materials.last_page"
                                        circle
                                        @input="getMaterials"
                                        :total-visible="9"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:no-data>
                                No hay materiales con ese proveedor.
                            </template>
                        </v-data-table>
                    </v-form>
                </v-col>
            </v-row>    
        </v-card>
    </v-container>
</template>
<script>
import debounce from "lodash.debounce";
export default{
    data(){
        return {
            search: "",
            headers: [
                { text: 'Clave', value: 'code', sortable: false },
                { text: 'Concepto', value: 'concept', sortable: false },
                { text: 'Proveedor', value: 'materials.name', sortable: false },
                { text: 'Tipo', value: 'types_materials.type', sortable: false },
                { text: 'Unidad', value: 'units.name', sortable: false },
                { text: 'PU pesos', value: 'prices.unit_price_pesos', sortable: false },
                { text: 'PU Dólares', value: 'prices.unit_price_dollars', sortable: false },
                { text: 'Paridad', value: 'prices.parity', sortable: false },
            ],
            itemsPerPage: 10,
            materials: [],
            supplier_id: null,
            sortBy: null,
            order: null
        }
    },
    methods: {
        getMaterials(page = 1){
            this.$http.get("/get_materials_suppliers", {
                params: {
                    page: page,
                    query: this.search,
                    perPage: this.itemsPerPage,
                    supplierId: this.$route.params.supplier_id
                }
            })
            .then(response => {
                this.materials = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
    },
    created(){
        this.getMaterials();
        this.supplier_id = this.$route.params.supplier_id;
        this.debounceSearchMaterials = debounce(() => {
            this.getMaterials();
        }, 200);
        
    },
    watch: {
        search () {
            this.debounceSearchMaterials();
        },
    }
}
</script>
<style scoped>
.v-data-table >>> .v-data-table-header {
    background-color: #f3f4f9 !important;
    color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
    color: black !important;
}
.card-margin-supplier{
    margin-top: 3vh !important;
}
.data-table{
    padding-top: 12px !important;
    margin-top: 10px !important;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
.v-data-table.row-height td{
  height: 18px !important;
}
</style>
