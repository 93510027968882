<template>
  <div>
    <v-card>
        <v-card-title>Equipo</v-card-title>
        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Clave
                            </th>
                            <th class="text-left">
                                Nombre
                            </th>
                            <th class="text-left">
                                Observaciones
                            </th>
                            <th class="text-left">
                                Fecha
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ equipment.key }}</td>
                            <td>{{ equipment.name }}</td>
                            <td>{{ equipment.observations }}</td>
                            <td>{{ formatDate.dayMonthAndYear(equipment.date)}}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
    data() {
        return {
            equipment: {}
        }
    },
    methods: {
        async getEquipment(page = 1) {
            let equipmentId = this.$route.params.equipment_id;
            try {
                this.$http.get("/equipos/show/"+ equipmentId)
                .then(response => {
                    this.equipment = response.data;
                })
                
            } catch (error) {
                console.error(error)
            }
            
        }
    },
    mounted() {
        this.getEquipment()
    }

}
</script>

<style>

</style>