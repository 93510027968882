<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col md="11" sm="6">
                    <!-- <h2>Cotización</h2> -->
                </v-col>
                <v-col cols="12" md="1" sm="6">
                    <v-btn to="/cotizaciones" outlined color="red">Regresar</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid>
            <v-row>
                <v-col md="6" sm="12">
                <information-bid-component :bid="bid" />
            </v-col>
            <v-col md="6" sm="12">
                <bid-sums-component :bid="bid" />
            </v-col>
            <v-col md="12" sm="12">
                <bid-equipments-table-component @update="getBid" :bid="bid" :materialTypes="materialTypes" />
            </v-col>
            </v-row>
        </v-container>
    </div>
  </template>
  
<script>
import InformationBidComponent from "@/components/App/Quotations/Equipments/InformationBidComponent.vue"
import BidSumsComponent from "@/components/App/Quotations/Equipments/BidSumsComponent.vue"
import BidEquipmentsTableComponent from "@/components/App/Quotations/Equipments/BidEquipmentsTableComponent.vue"

export default {
    name: "equipment-bids-layout",
    components: {
        InformationBidComponent,
        BidSumsComponent,
        BidEquipmentsTableComponent
    },
    data() {
    return {
        bid: {},
        materialTypes: []
    }
    },
    methods: {
        async getBid(){
            let bid_id = this.$route.params.bid_id
            await this.$http.get("/bids/show/"+bid_id)
            .then(response => {
                this.bid = response.data;
            })
            .then(() => {
                try {
                    this.$http.get("/tipos_materiales")
                    .then(response => {
                        this.materialTypes = response.data.data;
                    })
                    
                } catch (error) {
                    console.error(error)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
    },
    mounted() {
        this.getBid()
    }
}
</script>
  
  <style>
  
  </style>