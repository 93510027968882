<template>
  <div>
    <v-container fluid>
        <v-row>
            <v-col lg="4" md="4" sm="12">
                <exchange-rate-table-component @update="updateChart"/>
            </v-col>
            <v-col lg="8" md="8" sm="12">
                <exchange-rate-line-chart-component ref="chart"/>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import ExchangeRateTableComponent from "@/components/App/ExchangeRate/ExchangeRateTableComponent.vue";
import ExchangeRateLineChartComponent from "@/components/App/ExchangeRate/Charts/ExchangeRateLineChartComponent.vue";
export default {
    name: "exchange-rate-layout",
    components: {
        ExchangeRateTableComponent,
        ExchangeRateLineChartComponent
    },
    methods: {
        updateChart(){
            this.$refs.chart.getExchangeRatesHistoryArray();
        }
    }
}
</script>

<style>

</style>