<template>
    <div>
        <v-btn :disabled="disabled" icon title="Agregar factor individual" @click.stop="dialog = true">
            <v-icon>mdi-note-plus-outline</v-icon>
        </v-btn>

        <v-dialog
        persistent
        v-model="dialog"
        max-width="800"
        >
        <v-card>
            <v-card-title class="text-h5">
                Factor individual de {{ equipment.name }}
            </v-card-title>
            <v-card-subtitle>
                Ingrese los factores individuales del equipo {{ equipment.name }}
            </v-card-subtitle>
            <v-card-text>
                    <v-form
                        ref="formToSaveIndividualFactors"
                        v-model="isValid"
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <!-- <v-text-field
                                        outlined
                                        type="number"
                                        v-model="factor.individual_actualization_factor"
                                        label="Ingresa el factor individual de actualización (puede ser cero)"
                                        required
                                        :rules="factorWithCeroRules"
                                    >
                                    </v-text-field> -->

                                    <v-text-field
                                        outlined
                                        type="number"
                                        v-model="factor.individual_factor"
                                        label="Ingresa el factor individual (puede ser cero)"
                                        :rules="factorWithCeroRules"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                :loading="loading"
                color="red"
                text
                @click="closeDialog()"
            >
                Cancelar
            </v-btn>

            <v-btn
                :loading="loading"
                :disabled="!isValid"
                color="green"
                outlined
                
                @click="saveIndividualFactors()"
            >
                Agregar
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

    </div>
</template>

<script>
export default {
    props: {
        type: String,
        equipment: Object,
        disabled: Boolean
    },
    data() {
        return {
            errors: {},
            loading: false,
            factorWithCeroRules: [
                v => (v === 0 || !!v) || 'Campo requerido',
                v => (v >= 0) || 'Ingresa un valor que no sea negativo',
                
            ],
            
            isValid: false,
            dialog: false,
            factor: {
                // individual_actualization_factor: 0,
                individual_factor: 0,
                equipment_id: this.equipment.id,
                type: this.type
            }
        }
    },
    methods: {
        saveIndividualFactors() {
            if (this.$refs.formToSaveIndividualFactors.validate() != true) {
                alert("Error inesperado, llene todos los campos para poder continuar")
                this.$refs.formToSaveIndividualFactors.resetValidation()
                return 
            }
            else {
                this.loading = true
                this.$http.post("/factores_individuales/create", this.factor)
                .then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.$emit('update')
                        this.closeDialog();
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors
                    console.log(this.errors);
                })
                .finally(() => {
                    this.loading = false;                   
                });
            }
        },
        // async getTypeMaterials(){
        //     await this.$http.get("/tipos_materiales")
        //     .then(response => {
        //         this.factor.types_materials_factors = response.data.data.map(material => {
        //             material.factor = 0;
        //             return material;
        //         });
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     });
        // },
        closeDialog() {

            this.dialog = false
        },
    },
    mounted() {
        // this.getTypeMaterials();
    }

}
</script>

<style>

</style>