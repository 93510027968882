<template>
  <v-app class="bg-white">
    <v-main class="auth-pages" :style="`background-image: url(${require('@/assets/img/illustrations/triaso/Triaso_background.png')}); background-size: cover;`">
      <div class="header-auth position-relative border-radius-xl min-vh-100">
        <v-container class="py-0">
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              class="text-center"
              md="4"
              xs="12"
            >
              <SignInFormLayout />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

  <script>
  import SignInFormLayout from "./SignInFormLayout.vue";
  export default {
    name: "signin-layout",
    components: {
      SignInFormLayout,
    },
    data() {
      return {};
    },
    methods: {}
  };
  </script>
  