<template>
  <div>
    <SignInFormCardComponent />
  </div>
</template>

<script>
import SignInFormCardComponent from '../../../../../components/App/Authentication/SignIn/SignInFormCardComponent.vue';
export default {
    name:"sign-in-form-layout",
    components: {
        SignInFormCardComponent
    }
}
</script>

<style>

</style>