<template>
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="19.0864" y="1.6816" width="6.0638" height="9.03478" transform="rotate(45 19.0864 1.6816)" stroke="black" stroke-width="1.5"/>
        <rect x="8.02051" y="12.7474" width="6.0638" height="8.76772" transform="rotate(45 8.02051 12.7474)" stroke="black" stroke-width="1.5"/>
        <path d="M20.8693 8.64354L18.6407 6.41504M18.6675 10.8453L16.439 8.61683M10.8453 18.6674L8.61684 16.4389M8.38962 21.1232L6.16111 18.8947" stroke="black" stroke-width="1.5"/>
        <path d="M2.65165 6.94007C1.77297 6.06139 1.77297 4.63676 2.65165 3.75808L3.75742 2.65231C4.6361 1.77363 6.06072 1.77363 6.9394 2.65231L21.9278 17.6407L17.64 21.9284L2.65165 6.94007Z" stroke="black" stroke-width="1.5"/>
        <path d="M9.00438 4.54798L4.54736 9.00499" stroke="black" stroke-width="1.5"/>
        <path d="M22.2978 18.0096L23.3698 23.3692L18.0101 22.2973L17.8096 22.0968L22.0973 17.8091L22.2978 18.0096Z" stroke="black" stroke-width="1.5"/>
    </svg>
</template>
<script>
export default {
    name: "Material"
}
</script>