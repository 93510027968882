<template>
    <v-container fluid>
        <v-card class="card-margin-type-material">
            <v-row>
                <v-col cols="12">
                    <v-form 
                        ref="formTypeMaterial"
                        @submit.prevent="getTypeMaterials()"
                    >
                        <v-data-table
                            :headers="headers"
                            item-key="id"
                            :items="typeMaterials.data"
                            class="elevation-1 row-height"
                            :hide-default-footer="true"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="order"
                        >
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="card-header-padding">
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Tipos de materiales
                                            </h5>
                                            <p class="text-sm text-body font-weight-light mb-0">
                                            Registro detallados de materiales
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="search"
                                            input-alternative input-focused-alternative input-icon
                                            class="ml-5"
                                            outlined
                                            placeholder="Buscar"
                                            background-color="#f3f4f9"
                                        >
                                            <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-btn icon class="mr-4">
                                            <v-icon dark @click="openFilterDialog" title="Filtros">
                                                fas fa-filter
                                            </v-icon>
                                        </v-btn>
                                        <v-btn outlined color="black" @click="clearFilter" title="Filtros">
                                            Limpiar filtros
                                        </v-btn>
                                    </v-col>    
                                    <v-col cols="12" lg="6" class="btn-padding d-flex justify-end">
                                        <!-- <v-btn v-if="!addTemporalTypeMaterial && !editTypeMaterial && can('crear tipos de materiales')" outlined color="green" class="mr-4">
                                            Exportar
                                        </v-btn> -->
                                        <v-btn v-if="!addTemporalTypeMaterial && !editTypeMaterial && can('exportar tipos de materiales')" outlined color="blue" @click="createTemporalTypeMaterial()">
                                            Crear Tipo de material
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item="{ item}">
                                <tr v-if="item.editTypeMaterial == true">
                                    <td>
                                        <v-text-field 
                                            v-model="item.type" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :error-messages="errors.type"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="item.description" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-btn icon @click="updateTypeMaterial(item)" :loading="btn_confirm" title="Guardar cambios">
                                            <v-icon dark color="green" fab class="font-size-3k">
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelEditTypeMaterial()" :loading="btn_confirm" title="Cancelar edición">
                                            <v-icon dark color="red" fab class="font-size-3k">
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td>
                                        {{ item.type }}
                                    </td>
                                    <td>
                                        {{ item.description }}
                                    </td>
                                    <td>
                                        <v-btn icon @click="editTemporalTypeMaterial(item)" title="Editar tipo de material" v-if="can('editar tipos de materiales')">
                                            <editIcon class="icon-custom">
                                            </editIcon>
                                        </v-btn>
                                        <v-btn icon @click="deleteTypeMaterial(item.id)" title="Borrar tipo de material" v-if="can('borrar tipos de materiales')">
                                            <deleteIcon class="icon-custom">
                                            </deleteIcon>
                                        </v-btn>    
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:body.prepend>
                                <tr v-if="temporalTypeMaterial.addTemporalTypeMaterial == true">
                                    <td>
                                        <v-text-field 
                                            v-model="temporalTypeMaterial.type" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            :rules="inputRules"
                                            class="data-table"
                                            :error-messages="errors.type"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="temporalTypeMaterial.description" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            :rules="inputRules"
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-btn icon @click="createTypeMaterial(temporalTypeMaterial)" :loading="btn_confirm" title="Crear tipo de material">
                                            <v-icon dark color="green" fab class="font-size-3k">
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelTemporalTypeMaterial()" :loading="btn_confirm" title="Cancelar creación tipo de material">
                                            <v-icon dark color="red" fab class="font-size-3k">
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:footer>
                                <v-row class="card-padding">
                                    <v-col cols="12" lg="3">
                                        <span class="text-body me-3 text-sm d-flex">Mostrando del {{ typeMaterials.from }} de {{ typeMaterials.total }} registros</span>
                                    </v-col>
                                    <v-col cols="12" lg="9" class="ml-auto d-flex justify-end">
                                        <v-pagination
                                        prev-icon="fa fa-angle-left"
                                        next-icon="fa fa-angle-right"
                                        class="pagination"
                                        color="blue"
                                        v-model="typeMaterials.current_page"
                                        :length="typeMaterials.last_page"
                                        circle
                                        @input="getTypeMaterials"
                                        :total-visible="9"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:no-data>
                                No hay tipos de materiales registrados.
                            </template>
                        </v-data-table>
                    </v-form>
                </v-col>
            </v-row>    
        </v-card>
        <DialogFilterComponent
          :show.sync="showFilterDialog"
          @updateSearch="getTypeMaterialFilter"
          :clear_filter="clear_filter"
        ></DialogFilterComponent>
    </v-container>
</template>
<script>
import DialogFilterComponent from './DialogFiltersTypeMaterialComponent.vue'
import debounce from "lodash.debounce";
export default{
    data(){
        return {
            search: "",
            headers: [
                { text: 'Tipo', value: 'type', sortable: false },
                { text: 'Descripción', value: 'description', sortable: false },
                { text: 'Acciones', value: '', sortable: false },
            ],
            itemsPerPage: 10,
            typeMaterials: [],
            typeMaterial: {},
            addTemporalTypeMaterial: false,
            editTypeMaterial: false,
            editTypeMaterialId: null,
            inputRules: [
                value => !!value || 'Este campo es obligatorio.'
            ],
            temporalTypeMaterial: {},
            errors: {},
            sortBy: null,
            order: "Descendente",
            showFilterDialog: false,
            clear_filter: false,
            btn_confirm: false
        }
    },
    components: {
        DialogFilterComponent
    },
    methods: {
        getTypeMaterials(page = 1){
            this.clear_filter = false;
            this.$http.get("/tipos_materiales", {
                params: {
                    page: page,
                    query: this.search,
                    perPage: this.itemsPerPage,
                    sortBy: this.sortBy,
                    order: this.order,
                }
            })
            .then(response => {
                this.typeMaterials = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        createTemporalTypeMaterial(){
            this.addTemporalTypeMaterial = true;
            var typeMaterial;
            if(this.typeMaterials.data.length != 0){
                typeMaterial = {
                    type: "",
                    description: "",
                    addTemporalTypeMaterial: this.addTemporalTypeMaterial
                }
            }else{
                typeMaterial = {
                    type: "",
                    description: "",
                    addTemporalTypeMaterial: this.addTemporalTypeMaterial
                }
            }
            this.temporalTypeMaterial = typeMaterial;
        },
        cancelTemporalTypeMaterial(){
            this.temporalTypeMaterial = {};
            this.addTemporalTypeMaterial = false;
        },
        cancelEditTypeMaterial(){
            this.editTypeMaterial = false;
            this.typeMaterials.data.find((element) => element.id == this.editTypeMaterialId).editTypeMaterial = this.editTypeMaterial;
            this.editTypeMaterial = null;
            this.getTypeMaterials(this.typeMaterials.current_page);
        },
        createTypeMaterial(typeMaterial){
            this.btn_confirm = true;
            this.typeMaterial = typeMaterial;
            
            if(this.$refs.formTypeMaterial.validate() == true){
                this.$http.post("/tipos_materiales/create", this.typeMaterial)
                .then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.addTemporalTypeMaterial = false;
                        this.temporalTypeMaterial = {};
                        this.getTypeMaterials();
                        this.typeMaterial = {};
                        this.errors = {};
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors
                })
                .finally(() => {
                    this.btn_confirm = false;                   
                });
            }else{
                this.btn_confirm = false;
            }
        },
        editTemporalTypeMaterial(typeMaterial){
            this.editTypeMaterial = true;
            this.editTypeMaterialId = typeMaterial.id;
            this.typeMaterials.data.find((element) => element.id == typeMaterial.id).editTypeMaterial = this.editTypeMaterial;
        },
        updateTypeMaterial(typeMaterial){
            this.btn_confirm = true;
            var updateTypeMaterial = {
                id: typeMaterial.id,
                type: typeMaterial.type,
                description: typeMaterial.description,
            }
            this.$http.patch(`/tipos_materiales/update/${typeMaterial.id}`,updateTypeMaterial)
            .then(response => {
                if(response.status == 200){
                    this.$swal.fire("", response.data.message, "success");
                    this.editTypeMaterial = false;
                    this.getTypeMaterials();
                    this.errors = {};
                }
            })
            .catch((error) => {
                this.errors = error.response.data.errors
            })
            .finally(() => {
                this.btn_confirm = false;                   
            });
        },
        deleteTypeMaterial(id){
            this.$swal({
                title: "Confirmación",
                text: " ¿Estas seguro de borrar este registro?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                }).then((result) => {
                if (result.value) {
                    this.$http.delete(`/tipos_materiales/destroy/${id}`)
                    .then(response => {
                        this.$swal.fire("", response.data.message, "success");
                        this.getTypeMaterials();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                } 
            });
        },
        openFilterDialog(){
            this.showFilterDialog = true;
        },
        getTypeMaterialFilter(datos){
            this.order = datos.orderBy;
            this.sortBy = datos.orderType;
            this.getTypeMaterials();
        },
        clearFilter(){
            this.clear_filter = true;
        }
    },
    created(){
        this.getTypeMaterials();
        this.debounceSearchTypeMaterials = debounce(() => {
            this.getTypeMaterials();
        }, 200);
    },
    watch: {
        search () {
            this.debounceSearchTypeMaterials();
        },
        temporalTypeMaterial () {
            this.temporalTypeMaterial;
        },
    }
}
</script>
<style scoped>
.v-data-table >>> .v-data-table-header {
    background-color: #f3f4f9 !important;
    color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
    color: black !important;
}
.card-margin-type-material{
    margin-top: 3vh !important;
}
.data-table{
    padding-top: 12px !important;
    margin-top: 10px !important;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
.v-data-table.row-height td{
  height: 18px !important;
}
</style>
