<template>
   <div>
        <v-col cols="12" class="margin-navbar-top">
            <v-card elevation="2">
                <v-row>
                    <v-col cols="12">
                        <div class="px-4 pt-2 pb-0 d-flex">
                            <div class="ms-4">
                                <h6 class="text-h6 text-typo font-weight-bold">
                                Precio del dólar al cambio de {{ monthsArray[monthsArray.length - 1] }} :
                                </h6>
                                <p class="font-weight-light text-secondary text-md">
                                $ {{ exchangeRatesHistoryArray[exchangeRatesHistoryArray.length - 1] }}
                                </p>
                            </div>
                            <div class="my-2 ms-auto">
                                <v-badge bottom bordered :color="lastTwoExchangeRatesPercent > 0 ? 'green' : 'red'" dot offset-x="4" offset-y="9">
                                </v-badge>
                                <span class="text-dark text-md ms-3">{{ lastTwoExchangeRatesPercent }} %</span>
                            </div>
                            
                            </div>
                            <div class="card-padding pt-0 px-4">
                            <div class="chart">
                                <canvas
                                :id="lineCharExchangeRatesId"
                                class="chart-canvas"
                                height="300"
                                ></canvas>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import Chart from "chart.js/auto";
import { formatDate } from '@fullcalendar/core';
export default {
    data () {
        return {
            lineCharExchangeRatesId: '',
            exchangeRatesHistory: null,
            chartInstance: null,
        }
    },
    mounted() {
        this.getExchangeRatesHistoryArray();
    },
  methods: {
    async getExchangeRatesHistoryArray() {
        try {
        const response = await this.$http.get("/exchange/current_history");
        this.exchangeRatesHistory = response.data;
        this.lineCharExchangeRatesId = `line-chart-exchange-rates-${Math.floor(Math.random() * 1000)}`;
        this.$nextTick(() => {
          this.createChart();
        });
        } catch (error) {
        console.error("Error al obtener el historial de precios:", error);
        }
    },

    createChart () {
      var ctx = document
      .getElementById(this.lineCharExchangeRatesId)
      .getContext("2d");
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      this.chartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.monthsArray,
          datasets: [
            {
              label: "Dólar",
              tension: 0.4,
              pointRadius: 0,
              borderColor: "green",
              borderWidth: 3,
              backgroundColor: "transparent",
              fill: true,
              data: this.exchangeRatesHistoryArray,
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
                color: "#c1c4ce5c",
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#b2b9bf",
                font: {
                  size: 14,
                  weight: 300,
                  family: "Roboto",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
                color: "#c1c4ce5c",
              },
              ticks: {
                display: true,
                color: "#b2b9bf",
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Roboto",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
      });
    },

  },
  computed: {
    exchangeRatesHistoryArray () {
        if (this.exchangeRatesHistory && this.exchangeRatesHistory.length > 0) {
            return this.exchangeRatesHistory.map(item => parseFloat(item.dollar));
        } else {
            return [];
        }
    },

    monthsArray() {
        if (this.exchangeRatesHistory && this.exchangeRatesHistory.length > 0) {
            return this.exchangeRatesHistory.map(item => {
            const date = new Date(item.created_at);

            const month = date.toLocaleString('default', { month: 'long' });

            const day = date.getDate();

            const year = date.getFullYear();

            return `${day} de ${month} del ${year}`;
            });
        } else {
            return [];
        }
    },

    lastTwoExchangeRatesPercent() {
        if (this.exchangeRatesHistory && this.exchangeRatesHistory.length > 1) {
            const historyArray = this.exchangeRatesHistoryArray;
            const lastRate = historyArray[historyArray.length - 1];
            const secondLastRate = historyArray[historyArray.length - 2];

            if (secondLastRate !== 0) { 
                const percentageDifference = (((lastRate - secondLastRate) / secondLastRate) * 100).toFixed(2);

                return parseFloat(percentageDifference);
            } else {
                return null;
            }
        } else {
            return null;
        }
    },
  }
}
</script>
