<template>
    <v-container fluid class="padding-container">
        <v-card class="card-margin-crushers">
            <v-row>
                <v-col cols="12">
                    <v-form ref="formEquipment" @submit.prevent="getCrushers()">
                        <v-data-table
                            v-model="selectedItems"
                            :headers="headers"
                            item-key="id"
                            :items="crushers.data"
                            class="elevation-1"
                            group-by="clasificate_as"
                            :hide-default-footer="true"
                            dense
                        >
                            <template v-slot:group.header="{group, isOpen, toggle}">
                                <td :colspan="headers.length" class="grey white--text">
                                    <v-btn color="white" icon>
                                        <v-icon @click="toggle">
                                            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                                        </v-icon>
                                    </v-btn>
                                <span>{{ group }}</span>
                                </td>
                            </template>
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <div class="card-header-padding">
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Trituradoras
                                            </h5>
                                            <p class="text-sm text-body font-weight-light mb-0">
                                            Registro de trituradoras
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="search"
                                            input-alternative input-focused-alternative input-icon
                                            class="ml-5"
                                            outlined
                                            placeholder="Buscar"
                                            background-color="#f3f4f9"
                                        >
                                            <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <!-- <add-equipment-to-price-quote-component :type="'ListaPrTris'" v-if="selectedItems.length > 0" :equipments="selectedItems" /> -->
                                    </v-col>    
                                    <v-col cols="12" lg="6" class="card-header-padding d-flex justify-end">
                                        <v-btn @click="downloadExport()" outlined color="green" class="mr-4" title="Exportar" v-if="can('exportar lista de precio trituradoras')">
                                            Exportar
                                        </v-btn>
                                        <add-factors-dialog-component v-if="can('agregar factores generales lista de precio trituradoras') && hasFactor == false" @update="updateFactor" :type="'ListaPrTris'" />
                                        <edit-factors-dialog-component v-if="can('agregar factores generales lista de precio trituradoras')" @update="updateFactor" :type="'ListaPrTris'" />
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <add-individual-factors-component v-if="can('agregar factores individuales lista de precio trituradoras')" @update="getCrushers" :disabled="!hasFactor ? true : false" :equipment="item" :type="'ListaPrTris'" />
                            </template>
                            <template v-slot:footer>
                                <v-row class="card-padding">
                                    <v-col cols="12" lg="3" sm="12">
                                        <span class="text-body me-3 text-sm d-flex">Mostrando del {{ crushers.from }} de {{ crushers.total }} registros</span>
                                    </v-col>
                                    <v-col cols="12" lg="9" class="ml-auto d-flex justify-end">
                                        <v-pagination
                                        prev-icon="fa fa-angle-left"
                                        next-icon="fa fa-angle-right"
                                        class="pagination"
                                        color="blue"
                                        v-model="crushers.current_page"
                                        :length="crushers.last_page"
                                        circle
                                        @input="getCrushers"
                                        :total-visible="9"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item.date="{ item }">
                                {{ formatDate.dayMonthAndYear(item.date) }}
                            </template>
                            <template v-for="(type, index) in materialTypes" v-slot:[`item.sum.types[${index}].total`]="{ item }">
                                <div class="text-end">{{ item.sum.types[index].total | VMask(currencyMask) }}</div>
                            </template>
                            <template v-slot:item.sum_equipment_factor[0].sum="{ item }">
                                <div class="text-end">{{  item.sum_equipment_factor[0] ? item.sum_equipment_factor[0].sum : "0" | VMask(currencyMask) }}</div>
                            </template>
                            <template v-slot:item.sum_equipment_factor[0].pesos_with_iva="{ item }">
                                <div class="text-end">{{  item.sum_equipment_factor[0] ? item.sum_equipment_factor[0].pesos_with_iva : "0" | VMask(currencyMask) }}</div>
                            </template>
                            <template v-slot:item.sum_equipment_factor[0].dollars="{ item }">
                                <div class="text-end">{{  item.sum_equipment_factor[0] ? item.sum_equipment_factor[0].dollars : "0" | VMask(currencyMask) }}</div>
                            </template>
                            <template v-slot:item.sum_equipment_factor[0].utility_in_pesos="{ item }">
                                <div class="text-end">{{  item.sum_equipment_factor[0] ? item.sum_equipment_factor[0].utility_in_pesos: "0" | VMask(currencyMask) }}</div>
                            </template>
                            <template v-slot:no-data>
                                No hay tipos de cambios registrados.
                            </template>
                        </v-data-table>
                    </v-form>
                </v-col>
            </v-row>    
        </v-card>
    </v-container>
</template>

<script>
import AddFactorsDialogComponent from '@/components/App/Factors/AddFactorsDialogComponent.vue';
import AddIndividualFactorsComponent from '@/components/App/Factors/AddIndividualFactorsComponent.vue';
import AddEquipmentToPriceQuoteComponent from '@/components/App/Quotations/AddEquipmentToPriceQuoteComponent.vue';
import EditFactorsDialogComponent from '@/components/App/Factors/EditFactorsDialogComponent.vue';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default {
    components:{
        AddFactorsDialogComponent,
        AddIndividualFactorsComponent,
        AddEquipmentToPriceQuoteComponent,
        EditFactorsDialogComponent
    },
    props: {
        factor: Object
    },
    data() {
        return {
            selectedItems: [],
            materialTypes: [],
            search: "",
            itemsPerPage: 10,
            crushers: [],
            sortBy: null,
            order: null,
            currencyMask
        }
    },
    methods: {
        beforeUnload() {
            sessionStorage.removeItem('selectedItems');
        },
        loadSelectedItemsFromSessionStorage() {
            const storedSelectedItems = sessionStorage.getItem('selectedItems');
            if (storedSelectedItems) {
                this.selectedItems = JSON.parse(storedSelectedItems);
            }
        },
        saveSelectedItemsToSessionStorage() {
            sessionStorage.setItem('selectedItems', JSON.stringify(this.selectedItems));
        },
        updateFactor() {
            this.$emit('update')
        },
        async getCrushers(page = 1){
            await this.$http.get("/getCrushers", {
                params: {
                    page: page,
                    query: this.search,
                    perPage: this.itemsPerPage,
                    sortBy: this.sortBy,
                    order: this.order,
                    factor_id: this.factor.id
                }
            })
            .then(response => {
                this.crushers = response.data;
            })
            .then(() => {
                try {
                    this.$http.get("/tipos_materiales")
                    .then(response => {
                        this.materialTypes = response.data.data;
                    })
                    
                } catch (error) {
                    console.error(error)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        async downloadExport(){
            this.$http.get(`/exportarCrushers/${this.factor.id}`, { responseType: 'blob' })
            .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Lista de precios plantas.xlsx'); 
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            })
            .catch(error => {
            console.error('Error al descargar el archivo Excel:', error);
            });
        }
    },
    computed: {
        hasFactor() {
            if (this.factor.id) {
                return true
            }
            return false
        },
        headers() {
            let header = [
                { text: 'Clave', value: 'key'},
                { text: 'Nombre', value: 'name'},
                { text: 'Fecha', value: 'date'},
            ]
            let materialTypes = this.materialTypes.map(item => ({
                text: item.description,
                value: `sum.types[${item.id - 1}].total`, 
                sortable: false,  
            }));

            header = header.concat(materialTypes);
            let objectTotal = {
                 'text': 'Suma', value: 'sum_equipment_factor[0].sum', 'sorteable': false,
            }
            header.push(objectTotal)
            let objectFactorIndividual = {
                 'text': 'Factor individual', value: 'equipment_factor[0].individual_factor', 'sorteable': false,
            }
            header.push(objectFactorIndividual)
            let objectPesosWithIva = {
                 'text': 'Pesos con IVA', value: 'sum_equipment_factor[0].pesos_with_iva', 'sorteable': false,
            }
            header.push(objectPesosWithIva)
            let objectDollars = {
                 'text': 'Dólares', value: 'sum_equipment_factor[0].dollars', 'sorteable': false,
            }
            header.push(objectDollars)
            let objectUtility = {
                 'text': 'Utilidad en pesos', value: 'sum_equipment_factor[0].utility_in_pesos', 'sorteable': false,
            }
            header.push(objectUtility)
            let objectTotalPesos = {
                 'text': 'Factor total', value: 'equipment_factor[0].individual_total_factor', 'sorteable': false,
            }
            header.push(objectTotalPesos)
            let objectActions = {
                text: 'Acciones', value: 'actions', 'sorteable': false,
            }
            header.push(objectActions)
            return header
        }
    },
    mounted() {
        this.getCrushers();
        // this.loadSelectedItemsFromSessionStorage();
        // window.addEventListener('unload', this.beforeUnload);
    },
    beforeDestroy() {
        // this.saveSelectedItemsToSessionStorage();
        // window.removeEventListener('unload', this.beforeUnload);
    },
    watch: {
        factor() {
            this.getCrushers()
            immediate: true
        },
        // currentPage(newPage) {
        // this.saveSelectedItemsToSessionStorage();
        // },
        // selectedItems(newItems) {
        // this.saveSelectedItemsToSessionStorage();
        // },
    }

}
</script>

<style scoped>
.v-data-table >>> .v-data-table-header {
    background-color: #f3f4f9 !important;
    color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
    color: black !important;
}
.card-margin-type-material{
    margin-top: 20vh !important;
}
.data-table{
    padding-top: 12px !important;
    margin-top: 10px !important;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
.card-margin-crushers{
    margin-top: 3vh !important;
}
</style>