<template>
    <v-container fluid class="padding-container">
        <v-card class="card-margin-equipment">
            <v-row>
                <v-col cols="12">
                    <v-form ref="formEquipment" @submit.prevent="getEquipments()">
                        <v-data-table
                            :headers="headers"
                            item-key="id"
                            :items="equipments.data"
                            class="elevation-1 row-height"
                            :hide-default-footer="true"
                        >
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <div class="card-header-padding">
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Equipos
                                            </h5>
                                            <p class="text-sm text-body font-weight-light mb-0">
                                            Registro de equipos
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="search"
                                            input-alternative input-focused-alternative input-icon
                                            class="ml-5"
                                            outlined
                                            placeholder="Buscar"
                                            background-color="#f3f4f9"
                                        >
                                            <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <!-- <v-btn icon @click="openFilterDialog" title="Filtros" >
                                            <v-icon dark>
                                                fas fa-filter
                                            </v-icon>
                                        </v-btn> -->
                                    </v-col>    
                                    <v-col cols="12" lg="6" class="card-header-padding d-flex justify-end">
                                        <v-btn v-if="!addTemporalEquipment && !editEquipment && can('exportar equipos')" @click="downloadExport()" outlined color="green" class="mr-4" title="Exportar">
                                            Exportar
                                        </v-btn>
                                        <v-btn v-if="!addTemporalEquipment && !editEquipment && can('crear equipos')" outlined color="blue" @click="createTemporalEquipment()" title="Crear Material">
                                            Crear equipo
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item="{item}">
                                <tr v-if="item.editEquipment == true">
                                    <td>
                                        <v-text-field 
                                            v-model="item.key"
                                            validate-on-blur
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="claveRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="item.name" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="item.observations" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-menu
                                            v-model="showPicker"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            full-width
                                            max-width="400px"
                                            min-width="400px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="formattedDate"
                                                    readonly
                                                    v-on="on"
                                                    outlined
                                                    background-color="#f3f4f9"
                                                    :rules="inputRules"
                                                    input-alternative input-focused-alternative input-icon
                                                    prepend-inner-icon="mdi-calendar"
                                                    class="data-table"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="item.date"
                                                no-title
                                                locale="es"
                                                width="400px"
                                                @input="showPicker = false, dayMonthAndYear(item.date)"
                                            ></v-date-picker>
                                        </v-menu>
                                    </td>
                                    <td>
                                        <v-combobox
                                            v-model="item.clasification"
                                            :items="equipmentClasifications"
                                            multiple
                                            outlined
                                            dense
                                            :rules="inputRules"
                                            background-color="#f3f4f9"
                                            class="data-table"
                                        ></v-combobox>
                                    </td>
                                    <td>
                                        <v-select
                                            v-model="item.clasificate_as" 
                                            :items="equipmentClasificateAs"
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-select>
                                    </td>
                                    <template v-if="item.sum">
                                        <td v-for="item in item.sum.types" :key="item.id">
                                            {{ item.total | VMask(currencyMask) }}
                                        </td>
                                    </template>
                                    <td>
                                        <v-btn icon @click="updateEquipment(item)">
                                            <v-icon dark color="green" fab>
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelEditEquipment()">
                                            <v-icon dark color="red" fab>
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td>
                                        {{item.key}}
                                    </td>
                                    <td>
                                        <p class="text-hidden">
                                           <span class="text-hidden-color"> {{ item.name }} </span>
                                        </p>
                                    </td>
                                    <td>
                                        <p class="text-hidden">
                                           <span class="text-hidden-color"> {{ item.observations }} </span>
                                        </p>
                                    </td>
                                    <td>
                                        {{ formatDate.dayMonthAndYear(item.date) }}
                                    </td>
                                    <td>
                                        <ul class="list-unstyled">
                                            <li v-for="clasification in item.clasification">{{ clasification }}</li>
                                        </ul>
                                    </td>
                                    <td>
                                        {{ item.clasificate_as }}
                                    </td>
                                    <template v-if="item.sum">
                                        <td v-for="item in item.sum.types" :key="item.id" class="text-right">
                                            {{ item.total | VMask(currencyMask) }}
                                        </td>
                                    </template>
                                    <td>
                                        <v-btn icon v-if="can('duplicar equipos')" @click="createDuplicateTemporalEquipment(item.id), dayMonthAndYear(item.date)" title="Duplicar">
                                            <v-icon color="black" fab>
                                                fas fa-clone
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="editTemporalEquipment(item), dayMonthAndYear(item.date)" title="Editar" v-if="can('editar equipos')">
                                            <editIcon class="icon-custom">
                                            </editIcon>
                                        </v-btn>
                                        <v-btn icon :to="'/equipos/relacion_materiales/'+item.id" title="Ver materiales" v-if="can('ver materiales equipos')">
                                            <materialIcon class="icon-custom">
                                            </materialIcon>
                                        </v-btn>
                                        <v-btn icon @click="deleteEquipment(item.id)" title="Borrar" v-if="can('borrar equipos')">
                                            <deleteIcon class="icon-custom">
                                                
                                            </deleteIcon>
                                        </v-btn>    
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:body.prepend>
                                <tr v-if="temporalEquipment.addTemporalEquipment == true">
                                    <td>
                                        <v-text-field 
                                            v-model="temporalEquipment.key" 
                                            :maxlength="6"
                                            validate-on-blur
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="claveRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="temporalEquipment.name" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="temporalEquipment.observations" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-menu
                                            v-model="showPicker"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            full-width
                                            max-width="400px"
                                            min-width="400px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="formattedDate"
                                                    readonly
                                                    v-on="on"
                                                    outlined
                                                    background-color="#f3f4f9"
                                                    :rules="inputRules"
                                                    input-alternative input-focused-alternative input-icon
                                                    prepend-inner-icon="mdi-calendar"
                                                    class="data-table"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="temporalEquipment.date"
                                                no-title
                                                locale="es"
                                                width="400px"
                                                @input="showPicker = false, dayMonthAndYear(temporalEquipment.date)"
                                            ></v-date-picker>
                                        </v-menu>
                                    </td>
                                    <td>
                                        <v-combobox
                                            v-model="temporalEquipment.clasification"
                                            :items="equipmentClasifications"
                                            multiple
                                            outlined
                                            dense
                                            :rules="inputRules"
                                            background-color="#f3f4f9"
                                            class="data-table"
                                        ></v-combobox>
                                    </td>
                                    <td>
                                        <v-select
                                            v-model="temporalEquipment.clasificate_as" 
                                            :items="equipmentClasificateAs"
                                            outlined 
                                            background-color="#f3f4f9"
                                            dense
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-select>
                                    </td>
                                    <td v-for="item in materialTypes" :key="item">
                                        0
                                    </td>
                                    <td>
                                        <v-btn icon @click="createEquipment(temporalEquipment)">
                                            <v-icon dark color="green" fab>
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelTemporalEquipment()">
                                            <v-icon dark color="red" fab>
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr v-if="temporalDuplicateEquipment.addTemporalDuplicateEquipment == true">
                                    <td>
                                        <v-text-field 
                                            v-model="temporalDuplicateEquipment.key" 
                                            :maxlength="6"
                                            validate-on-blur
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="claveRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="temporalDuplicateEquipment.name" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="temporalDuplicateEquipment.observations" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-menu
                                            v-model="showPicker"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            full-width
                                            max-width="400px"
                                            min-width="400px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="formattedDate"
                                                    readonly
                                                    v-on="on"
                                                    outlined
                                                    background-color="#f3f4f9"
                                                    :rules="inputRules"
                                                    input-alternative input-focused-alternative input-icon
                                                    prepend-inner-icon="mdi-calendar"
                                                    class="data-table"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="temporalDuplicateEquipment.date"
                                                no-title
                                                locale="es"
                                                width="400px"
                                                @input="showPicker = false, dayMonthAndYear(temporalDuplicateEquipment.date)"
                                            ></v-date-picker>
                                        </v-menu>
                                    </td>
                                    <td>
                                        <v-combobox
                                            v-model="temporalDuplicateEquipment.clasification"
                                            :items="equipmentClasifications"
                                            multiple
                                            outlined
                                            dense
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            class="data-table"
                                        ></v-combobox>
                                    </td>
                                    <td>
                                        <v-select
                                            v-model="temporalDuplicateEquipment.clasificate_as" 
                                            :items="equipmentClasificateAs"
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-select>
                                    </td>
                                    <td v-for="item in materialTypes" :key="item">
                                        0
                                    </td>
                                    <td>
                                        <v-btn icon @click="createDuplicateEquipment(temporalDuplicateEquipment)">
                                            <v-icon dark color="green" fab>
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelTemporalDuplicateEquipment()">
                                            <v-icon dark color="red" fab>
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:footer>
                                <v-row class="card-padding">
                                    <v-col cols="12" lg="3" sm="12">
                                        <span class="text-body me-3 text-sm d-flex">Mostrando del {{ equipments.from }} de {{ equipments.total }} registros</span>
                                    </v-col>
                                    <v-col cols="12" lg="9" class="ml-auto d-flex justify-end">
                                        <v-pagination
                                        prev-icon="fa fa-angle-left"
                                        next-icon="fa fa-angle-right"
                                        class="pagination"
                                        color="blue"
                                        v-model="equipments.current_page"
                                        :length="equipments.last_page"
                                        circle
                                        @input="getEquipments"
                                        :total-visible="9"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:no-data>
                                No hay tipos de cambios registrados.
                            </template>
                        </v-data-table>
                    </v-form>
                </v-col>
            </v-row>    
        </v-card>
    </v-container>
</template>

<script>
import debounce from "lodash.debounce";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default {
    data() {
        return {
            equipmentClasificateAs:['Equipo', 'Componente suelto'],
            equipmentClasifications:['ListaPrPlas', 'ListaPrTris', 'ListaPrNpts'],
            materialTypes: [],
            search: "",
            itemsPerPage: 10,
            equipments: [],
            equipment: {},
            sortBy: null,
            order: "desc",
            addTemporalEquipment: false,
            editEquipment: false,
            editEquipmentId: null,
            inputRules: [
                value => !!value || 'Este campo es obligatorio.'
            ],
            claveRules: [
                v => !!v || 'Este campo es obligatorio.',
                v => (v && v.length === 6) || 'La clave debe tener 6 caracteres'
            ],
            temporalEquipment: {},
            temporalDuplicateEquipment: {},
            addTemporalDuplicateEquipment: false,
            equipment_id: null,
            duplicate_equipment: {},
            showPicker: false,
            formattedDate: 'DD/MM/AAAA',
            currencyMask
        }
    },
    methods: {
        async getEquipments(page = 1){
            await this.$http.get("/equipos", {
                params: {
                    page: page,
                    query: this.search,
                    perPage: this.itemsPerPage,
                    sortBy: this.sortBy,
                    order: this.order,
                }
            })
            .then(response => {
                this.equipments = response.data;
            })
            .then(() => {
                try {
                    this.$http.get("/tipos_materiales")
                    .then(response => {
                        this.materialTypes = response.data.data;
                    })
                    
                } catch (error) {
                    console.error(error)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        createTemporalEquipment(){
            this.addTemporalEquipment = true;
            var equipment;
            if(this.equipments.data.length != 0){
                const dataEquipment = this.equipments.data[this.equipments.data.length-1];
                const id = dataEquipment.id + 1;
                equipment = {
                    id: id, 
                    key: "",
                    name: "",
                    observations: "",
                    date: "",
                    clasification: "",
                    clasificate_as: "",
                    sum_equipment_materials_id : null,
                    addTemporalEquipment: this.addTemporalEquipment
                }
            }else{
                equipment = {
                    id: "1", 
                    key: "",
                    name: "",
                    observations: "",
                    date: "",
                    clasification: "",
                    clasificate_as: "",
                    sum_equipment_materials_id : null,
                    addTemporalEquipment: this.addTemporalEquipment
                }
            }
            this.temporalEquipment = equipment;
        },
        createDuplicateTemporalEquipment(equipmentId){
            this.equipment_id = equipmentId;
            this.addTemporalDuplicateEquipment = true;
            this.$http.get(`/equipos/show/${this.equipment_id}`)
            .then(response => {
                this.duplicate_equipment = response.data;
                this.temporalDuplicateEquipment =  {
                    id: this.equipment_id,
                    key: "",
                    name: this.duplicate_equipment.name,
                    observations: this.duplicate_equipment.observations,
                    date: this.duplicate_equipment.date,
                    clasification: this.duplicate_equipment.clasification,
                    clasificate_as: this.duplicate_equipment.clasificate_as,
                    sum_equipment_materials_id : null,
                    addTemporalDuplicateEquipment: this.addTemporalDuplicateEquipment
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        cancelTemporalEquipment(){
            this.temporalEquipment = {};
            this.addTemporalEquipment = false;
            this.formattedDate = 'DD-MM-AAAA';
        },
        cancelTemporalDuplicateEquipment(){
            this.temporalDuplicateEquipment = {};
            this.addTemporalDuplicateEquipment = false;
            this.equipment_id = null;
            this.duplicate_equipment = {};
            this.formattedDate = 'DD-MM-AAAA';
        },
        cancelEditEquipment(){
            this.editEquipment = false;
            this.equipments.data.find((element) => element.id == this.editEquipmentId).editEquipment = this.editEquipment;
            this.editEquipment = null;
            this.formattedDate = 'DD-MM-AAAA';
        },
        createDuplicateEquipment(equipment){
            this.equipment = equipment;
            if(this.$refs.formEquipment.validate() == true){
                this.$http.post(`/equipos/duplicado`,this.equipment
                ).then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.addTemporalDuplicateEquipment = false;
                        this.temporalDuplicateEquipment = {};
                        this.getEquipments();
                        this.equipment = {};
                        this.equipment_id = null;
                        this.duplicate_equipment = {};
                        this.formattedDate = 'DD-MM-AAAA';
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            }
        },
        createEquipment(equipment){
            this.equipment = equipment;
            
            if(this.$refs.formEquipment.validate() == true){
                this.$http.post("equipos/create", this.equipment)
                .then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.addTemporalEquipment = false;
                        this.temporalEquipment = {};
                        this.getEquipments();
                        this.equipment = {};
                        this.formattedDate = 'DD-MM-AAAA';
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            }
        },
        editTemporalEquipment(equipment){
            this.editEquipment = true;
            this.editEquipmentId = equipment.id;
            this.equipments.data.find((element) => element.id == equipment.id).editEquipment = this.editEquipment;
        },
        updateEquipment(equipment){
            var updateEquipment = {
                key: equipment.key,
                name: equipment.name,
                observations: equipment.observations,
                date: equipment.date,
                clasification: equipment.clasification,
                clasificate_as: equipment.clasificate_as,
            }
            this.$http.patch(`equipos/update/${equipment.id}`,updateEquipment)
            .then(response => {
                if(response.status == 200){
                    this.$swal.fire("", response.data.message, "success");
                    this.editEquipment = false;
                    this.getEquipments();
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        deleteEquipment(id){
            this.$swal({
                title: "Confirmación",
                text: " ¿Estas seguro de borrar este registro?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                }).then((result) => {
                if (result.value) {
                    this.$http.delete(`/equipos/destroy/${id}`)
                    .then(response => {
                        this.$swal.fire("", response.data.message, "success");
                        this.getEquipments();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                } 
            });
        },
        downloadExport(){
            this.$http.get('/exportar_equipos', { responseType: 'blob' })
            .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'equipos.xlsx'); 
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            })
            .catch(error => {
            console.error('Error al descargar el archivo Excel:', error);
            });
        },
        dayMonthAndYear(dateItem) {
            const date = new Date(dateItem);

            const month = (date.getMonth() + 1).toString().padStart(2, '0');

            const day = (date.getDate() + 1);

            const year = date.getFullYear();

            const formatted = `${day}/${month}/${year}`;
            this.formattedDate = formatted;
        }
    },
    computed: {
        headers() {
            let header = [
                { text: 'Clave', value: 'key', 'sortable': false},
                { text: 'Nombre', value: 'name', 'sortable': false, width: '200px'},
                { text: 'Observaciones', value: 'observations', 'sortable': false, width: '200px'},
                { text: 'Fecha', value: 'date', 'sortable': false},
                { text: 'Listas', value: 'clasification', 'sortable': false},
                { text: 'Clasificación', value: 'clasificate_as', 'sortable': false},
            ]
            let materialTypes =  this.materialTypes.map(item => item.description);
            materialTypes.forEach(item => {
                let itemObject = {
                    'text': item,
                    'value': "",
                    'sortable': false,
                }
                header.push(itemObject)
            })
            let objectActions = {
                 'text': 'Acciones', 'value': '', 'sortable': false,
            }
            header.push(objectActions)
            return header
        }
    },
    created() {
        this.getEquipments();
    }

}
</script>

<style scoped>
.v-data-table >>> .v-data-table-header {
    background-color: #f3f4f9 !important;
    color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
    color: black !important;
}
.card-margin-equipment{
    margin-top: 3vh !important;
}
.data-table{
    padding-top: 12px !important;
    margin-top: 10px !important;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
.v-data-table.row-height td{
  height: 18px !important;
}
.list-unstyled {
  list-style: none;
  padding-left:0;
}
.padding-container{
    padding: 0px !important;
}
</style>