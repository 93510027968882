<template>
    <div>
      <v-container fluid>
          <v-row>
              <v-col cols="12">
                <general-factors-information @getFactor="getFactor" :type="'ListaPrTris'" ref="generalInformation" />
              </v-col>
              <v-col cols="12">
                  <crushers-table-component :factor="factor" @update="updateFactor" />
              </v-col>
          </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  import CrushersTableComponent from "@/components/App/Crushers/CrushersTableComponent"
  import GeneralFactorsInformation from "@/components/App/Factors/GeneralFactorsInformation.vue";
  export default {
    name: "crusher-layout",
    components: {
      CrushersTableComponent,
      GeneralFactorsInformation
    },
    data() {
      return {
        factor: {}
      }
    },
    methods: {
      async getFactor(factor) {
        this.factor = await factor
      },
      updateFactor() {
        this.$refs.generalInformation.getFactor();
      },
    }
  }
  </script>
  
  <style>
  
  </style>