<template>
    <v-container fluid>
        <v-card class="card-margin-user">
            <v-row>
                <v-col cols="12">
                    <v-form 
                        ref="formUser"
                        @submit.prevent="getUsers()"
                    >
                        <v-data-table
                            :headers="headers"
                            item-key="id"
                            :items="users.data"
                            class="elevation-1 row-height"
                            :hide-default-footer="true"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="order"
                        >
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="card-header-padding">
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Usuarios
                                            </h5>
                                            <p class="text-sm text-body font-weight-light mb-0">
                                            Registro para nuevos usuarios.
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="search"
                                            input-alternative input-focused-alternative input-icon
                                            class="ml-5"
                                            outlined
                                            placeholder="Buscar"
                                            background-color="#f3f4f9"
                                        >
                                            <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="9" class="btn-padding d-flex justify-end">
                                        <v-btn v-if="!addTemporalUser && !editUser && can('crear usuarios')" outlined color="blue" @click="createTemporalUser()">
                                            Crear usuario
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr v-if="item.editUser == true">
                                    <td>
                                        <v-text-field 
                                            v-model="item.name" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            autocomplete="off"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="item.email" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            autocomplete="off"
                                            :error-messages="errors.email"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="item.password" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="passwordRulesEdit"
                                            type="password"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            autocomplete="off"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                            v-model="item.roles[0].name"
                                            :items="roles"
                                            outlined
                                            input-alternative input-focused-alternative input-icon
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            class="data-table"
                                        ></v-autocomplete>
                                    </td>
                                    <td>
                                        <v-btn icon @click="updateUser(item)" title="Guardar cambios">
                                            <v-icon dark color="green" fab class="font-size-3k">
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelEditUser()" title="Cancelar edición">
                                            <v-icon dark color="red" fab class="font-size-3k">
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>
                                        {{ item.email }}
                                    </td>
                                    <td>
                                        <span>**********</span>
                                    </td>
                                    <td>
                                        {{ item.roles[0].name }}
                                    </td>
                                    <td>
                                        <v-btn v-if="item.roles[0].name != 'Administrador' && can('editar usuarios')" icon @click="editTemporalUser(item)" title="Editar usuario">
                                            <editIcon class="icon-custom">
                                            </editIcon>
                                        </v-btn>
                                        <v-btn icon :to="'/usuario_permisos/'+item.id" title="Ver permisos" v-if="can('ver permisos')">
                                            <v-icon color="black" dark fab class="font-size-3k">
                                                fas fa-user-tag
                                            </v-icon>
                                        </v-btn>
                                        <v-btn v-if="item.roles[0].name != 'Administrador' && can('borrar usuarios')" icon @click="deleteUser(item.id)" title="Borrar usuario">
                                            <deleteIcon class="icon-custom">
                                            </deleteIcon>
                                        </v-btn>    
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:body.prepend>
                                <tr v-if="temporalUser.addTemporalUser == true">
                                    <td>
                                        <v-text-field 
                                            v-model="temporalUser.name" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            :rules="inputRules"
                                            class="data-table"
                                            autocomplete="off"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="temporalUser.email" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputEmailRules"
                                            type="email"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :error-messages="errors.email"
                                            autocomplete="off"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="temporalUser.password" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="passwordRules"
                                            type="password"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            autocomplete="off"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                            v-model="temporalUser.rol"
                                            :items="roles"
                                            outlined
                                            input-alternative input-focused-alternative input-icon
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            class="data-table"
                                        ></v-autocomplete>
                                    </td>
                                    <td>
                                        <v-btn icon @click="createUser(temporalUser)" title="Crear unidad">
                                            <v-icon dark color="green" fab class="font-size-3k">
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelTemporalUser()" title="Cancelar creación de unidad">
                                            <v-icon dark color="red" fab class="font-size-3k">
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:footer>
                                <v-row class="card-padding">
                                    <v-col cols="12" lg="3">
                                        <span class="text-body me-3 text-sm d-flex">Mostrando del {{ users.from }} de {{ users.total }} registros</span>
                                    </v-col>
                                    <v-col cols="2" class="ml-auto d-flex justify-end">
                                        <v-pagination
                                        prev-icon="fa fa-angle-left"
                                        next-icon="fa fa-angle-right"
                                        class="pagination"
                                        color="blue"
                                        v-model="users.current_page"
                                        :length="users.last_page"
                                        circle
                                        @input="getUsers"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:no-data>
                                No hay tipos de usuarios registrados.
                            </template>
                        </v-data-table>
                    </v-form>
                </v-col>
            </v-row>    
        </v-card>
    </v-container>
</template>
<script>
import debounce from "lodash.debounce";
export default{
    data(){
        return {
            search: "",
            headers: [
                { text: 'Nombre', value: 'name', sortable: false },
                { text: 'Correo', value: 'email', sortable: false },
                { text: 'Password', value: 'passoword', sortable: false },
                { text: 'Rol', sortable: false },
                { text: 'Acciones', value: '', sortable: false },
            ],
            itemsPerPage: 10,
            users: [],
            user: {},
            addTemporalUser: false,
            editUser: false,
            editUserId: null,
            inputRules: [
                value => !!value || 'Este campo es obligatorio.'
            ],
            inputEmailRules: [
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'El correo debe ser valido'
            ],
            passwordRules: [
                (value) => !!value || 'La contraseña es requerida.',
                (value) => (value && value.length >= 8) || 'minimo 8 caracteres',
            ],
            passwordRulesEdit: [
                (value) => (value && value.length >= 8) || 'minimo 8 caracteres',
            ],
            temporalUser: {},
            errors: {},
            sortBy: null,
            order: null,
            showFilterDialog: false,
            roles: ['Ingeniería','Captura','Dirección'],
        }
    },
    methods: {
        getUsers(page = 1){
            this.$http.get("/usuarios", {
                params: {
                    page: page,
                    query: this.search,
                    perPage: this.itemsPerPage,
                    sortBy: this.sortBy,
                    order: this.order,
                }
            })
            .then(response => {
                this.users = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        createTemporalUser(){
            this.addTemporalUser = true;
            var user;
            if(this.users.data.length != 0){
                const dataUser = this.users.data[this.users.data.length-1];
                const id = dataUser.id + 1;
                user = {
                    name: "",
                    email: "",
                    password: "",
                    rol: "",
                    addTemporalUser: this.addTemporalUser
                }
            }else{
                user = {
                    name: "",
                    email: "",
                    password: "",
                    rol: "",
                    addTemporalUser: this.addTemporalUser
                }
            }
            this.temporalUser = user;
        },
        cancelTemporalUser(){
            this.temporalUser = {};
            this.addTemporalUser = false;
        },
        cancelEditUser(){
            this.editUser = false;
            this.users.data.find((element) => element.id == this.editUserId).editUser = this.editUser;
            this.editUser = null;
        },
        createUser(user){
            this.user = user;
            
            if(this.$refs.formUser.validate() == true){
                this.$http.post("/usuarios/create", this.user)
                .then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.addTemporalUser = false;
                        this.temporalUser = {};
                        this.getUsers();
                        this.user = {};
                        this.errors = {};
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors
                });
            }
        },
        editTemporalUser(user){
            this.editUser = true;
            this.editUserId = user.id;
            this.users.data.find((element) => element.id == user.id).editUser = this.editUser;
        },
        updateUser(user){
            var updateUser = {
                name: user.name,
                email: user.email,
                password: user.password,
                rol: user.roles[0].name
            }
            this.$http.patch(`/usuarios/update/${user.id}`,updateUser)
            .then(response => {
                if(response.status == 200){
                    this.$swal.fire("", response.data.message, "success");
                    this.editUser = false;
                    this.getUsers();
                    this.errors = {};
                }
            })
            .catch((error) => {
                this.errors = error.response.data.errors
            });
        },
        deleteUser(id){
            this.$swal({
                title: "Confirmación",
                text: " ¿Estas seguro de borrar este registro?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                }).then((result) => {
                if (result.value) {
                    this.$http.delete(`/usuarios/destroy/${id}`)
                    .then(response => {
                        this.$swal.fire("", response.data.message, "success");
                        this.getUsers();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                } 
            });
        },
        // openFilterDialog(){
        //     this.showFilterDialog = true;
        // },
        // getSuppliersFilter(datos){
        //     this.order = datos.orderBy;
        //     this.sortBy = datos.orderType;
        //     this.getUsers();
        // },
    },
    created(){
        this.getUsers();
        this.debounceSearchUsers = debounce(() => {
            this.getUsers();
        }, 500);
    },
    watch: {
        search () {
            this.debounceSearchUsers();
        },
    }
}
</script>
<style scoped>
.v-data-table >>> .v-data-table-header {
    background-color: #f3f4f9 !important;
    color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
    color: black !important;
}
.card-margin-user{
    margin-top: 3vh !important;
}
.data-table{
    padding-top: 12px !important;
    margin-top: 10px !important;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
    min-height: 32px !important;
}
.v-data-table.row-height td{
  height: 18px !important;
}
</style>
