<template>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.75" y="3.75006" width="19.4999" height="19.4999" rx="2.25" stroke="black" stroke-width="1.5"/>
        <rect x="8.5708" y="11.5281" width="16.3032" height="5.75833" transform="rotate(-45 8.5708 11.5281)" fill="black"/>
        <path d="M6.53497 17.6356L8.5708 11.5279L12.6426 15.5996L6.53497 17.6356Z" fill="black"/>
    </svg>
</template>
<script>
export default {
    name: "Edit"
}
</script>