<template>
    <v-app :style="`background-image: url(${require('@/assets/img/illustrations/triaso/triaso_fondo.png')}); background-size: cover;`">
        <v-container fluid>
            <v-row class="viewport-layout">
                <app-bar-component> </app-bar-component>
                <v-col cols="12" class="py-10">
                    <div class="py-16">
                        <router-view></router-view>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import AppBarComponent from "@/components/App/AppBarComponent.vue";
export default {
    name: "home-layout",
    components: {
      AppBarComponent,
    },
methods: {
    
}
}
</script>
<style>
.viewport-layout{
    min-width: 95vw !important;
    min-height: 100vh !important;
}
</style>
