<template>
    <v-container fluid class="padding-container">
      <v-card>
          <v-card-title>Factores</v-card-title>
          <v-card-text>
              <v-simple-table>
                  <template v-slot:default>
                      <thead>
                          <tr>
                            <th v-for="item in factor.types_materials_factors" :key="item.id" class="text-center">
                                {{ item.type }} {{ item.description }}
                            </th>
                            <th class="text-center">
                                Factor general
                            </th>
                            <!-- <th class="text-center">
                                Factor general de actualización
                            </th> -->
                            <th class="text-center">
                                Factor general de precio en México
                            </th>
                            <th class="text-center">
                                Factor general de exportación
                            </th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td class="text-center" v-for="item in factor.types_materials_factors" :key="item.id">
                                {{ item.factor }}
                              </td>
                              <td class="text-center">
                                {{ factor.general_factor }}
                              </td>
                              <!-- <td class="text-center">
                                {{ factor.general_actualization_factor }}
                              </td> -->
                              <td class="text-center">
                                {{ factor.mexican_factor }}
                              </td>
                              <td class="text-center">
                                {{ factor.export_factor }}
                              </td>
                             
                          </tr>
                      </tbody>
                  </template>
              </v-simple-table>
          </v-card-text>
      </v-card>
    </v-container>
  </template>
  
<script>
    export default {
        props: {
            type: String,
        },
        data() {
            return {
                factor: {}
            }
        },
        methods: {
            async getFactor() {
                try {
                    await this.$http.get("/getLastFactor",{
                            params: {
                                type: this.type
                            }
                        })
                    .then(response => {
                        this.factor = response.data
                        this.factor.types_materials_factors = JSON.parse(this.factor.types_materials_factors);  
                        this.$emit('getFactor', this.factor)                
                    })
                    
                } catch (error) {
                    console.error(error)
                }
                
            }
        },
        mounted() {
            this.getFactor()
            .then(() => {
                if (!this.factor) {
                    this.$swal.fire("", "Agrega factores generales para poder agregar factores individuales a los equipos.", "warning");
                }
            })
            
        }
  
    }
</script>
  
  <style>
  
  </style>