<template>
    <v-row justify="center">
        <v-dialog
            v-model="localShow"
            scrollable
            max-width="700px"
        >
            <v-card>
                <v-toolbar color="#f3f4f9" dark>
                    <v-toolbar-title class="title-color">Filtros</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn class="close-window-button" icon dark @click="localShow = false" color="black" rel="tooltip" title="Cerrar">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="margin-body-card">
                    <v-tabs
                        v-model="vTabs"
                        slider-color="blue"
                        class="text-xs-center"
                        grow
                    >
                        <v-tab href="#tab-1">
                            Ordenar por:
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items
                        v-model="vTabs"
                    >
                        <v-tab-item value="tab-1">
                            <v-container>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="padding-col"
                                    >
                                        <span>Campo</span>
                                        <v-autocomplete
                                            v-model="orderType"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="orderTypes"
                                            clearable
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="padding-col"
                                    >
                                        <span>Ascendete/Descendente</span>
                                        <v-autocomplete
                                            v-model="orderBy"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="ordersBy"
                                            clearable
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>
                    </v-tabs-items>
                    <v-row class="margin-btn">
                        <v-spacer></v-spacer>
                        <v-btn 
                            outlined color="green"    
                            @click="sendData()"
                            >
                            Aplicar
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    data() {
        return{
            localShow: false,
            orderTypes: ['ID','Nombre', 'Abreviatura'],
            ordersBy: ['Ascendente','Descendente'],
            orderType: null,
            orderBy: null,
            vTabs: "tab-1"
        }
    },
    props: {
        show: Boolean,
        clear_filter: Boolean
    },
    watch: {
        show: function (value) {
            this.localShow = value;
        },
        localShow: function (value) {
            this.$emit('update:show', value)
        },
        clear_filter: function (value) {
            if(value == true){
                this.orderType = null;
                this.orderBy = null;

                this.sendData()
            }
        },
    },
    methods: {
        sendData(){
            this.$emit('updateSearch', {
                orderType: this.orderType,
                orderBy: this.orderBy,

            });
            this.localShow = false;
        }
    },
    created() {
       
    }
}
</script>
<style>
.title-color{
    color: black;
}
.margin-body-card{
    margin-top: 3vh;
}
.padding-col{
    padding: 0 !important;
    margin-top: 1vh;
}
.margin-btn{
    margin-top: 3vh;
}
</style>