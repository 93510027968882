<template>
    <div>
        <v-btn outlined color="blue" title="Agregar Factor" @click.stop="dialog = true">
            Editar Factores
        </v-btn>

        <v-dialog
        persistent
        v-model="dialog"
        max-width="800"
        >
        <v-card>
            <v-card-title class="text-h5">
                Factores
            </v-card-title>
            <v-card-subtitle>
                Ingrese los factores generales
            </v-card-subtitle>
            <v-card-text>
                    <v-form
                        ref="formToSaveGeneralFactors"
                        v-model="isValid"
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field 
                                        outlined
                                        v-for="material in lastFactor.types_materials_factors" :key="material.id"
                                        type="number"
                                        v-model="material.factor"
                                        :label="'Ingresa el factor de '+material.type+': ' + material.description"
                                        required
                                        :rules="factorRules"
                                    ></v-text-field>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="6">
                                    <v-text-field
                                        outlined
                                        type="number"
                                        v-model="lastFactor.general_factor"
                                        label="Ingresa el factor general (puede ser cero)"
                                        :rules="factorWithCeroRules"
                                    >
                                    </v-text-field>
                                    <!-- <v-text-field
                                        outlined
                                        type="number"
                                        v-model="factor.general_actualization_factor"
                                        label="Ingresa el factor general de actualización"
                                        required
                                        :rules="factorRules"
                                    >
                                    </v-text-field> -->
                                    <v-text-field
                                        outlined
                                        type="number"
                                        v-model="lastFactor.mexican_factor"
                                        label="Ingresa el factor general de precio en México"
                                        required
                                        :rules="factorRules"
                                    >
                                    </v-text-field>
                                    <v-text-field
                                        outlined
                                        type="number"
                                        v-model="lastFactor.export_factor"
                                        label="Ingresa el factor general de exportación (puede ser cero)"
                                        :rules="factorWithCeroRules"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                :loading="loading"
                color="red"
                text
                @click="closeDialog()"
            >
                Cancelar
            </v-btn>

            <v-btn
                :loading="loading"
                :disabled="!isValid"
                color="green"
                outlined
                
                @click="saveGeneralFactors()"
            >
                Guardar
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

    </div>
</template>

<script>
export default {
    props: {
        type: String
    },
    data() {
        return {
            errors: {},
            loading: false,
            factorWithCeroRules: [
                v => (v === 0 || !!v) || 'Campo requerido',
                v => (v >= 0) || 'Ingresa un valor que no sea negativo',
                
            ],
            factorRules: [
                v => !!v || 'Campo requerido',
                v => (v && v > 0) || 'Ingresa un valor mayor a 0',
            ],
            isValid: false,
            dialog: false,
            factor: {
                types_materials_factors: [],
                // general_actualization_factor: 0,
                general_factor: 0,
                mexican_factor: 0,
                export_factor: 0,
                type: this.type
            },
            lastFactor: {}
        }
    },
    methods: {
        saveGeneralFactors() {
            if (this.$refs.formToSaveGeneralFactors.validate() != true) {
                alert("Error inesperado, llene todos los campos para poder continuar")
                this.$refs.formToSaveGeneralFactors.resetValidation()
                return 
            }
            else {
                this.loading = true
                this.$http.post("/factores/create", this.lastFactor)
                .then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.$emit('update')
                        this.closeDialog();
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors
                    console.log(this.errors);
                })
                .finally(() => {
                    this.loading = false;                   
                });
            }
        },
        async getTypeMaterials(){
            await this.$http.get("/tipos_materiales")
            .then(response => {
                this.factor.types_materials_factors = response.data.data.map(material => {
                    material.factor = 0;
                    return material;
                });
            })
            .catch((error) => {
                console.error(error);
            });
        },
        async getLastFactor(){
            await this.$http.get("/getLastFactor", {
                params: {
                    type: this.type
                }
            })
            .then(response => {
               this.lastFactor = response.data;
               this.lastFactor.types_materials_factors = JSON.parse(this.lastFactor.types_materials_factors);
            })
            .catch((error) => {
                console.error(error);
            });
        },
        closeDialog() {

            this.dialog = false
        },
    },
    mounted() {
        this.getTypeMaterials();
        this.getLastFactor();
    }

}
</script>

<style>

</style>