<template>
  <router-view></router-view>
</template>
<script>


export default {
  name: "auth-layout",
  components: {

  },
  data() {
    return {
    };
  },

};
</script>

<style scoped>

</style>