<template>
    <v-container fluid>
        <v-card class="card-margin-log">
            <v-row>
                <v-col cols="12">
                    <v-form 
                        ref="formLog"
                        @submit.prevent="getLogs()"
                    >
                        <v-data-table
                            :headers="headers"
                            item-key="id"
                            :items="logs.data"
                            class="elevation-1 row-height"
                            :hide-default-footer="true"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="order"
                        >
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="card-header-padding">
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Bitácora
                                            </h5>
                                            <p class="text-sm text-body font-weight-light mb-0">
                                            Registro de los cambios de los usuarios creación,edición,elimación de registros.
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="search"
                                            input-alternative input-focused-alternative input-icon
                                            class="ml-5"
                                            outlined
                                            placeholder="Buscar"
                                            background-color="#f3f4f9"
                                        >
                                            <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>
                                        {{ item.user_name }}
                                    </td>
                                    <td>
                                        {{ item.action }}
                                    </td>
                                    <td>
                                        {{ item.table }}
                                    </td>
                                    <td>
                                        {{ item.item_name }}
                                    </td>
                                    <td>
                                        {{ item.log_date }}
                                    </td>
                                    <td>
                                        {{ item.message }}
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:footer>
                                <v-row class="card-padding">
                                    <v-col cols="12" lg="3">
                                        <span class="text-body me-3 text-sm d-flex">Mostrando del {{ logs.from }} de {{ logs.total }} registros</span>
                                    </v-col>
                                    <v-col cols="2" lg="9" class="ml-auto d-flex justify-end">
                                        <v-pagination
                                        prev-icon="fa fa-angle-left"
                                        next-icon="fa fa-angle-right"
                                        class="pagination"
                                        color="blue"
                                        v-model="logs.current_page"
                                        :length="logs.last_page"
                                        circle
                                        @input="getLogs"
                                        :total-visible="9"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:no-data>
                                No hay registros.
                            </template>
                        </v-data-table>
                    </v-form>
                </v-col>
            </v-row>    
        </v-card>
        <v-card>
            <v-row class="card-margin-user">
                <v-col cols="12">
                    <v-form 
                        ref="formUserLastLogin"
                        @submit.prevent="getUserLastLogin()"
                    >
                        <v-data-table
                            :headers="headersUsers"
                            item-key="id"
                            :items="userLogin.data"
                            class="elevation-1 row-height"
                            :hide-default-footer="true"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="order"
                        >
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="card-header-padding">
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Usuarios inicio de sesión.
                                            </h5>
                                            <p class="text-sm text-body font-weight-light mb-0">
                                            Hora de inicio de sesión de los usuarios.
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="searchUser"
                                            input-alternative input-focused-alternative input-icon
                                            class="ml-5"
                                            outlined
                                            placeholder="Buscar"
                                            background-color="#f3f4f9"
                                        >
                                            <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                        </v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-btn icon>
                                            <v-icon dark>
                                                fas fa-filter
                                            </v-icon>
                                        </v-btn>
                                    </v-col> -->
                                </v-row>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>
                                        {{ item.email }}
                                    </td>
                                    <td>
                                        {{ item.last_login_at }}
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:footer>
                                <v-row class="card-padding">
                                    <v-col cols="12" lg="3">
                                        <span class="text-body me-3 text-sm d-flex">Mostrando del {{ userLogin.from }} de {{ userLogin.total }} registros</span>
                                    </v-col>
                                    <v-col cols="12" lg="9" class="ml-auto d-flex justify-end">
                                        <v-pagination
                                        prev-icon="fa fa-angle-left"
                                        next-icon="fa fa-angle-right"
                                        class="pagination"
                                        color="blue"
                                        v-model="userLogin.current_page"
                                        :length="userLogin.last_page"
                                        circle
                                        @input="getUserLastLogin"
                                        :total-visible="9"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:no-data>
                                No hay inicios de sesión.
                            </template>
                        </v-data-table>
                    </v-form>
                </v-col>
            </v-row>    
        </v-card>
    </v-container>
</template>
<script>
import debounce from "lodash.debounce";
export default{
    data(){
        return {
            search: "",
            headers: [
                { text: 'Nombre de usuario', value: 'name', sortable: false },
                { text: 'Acción', value: 'description', sortable: false },
                { text: 'Modulo', value: '', sortable: false },
                { text: 'Nombre del registro', value: '', sortable: false },
                { text: 'Fecha', value: '', sortable: false },
                { text: 'Mensaje', value: '', sortable: false },
            ],
            headersUsers: [
                { text: 'Nombre de usuario', value: 'name', sortable: false },
                { text: 'Correo', value: 'description', sortable: false },
                { text: 'Inicio de sesión', value: '', sortable: false },
            ],
            itemsPerPage: 10,
            logs: [],
            log: {},
            sortBy: null,
            order: null,
            userLogin: [],
            searchUser: ""
        }
    },
    methods: {
        getLogs(page = 1){
            this.clear_filter = false;
            this.$http.get("/bitacora", {
                params: {
                    page: page,
                    query: this.search,
                    perPage: this.itemsPerPage,
                    sortBy: this.sortBy,
                    order: this.order,
                }
            })
            .then(response => {
                this.logs = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        getUserLastLogin(page = 1){
            this.clear_filter = false;
            this.$http.get("/users_last_login", {
                params: {
                    page: page,
                    query: this.searchUser,
                    perPage: this.itemsPerPage,
                    sortBy: this.sortBy,
                    order: this.order,
                }
            })
            .then(response => {
                this.userLogin = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
    },
    created(){
        this.getLogs();
        this.getUserLastLogin();
        this.debounceSearchLogs = debounce(() => {
            this.getLogs();
        }, 200);
        this.debounceSearchUsers = debounce(() => {
            this.getUserLastLogin();
        }, 200);
    },
    watch: {
        search () {
            this.debounceSearchLogs();
        },
        searchUser () {
            this.debounceSearchUsers();
        },
    }
}
</script>
<style scoped>
.v-data-table >>> .v-data-table-header {
    background-color: #f3f4f9 !important;
    color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
    color: black !important;
}
.card-margin-log{
    margin-top: 3vh !important;
}
.card-margin-user{
    margin-top: 5vh !important;
}
.data-table{
    padding-top: 12px !important;
    margin-top: 10px !important;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
.v-data-table.row-height td{
  height: 18px !important;
}
</style>
