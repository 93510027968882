<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                  <quotations-table-component />
                    <!-- <plants-table-component :factor="factor" @update="updateFactor" /> -->
                </v-col>
            </v-row>
        </v-container>
    </div>
  </template>
  
  <script>
  import QuotationsTableComponent from '@/components/App/Quotations/QuotationsTableComponent.vue';
  export default {
      name: "proce-quote-layout",
      components: {
        QuotationsTableComponent
      },
      data() {
        return {
          factor: {}
        }
      },
      methods: {
        async getFactor(factor) {
          this.factor = await factor
        },
        updateFactor() {
          this.$refs.generalInformation.getFactor();
        },
      }
  }
  </script>
  
  <style>
  
  </style>