<template>
    <v-container fluid>
        <v-card class="card-margin-unit">
            <v-row>
                <v-col cols="12">
                    <v-form 
                        ref="formUnit"
                        @submit.prevent="getUnits()"
                    >
                        <v-data-table
                            :headers="headers"
                            item-key="id"
                            :items="units.data"
                            class="elevation-1 row-height"
                            :hide-default-footer="true"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="order"
                        >
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="card-header-padding">
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Unidades
                                            </h5>
                                            <p class="text-sm text-body font-weight-light mb-0">
                                            Registro de tipos de unidades para los materiales.
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="search"
                                            input-alternative input-focused-alternative input-icon
                                            class="ml-5"
                                            outlined
                                            placeholder="Buscar"
                                            background-color="#f3f4f9"
                                        >
                                            <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-btn icon class="mr-4">
                                            <v-icon dark @click="openFilterDialog" title="Filtros">
                                                fas fa-filter
                                            </v-icon>
                                        </v-btn>
                                        <v-btn outlined color="black" @click="clearFilter" title="Filtros">
                                            Limpiar filtros
                                        </v-btn>
                                    </v-col>    
                                    <v-col cols="12" lg="6" class="btn-padding d-flex justify-end">
                                        <!-- <v-btn v-if="!addTemporalUnit && !editUnit && can('exportar unidades')" outlined color="green" class="mr-4">
                                            Exportar
                                        </v-btn> -->
                                        <v-btn v-if="!addTemporalUnit && !editUnit && can('crear unidades')" outlined color="blue" @click="createTemporalUnit()">
                                            Crear unidad
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item="{ item}">
                                <tr v-if="item.editUnit == true">
                                    <td>
                                        <v-text-field 
                                            v-model="item.name" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :error-messages="errors.name"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="item.description" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :error-messages="errors.description"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-btn icon @click="updateUnit(item)" :loading="btn_confirm" title="Guardar cambios">
                                            <v-icon dark color="green" fab class="font-size-3k">
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelEditUnit()" :loading="btn_confirm" title="Cancelar edición">
                                            <v-icon dark color="red" fab class="font-size-3k">
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>
                                        {{ item.description }}
                                    </td>
                                    <td>
                                        <v-btn icon @click="editTemporalUnit(item)" title="Editar unidad" v-if="can('editar unidades')">
                                            <editIcon class="icon-custom">
                                            </editIcon>
                                        </v-btn>
                                        <v-btn icon @click="deleteUnit(item.id)" title="Borrar unidad" v-if="can('borrar unidades')">
                                            <deleteIcon class="icon-custom">
                                            </deleteIcon>
                                        </v-btn>    
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:body.prepend>
                                <tr v-if="temporalUnit.addTemporalUnit == true">
                                    <td>
                                        <v-text-field 
                                            v-model="temporalUnit.name" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            :rules="inputRules"
                                            class="data-table"
                                            :error-messages="errors.name"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="temporalUnit.description" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            :rules="inputRules"
                                            class="data-table"
                                            :error-messages="errors.description"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-btn icon @click="createUnit(temporalUnit)" :loading="btn_confirm" title="Crear unidad">
                                            <v-icon dark color="green" fab class="font-size-3k">
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelTemporalUnit()" :loading="btn_confirm" title="Cancelar creación de unidad">
                                            <v-icon dark color="red" fab class="font-size-3k">
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:footer>
                                <v-row class="card-padding">
                                    <v-col cols="12" lg="3">
                                        <span class="text-body me-3 text-sm d-flex">Mostrando del {{ units.from }} de {{ units.total }} registros</span>
                                    </v-col>
                                    <v-col cols="12" lg="9" class="ml-auto d-flex justify-end">
                                        <v-pagination
                                        prev-icon="fa fa-angle-left"
                                        next-icon="fa fa-angle-right"
                                        class="pagination"
                                        color="blue"
                                        v-model="units.current_page"
                                        :length="units.last_page"
                                        circle
                                        @input="getUnits"
                                        :total-visible="9"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:no-data>
                                No hay tipos de unidades registrados.
                            </template>
                        </v-data-table>
                    </v-form>
                </v-col>
            </v-row>    
        </v-card>
        <DialogFilterComponent
          :show.sync="showFilterDialog"
          @updateSearch="getUnitFilter"
          :clear_filter="clear_filter"
        ></DialogFilterComponent>
    </v-container>
</template>
<script>
import DialogFilterComponent from './DialogFiltersUnitComponent.vue'
import debounce from "lodash.debounce";
export default{
    data(){
        return {
            search: "",
            headers: [
                { text: 'Nombre', value: 'name', sortable: false },
                { text: 'Abreviatura', value: 'description', sortable: false },
                { text: 'Acciones', value: '', sortable: false },
            ],
            itemsPerPage: 10,
            units: [],
            unit: {},
            addTemporalUnit: false,
            editUnit: false,
            editUnitId: null,
            inputRules: [
                value => !!value || 'Este campo es obligatorio.'
            ],
            temporalUnit: {},
            errors: {},
            sortBy: null,
            order: "Descendente",
            showFilterDialog: false,
            clear_filter: false,
            btn_confirm: false
        }
    },
    components: {
        DialogFilterComponent
    },
    methods: {
        getUnits(page = 1){
            this.clear_filter = false;
            this.$http.get("/unidades", {
                params: {
                    page: page,
                    query: this.search,
                    perPage: this.itemsPerPage,
                    sortBy: this.sortBy,
                    order: this.order,
                }
            })
            .then(response => {
                this.units = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        createTemporalUnit(){
            this.addTemporalUnit = true;
            var unit;
            if(this.units.data.length != 0){
                unit = {
                    name: "",
                    description: "",
                    addTemporalUnit: this.addTemporalUnit
                }
            }else{
                unit = {
                    name: "",
                    description: "",
                    addTemporalUnit: this.addTemporalUnit
                }
            }
            this.temporalUnit = unit;
        },
        cancelTemporalUnit(){
            this.temporalUnit = {};
            this.addTemporalUnit = false;
        },
        cancelEditUnit(){
            this.editUnit = false;
            this.units.data.find((element) => element.id == this.editUnitId).editUnit = this.editUnit;
            this.editUnit = null;
            this.getUnits(this.units.current_page);
        },
        createUnit(unit){
            this.btn_confirm = true;
            this.unit = unit;
            
            if(this.$refs.formUnit.validate() == true){
                this.$http.post("/unidades/create", this.unit)
                .then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.addTemporalUnit = false;
                        this.temporalUnit = {};
                        this.getUnits();
                        this.unit = {};
                        this.errors = {};
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors
                })
                .finally(() => {
                    this.btn_confirm = false;                   
                });
            }else{
                this.btn_confirm = false;
            }
        },
        editTemporalUnit(unit){
            this.editUnit = true;
            this.editUnitId = unit.id;
            this.units.data.find((element) => element.id == unit.id).editUnit = this.editUnit;
        },
        updateUnit(unit){
            this.btn_confirm = true;
            var updateUnit = {
                id: unit.id,
                name: unit.name,
                description: unit.description,
            }
            this.$http.patch(`/unidades/update/${unit.id}`,updateUnit)
            .then(response => {
                if(response.status == 200){
                    this.$swal.fire("", response.data.message, "success");
                    this.editUnit = false;
                    this.getUnits();
                    this.errors = {};
                }
            })
            .catch((error) => {
                this.errors = error.response.data.errors
            })
            .finally(() => {
                this.btn_confirm = false;                   
            });
        },
        deleteUnit(id){
            this.$swal({
                title: "Confirmación",
                text: " ¿Estas seguro de borrar este registro?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                }).then((result) => {
                if (result.value) {
                    this.$http.delete(`/unidades/destroy/${id}`)
                    .then(response => {
                        this.$swal.fire("", response.data.message, "success");
                        this.getUnits();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                } 
            });
        },
        openFilterDialog(){
            this.showFilterDialog = true;
        },
        getUnitFilter(datos){
            this.order = datos.orderBy;
            this.sortBy = datos.orderType;
            this.getUnits();
        },
        clearFilter(){
            this.clear_filter = true;
        }
    },
    created(){
        this.getUnits();
        this.supplier_id = this.$route.params.supplier_id;
        this.debounceSearchUnits = debounce(() => {
            this.getUnits();
        }, 200);
    },
    watch: {
        search () {
            this.debounceSearchUnits();
        },
        temporalUnit () {
            this.temporalUnit;
        },
    }
}
</script>
<style scoped>
.v-data-table >>> .v-data-table-header {
    background-color: #f3f4f9 !important;
    color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
    color: black !important;
}
.card-margin-unit{
    margin-top: 3vh !important;
}
.data-table{
    padding-top: 12px !important;
    margin-top: 10px !important;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
.v-data-table.row-height td{
  height: 18px !important;
}
</style>
