<template>
    <v-container fluid>
        <v-card class="card-margin-dashboard" :img="require('@/assets/img/illustrations/triaso/triaso_dashboard.png')" min-height="30vh">
            <v-row>
                <v-col cols="12">
                    <v-card-title class="text-white">{{ timestamp }}</v-card-title>
                </v-col>
            </v-row>
        </v-card>
        <v-row>
        <v-col cols="6">
            <v-row class="margin-img-dashboard">
                <v-col cols="6">
                    <v-card class="mb-6 card-shadow border-radius-xl py-4">
                        <v-row no-gutters class="px-4">
                        <v-col sm="4">
                            <v-avatar
                            color="bg-gradient-info border-radius-xl mt-n8"
                            class="shadow-dark"
                            height="64"
                            width="64"
                            >
                            <supplierIcon class="icon-white icon-small">
                            </supplierIcon>
                            </v-avatar>
                        </v-col>
                        <v-col sm="8" class="text-end">
                            <p
                            class="
                                text-sm
                                mb-0
                                text-capitalize text-body
                                font-weight-light
                            "
                            >
                            Proveedores
                            </p>
                            <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                {{ counts.suppliers }}
                            </h4>
                        </v-col>
                        </v-row>
                        <hr class="dark horizontal mt-3 mb-4" />
                        <v-row class="px-4">
                        <v-col cols="12">
                            <p class="mb-0 text-body">
                            <span class="font-weight-light ms-1">Total de proveedores</span>
                            </p>
                        </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card class="mb-6 card-shadow border-radius-xl py-4">
                        <v-row no-gutters class="px-4">
                        <v-col sm="4">
                            <v-avatar
                            color="bg-gradient-info border-radius-xl mt-n8"
                            class="shadow-dark"
                            height="64"
                            width="64"
                            >
                            <materialIcon class="icon-white icon-small">
                            </materialIcon>
                            </v-avatar>
                        </v-col>
                        <v-col sm="8" class="text-end">
                            <p
                            class="
                                text-sm
                                mb-0
                                text-capitalize text-body
                                font-weight-light
                            "
                            >
                            Materiales
                            </p>
                            <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                {{ counts.materials }}
                            </h4>
                        </v-col>
                        </v-row>
                        <hr class="dark horizontal mt-3 mb-4" />
                        <v-row class="px-4">
                        <v-col cols="12">
                            <p class="mb-0 text-body">
                            <span class="font-weight-light ms-1">Total de materiales</span>
                            </p>
                        </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card class="mb-6 card-shadow border-radius-xl py-4">
                        <v-row no-gutters class="px-4">
                        <v-col sm="4">
                            <v-avatar
                            color="bg-gradient-info border-radius-xl mt-n8"
                            class="shadow-dark"
                            height="64"
                            width="64"
                            >
                            <v-icon color="white" size="30px">
                                fas fa-hammer
                            </v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col sm="8" class="text-end">
                            <p
                            class="
                                text-sm
                                mb-0
                                text-capitalize text-body
                                font-weight-light
                            "
                            >
                            Equipos
                            </p>
                            <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                {{ counts.equipments }}
                            </h4>
                        </v-col>
                        </v-row>
                        <hr class="dark horizontal mt-3 mb-4" />
                        <v-row class="px-4">
                        <v-col cols="12">
                            <p class="mb-0 text-body">
                            <span class="font-weight-light ms-1">Total de equipos</span>
                            </p>
                        </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card class="mb-6 card-shadow border-radius-xl py-4">
                        <v-row no-gutters class="px-4">
                        <v-col sm="4">
                            <v-avatar
                            color="bg-gradient-info border-radius-xl mt-n8"
                            class="shadow-dark"
                            height="64"
                            width="64"
                            >
                            <v-icon color="white" size="30px">
                                fas fa-archive
                            </v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col sm="8" class="text-end">
                            <p
                            class="
                                text-sm
                                mb-0
                                text-capitalize text-body
                                font-weight-light
                            "
                            >
                            Cotizaciones
                            </p>
                            <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                                {{ counts.quotes | VMask(currencyMask) }}
                            </h4>
                        </v-col>
                        </v-row>
                        <hr class="dark horizontal mt-3 mb-4" />
                        <v-row class="px-4">
                        <v-col cols="12">
                            <p class="mb-0 text-body">
                            <span class="font-weight-light ms-1">Mes actual</span>
                            </p>
                        </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="6" class="margin-graphic-dashboard">
            <v-card elevation="2">
                <v-row>
                    <v-col cols="12">
                        <div class="px-4 pt-2 pb-0 d-flex">
                            <div class="ms-4">
                                <h6 class="text-h6 text-typo font-weight-bold">
                                Precio del dólar al cambio de {{ monthsArray[monthsArray.length - 1] }} :
                                </h6>
                                <p class="font-weight-light text-secondary text-md">
                                $ {{ exchangeRatesHistoryArray[exchangeRatesHistoryArray.length - 1] }}
                                </p>
                            </div>
                            <div class="my-2 ms-auto">
                                <v-badge bottom bordered :color="lastTwoExchangeRatesPercent > 0 ? 'green' : 'red'" dot offset-x="4" offset-y="9">
                                </v-badge>
                                <span class="text-dark text-md ms-3">{{ lastTwoExchangeRatesPercent }} %</span>
                            </div>
                            
                            </div>
                            <div class="card-padding pt-0 px-4">
                            <div class="chart">
                                <canvas
                                :id="lineCharExchangeRatesId"
                                class="chart-canvas"
                                height="300"
                                ></canvas>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Chart from "chart.js/auto";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default {
    name: "dashboard-layout",
    data(){
        return{
            timestamp: "",
            lineCharExchangeRatesId: '',
            exchangeRatesHistory: null,
            chartInstance: null,
            counts: {},
            currencyMask
        }
    },
    components: {
    },
    created() {
        setInterval(this.getNow, 1000);
    },
    mounted() {
        this.getExchangeRatesHistoryArray();
        this.getCountSupplier();
    },
    methods: {
        getNow() {
            const today = new Date();
            const date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date +' '+ time;
            this.timestamp = dateTime;
        },
        async getExchangeRatesHistoryArray() {
            try {
            const response = await this.$http.get("/exchange/current_history");
            this.exchangeRatesHistory = response.data;
            this.lineCharExchangeRatesId = `line-chart-exchange-rates-${Math.floor(Math.random() * 1000)}`;
            this.$nextTick(() => {
            this.createChart();
            });
            } catch (error) {
            console.error("Error al obtener el historial de precios:", error);
            }
        },

        createChart () {
        var ctx = document
        .getElementById(this.lineCharExchangeRatesId)
        .getContext("2d");
        if (this.chartInstance) {
            this.chartInstance.destroy();
        }

        this.chartInstance = new Chart(ctx, {
            type: "line",
            data: {
            labels: this.monthsArray,
            datasets: [
                {
                label: "Dólar",
                tension: 0.4,
                pointRadius: 0,
                borderColor: "green",
                borderWidth: 3,
                backgroundColor: "transparent",
                fill: true,
                data: this.exchangeRatesHistoryArray,
                maxBarThickness: 6,
                },
            ],
            },
            options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                display: false,
                },
            },
            interaction: {
                intersect: false,
                mode: "index",
            },
            scales: {
                y: {
                grid: {
                    drawBorder: false,
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: false,
                    borderDash: [5, 5],
                    color: "#c1c4ce5c",
                },
                ticks: {
                    display: true,
                    padding: 10,
                    color: "#b2b9bf",
                    font: {
                    size: 14,
                    weight: 300,
                    family: "Roboto",
                    style: "normal",
                    lineHeight: 2,
                    },
                },
                },
                x: {
                grid: {
                    drawBorder: false,
                    display: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                    borderDash: [5, 5],
                    color: "#c1c4ce5c",
                },
                ticks: {
                    display: true,
                    color: "#b2b9bf",
                    padding: 10,
                    font: {
                    size: 14,
                    weight: 300,
                    family: "Roboto",
                    style: "normal",
                    lineHeight: 2,
                    },
                },
                },
            },
            },
        });
        },
        getCountSupplier(){
            this.$http.get(`/get_count_dashboard`)
                .then(response => {
                    this.counts = response.data
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    },
    computed: {
    exchangeRatesHistoryArray () {
        if (this.exchangeRatesHistory && this.exchangeRatesHistory.length > 0) {
            return this.exchangeRatesHistory.map(item => parseFloat(item.dollar));
        } else {
            return [];
        }
    },

    monthsArray() {
        if (this.exchangeRatesHistory && this.exchangeRatesHistory.length > 0) {
            return this.exchangeRatesHistory.map(item => {
            const date = new Date(item.created_at);

            const month = date.toLocaleString('default', { month: 'long' });

            const day = date.getDate();

            const year = date.getFullYear();

            return `${day} de ${month} del ${year}`;
            });
        } else {
            return [];
        }
    },

    lastTwoExchangeRatesPercent() {
        if (this.exchangeRatesHistory && this.exchangeRatesHistory.length > 1) {
            const historyArray = this.exchangeRatesHistoryArray;
            const lastRate = historyArray[historyArray.length - 1];
            const secondLastRate = historyArray[historyArray.length - 2];

            if (secondLastRate !== 0) { 
                const percentageDifference = (((lastRate - secondLastRate) / secondLastRate) * 100).toFixed(2);

                return parseFloat(percentageDifference);
            } else {
                return null;
            }
        } else {
            return null;
        }
    },
  }
}
</script>

<style scoped>
.card-margin-dashboard{
    margin-top: 3vh;
}
.margin-img-dashboard{
    margin-top: 3vh;
}
.img-logo {
    margin-top: 20vh !important;
}
.icon-small {
    width: 36px;
    height: 36px;
}
.margin-graphic-dashboard{
    margin-top: 5vh;
}
</style>