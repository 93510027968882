<template>
    <div>
        <v-btn outlined color="blue" title="Agregar a Cotización" @click.stop="dialog = true">
            Agregar equipo a cotización
        </v-btn>

        <v-dialog
        persistent
        v-model="dialog"
        max-width="800"
        >
        <v-card>
            <v-card-title class="text-h5">
                Cotizaciones
            </v-card-title>
            <v-card-subtitle>
                <br>
                Selecciona el tipo de lista de precios y busca el equipo que quieres agregar
            </v-card-subtitle>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" class="padding-col">
                            <v-combobox
                                v-model="types_clasification"
                                :items="equipment_clasifications"
                                solo
                                flat
                                color="blue"
                                label="Selecciona una clasificación"
                                background-color="#f3f4f9"
                                @input="getListPrices()"
                                class="padding-combobox mx-4"
                            >
                            </v-combobox>
                        </v-col>
                        <v-col cols="11">
                            <v-autocomplete
                                v-model="equipment"
                                :loading="loading"
                                :items="listPrices.data"
                                :search-input.sync="searchEquipment"
                                :filter="customFilter"
                                color="blue"
                                class="mx-4"
                                flat
                                hide-no-data
                                hide-details
                                label="Buscar por clave o nombre"
                                solo
                                background-color="#f3f4f9"
                                :disabled="types_clasification == null"
                            >
                                <template v-slot:selection="data">
                                    Clave: {{  data.item.key }} Nombre: {{ data.item.name }}
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="'Clave: '+data.item.key"></v-list-item-title>
                                            <v-list-item-subtitle v-html="'Nombre: '+data.item.name"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="1" class="d-flex align-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" color="blue" @click="addEquipmentToSelectedEquipment(equipment,types_clasification)" icon :disabled="equipment == null">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Agregar material</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="12">
                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left">
                                        Equipo
                                    </th>
                                    <th class="text-right">
                                        Acciones
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in selectedEquipment"
                                    :key="item.equipment.id"
                                    >
                                    <td>
                                        {{ item.equipment.name }}
                                    </td>
                                
                                    <td class="text-right">
                                        <!-- <v-btn @click="editItem(item)" color="orange" icon>
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn> -->
                                        <v-btn color="red" icon @click="remove(item)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                        
                                    </td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="red"
                outlined
                @click="closeDialog()"
            >
                Cancelar
            </v-btn>

            <v-btn
                :disabled="equipment == {} || selectedEquipment .length == 0"
                color="green"
                outlined
                @click="saveEquipmentsToBids()"
            >
                Agregar
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

    </div>
</template>

<script>
import debounce from "lodash.debounce";
export default {
    props: {
        bid: Object,
    },
    data () {
      return {
        loading: false,
        searchEquipment: "",
        dialog: false,
        equipment_clasifications: ['ListaPrPlas', 'ListaPrTris', 'ListaPrNpts'],
        types_clasification: null,
        listPrices: [],
        equipment: {},
        selectedEquipment: [],
      }
    },
    methods: {
        saveEquipmentsToBids() {
            let bid = {
                bid_id: this.bid.id,
                equipments: this.selectedEquipment,
            }
            this.$http.post('/bid_equipments/create', bid)
            .then(response => {
                if(response.status == 200){
                    this.$swal.fire("", response.data.message, "success");
                    this.listPrices = [];
                    this.equipment = {};
                    this.selectedEquipment = [];
                    this.types_clasification = null;
                    this.searchEquipment = "";
                    this.$emit('update');
                }
                if(response.status == 203){
                    this.$swal.fire("", response.data.message, "warning");
                }
            })
            .catch((error) => {
                
            })
            .finally(this.dialog = false);
        },
        remove (item) {
            const index = this.selectedEquipment.indexOf(item)
            if (index >= 0) this.selectedEquipment.splice(index, 1)
        },
        closeDialog() {
            this.listPrices = [];
            this.equipment = {};
            this.selectedEquipment = [];
            this.types_clasification = null;
            this.searchEquipment = "";
            this.dialog = false
        },
        customFilter(item, queryText, itemText) {
            const includesKey = item.key.toLowerCase().includes(queryText.toLowerCase());
            const includesName = item.name.toLowerCase().includes(queryText.toLowerCase());
            
            return includesKey || includesName;
        },
        async getListPrices(page = 1){
            this.loading = true
            await this.$http.get("/get_list_prices", {
                params: {
                    query: this.searchEquipment,
                    clasifications: this.types_clasification
                }
            })
            .then(response => {
                this.listPrices = response.data;
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(this.loading = false);
        },
        addEquipmentToSelectedEquipment(equipment,type_clasification) {
            let equipments = {
                type: JSON.stringify(type_clasification),
                equipment: equipment
            }
            this.selectedEquipment.push(equipments);
            this.equipment = {};
        },
    },
    mounted() {
        this.debounceSearchEquipment = debounce(() => {
            this.getListPrices();
        }, 200);
    },
    watch: {
        searchEquipment () {
            this.debounceSearchEquipment();
        },
    }
}
</script>

<style>
.toolbar__items {
  flex-wrap: wrap;
}
.padding-col{
    padding: 0px !important;
}
.padding-combobox{
    padding-left: 12px !important;
}
</style>