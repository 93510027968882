<template>
    <v-app-bar
      height="auto"
      class="blur border-radius-lg shadow top-0 position-absolute mt-7 mb-3 py-2 mx-6"
    >
      <v-container class="py-0" fluid>
        <v-row>
          <v-col cols="3" lg="2" xl="2" class="d-flex align-center">
            <v-img
                class="size-logo"
                src="@/assets/img/illustrations/triaso/triaso_logo_color.png"
              >
              </v-img>
            <!-- <p class="font-weight-bolder ms-lg-0 ms-4 mb-0 text-typo text-sm">
              TRIASO
            </p> -->
          </v-col>
  
          <v-col cols="7" class="text-left d-flex align-center">
            <v-btn v-if="!$vuetify.breakpoint.mobile" style="text-transform: capitalize;" plain to="/dashboard"><v-icon left class="font-size-3k">mdi-view-dashboard-outline</v-icon>Dashboard</v-btn>
            <template v-if="!$vuetify.breakpoint.mobile">
              <v-menu
                v-for="item in links"
                :key="item.name"
                open-on-hover
                offset-y
                bottom
                min-width="360"
                max-width="600"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :ripple="false"
                    color="transparent"
                    :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                    class="
                      text-typo
                      font-weight-normal
                      ls-0
                      align-center
                    "
                    depressed
                    link
                    :to="item.link"
                  >
                    <v-btn style="text-transform: capitalize;" plain>{{ item.name }}</v-btn>
                    <v-img
                      src="@/assets/img/down-arrow-dark.svg"
                      alt="down-arrow"
                      class="arrow ms-1 d-lg-block d-none"
                    />
                  </v-btn>
                </template>
  
                <v-list class="border-radius-xl overflow-hidden">
                  <template v-if="item.name == 'Catálogos'">
                    <v-list class="py-0">
                        <v-list-item class="mb-0 ps-0" v-if="can('consultar proveedores')">
                          <v-btn
                              :ripple="false"
                              text
                              class="text-dark font-weight-normal ls-0"
                              depressed
                              link
                              to="/proveedores" 
                          >
                              Proveedores
                              <v-spacer></v-spacer>
                          </v-btn>
                        </v-list-item>
                        <v-list-item class="mb-0 ps-0" v-if="can('consultar material')">
                          <v-btn
                              :ripple="false"
                              text
                              class="text-dark font-weight-normal ls-0"
                              depressed
                              link
                              to="/materiales"
                          >
                              Materiales
                              <v-spacer></v-spacer>
                          </v-btn>
                        </v-list-item>
                        <v-list-item class="mb-0 ps-0" v-if="can('consultar tipos de materiales')">
                          <v-btn
                              :ripple="false"
                              text
                              class="text-dark font-weight-normal ls-0"
                              depressed
                              link
                              to="/tipos_materiales"
                          >
                              Tipos de materiales
                              <v-spacer></v-spacer>
                          </v-btn>
                        </v-list-item>
                        <v-list-item class="mb-0 ps-0" v-if="can('consultar unidades')">
                          <v-btn
                              :ripple="false"
                              text
                              class="text-dark font-weight-normal ls-0"
                              depressed
                              link
                              to="/unidades"
                          >
                              Unidades
                              <v-spacer></v-spacer>
                          </v-btn>
                        </v-list-item>
                        <v-list-item class="mb-0 ps-0" v-if="can('consultar tipos de cambio')">
                          <v-btn
                              :ripple="false"
                              text
                              class="text-dark font-weight-normal ls-0"
                              depressed
                              link
                              to="/tipo_de_cambio"
                          >
                              Tipo de cambio
                              <v-spacer></v-spacer>
                          </v-btn>
                        </v-list-item>
                    </v-list>
                  </template>
                  <template v-if="item.name == 'Relación de materiales'">
                    <v-list class="py-0">
                        <v-list-item class="mb-0 ps-0" v-if="can('consultar equipos')">
                          <v-btn
                              :ripple="false"
                              text
                              class="text-dark font-weight-normal ls-0"
                              depressed
                              link
                              to="/equipos"
                          >
                              Equipos
                              <v-spacer></v-spacer>
                          </v-btn>
                        </v-list-item>
                        <v-list-item class="mb-0 ps-0" v-if="can('consultar lista de precio plantas')">
                          <v-btn
                              :ripple="false"
                              text
                              class="text-dark font-weight-normal ls-0"
                              depressed
                              link
                              to="/plantas"
                          >
                              Lista de precio plantas
                              <v-spacer></v-spacer>
                          </v-btn>
                        </v-list-item>
                        <v-list-item class="mb-0 ps-0" v-if="can('consultar lista de precio trituradoras')">
                          <v-btn
                              :ripple="false"
                              text
                              class="text-dark font-weight-normal ls-0"
                              depressed
                              link
                              to="/trituradoras"
                          >
                              Lista de precio trituradoras
                              <v-spacer></v-spacer>
                          </v-btn>
                        </v-list-item>
                        <v-list-item class="mb-0 ps-0" v-if="can('consultar lista de precio no-plas-tris')">
                          <v-btn
                              :ripple="false"
                              text
                              class="text-dark font-weight-normal ls-0"
                              depressed
                              link
                              to="/no_plantas_no_trituradoras"
                          >
                              Lista de precio No-Plas-Tris
                              <v-spacer></v-spacer>
                          </v-btn>
                        </v-list-item>
                    </v-list>
                  </template>
                </v-list>
              </v-menu>
            </template>
            <v-btn v-if="!$vuetify.breakpoint.mobile && can('consultar cotización')" style="text-transform: capitalize;" plain to="/cotizaciones"><v-icon left class="font-size-3k">mdi-currency-usd</v-icon>Cotizaciones</v-btn>
          </v-col>
  
          <v-col cols="3" class="text-right d-flex justify-end">
            <template v-if="!$vuetify.breakpoint.mobile">
              <div class="text-center">
                <v-menu
                transition="slide-y-transition"
                offset-y
                min-width="115px"
                max-width="115px"
                v-if="can('consultar usuarios') || can('consultar bitacora')"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="text-transform: capitalize;"
                      plain
                      icon
                      depressed
                      :ripple="false"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon right class="size-icons-navbar ml-4">mdi-cog-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      href="/usuarios" v-if="can('consultar usuarios')"
                    >
                      <v-list-item-title>Usuarios</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      href="/bitacora" v-if="can('consultar bitacora')"
                    >
                      <v-list-item-title>Bitacora</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                    style="text-transform: capitalize;"
                    @click="logout()" 
                    plain
                    >
                    Salir 
                    <v-icon right class="size-icons-navbar ml-4">mdi-exit-to-app</v-icon>
                </v-btn>
              </div>
            </template>
            <!-- <template v-if="!$vuetify.breakpoint.mobile">
              <v-btn
                
                elevation="0"
                color="#fff"
                class="font-weight-bolder py-4 px-5 ms-2"
                
                
                small
              >
                Cerrar Sesión
              </v-btn>
            </template> -->
  
            <v-btn
              v-show="$vuetify.breakpoint.mobile"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                drawer-toggler
                btn-toggler-hover
                py-3
                px-6
                rounded-sm
              "
              color="transparent"
              @click="dialog = true"
            >
              <div class="drawer-toggler-inner">
                <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
                <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
                <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              </div>
            </v-btn>
  
            <v-dialog
              v-model="dialog"
              content-class="position-absolute top-0"
              width="95%"
            >
              <v-card class="card-shadow card-padding">
                <v-card-title
                  class="
                    pt-0
                    px-4
                    card-padding
                    text-h6
                    font-weight-bolder
                    text-typo
                    justify-space-between
                    border-bottom
                  "
                >
                  SAMPEQ
  
                  <v-btn icon width="31" :ripple="false" @click="dialog = false">
                    <v-icon size="20" class="text-typo">fas fa-times</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="card-padding pb-0 d-flex flex-column px-0">
                  <v-btn
                    v-for="item in links"
                    :key="item.name"
                    :ripple="false"
                    text
                    class="text-typo text-capitalize ls-0"
                    depressed
                    link
                    :to="item.link"
                  >
                    {{ item.name }}
                    <v-spacer></v-spacer>
                  </v-btn>
  
                  <div class="border-bottom my-7"></div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
  </template>
  <script>
  
  export default {
    name: "app-bar-component",
    components: {
    },
    props: {
      background: String,
      hasBg: Boolean,
      linkColor: String,
    },
    data() {
      return {
        dialog: false,
        modelsList: [
            {
            title: "Equipos",
            link: "/equipos",
            },
            {
            title: "Lista de Precio Plantas",
            link: "/plantas",
            },
            {
            title: "Lista de Precio Trituradoras",
            link: "/trituradoras",
            },
            {
            title: "Lista de Precio No-Plas-Tris",
            link: "/no_plantas_no_trituradoras",
            },
        ],
        catalogsList: [
            {
            title: "Proveedores",
            link: "/proveedores",
            },
            {
            title: "Materiales",
            link: "/materiales",
            },
            {
            title: "Tipo de Materiales",
            link: "/tipos_materiales",
            },
            {
            title: "Unidades",
            link: "/unidades",
            },
            {
            title: "Tipo de Cambio",
            link: "/tipo_de_cambio",
            },
        ],
        links: [
          {
            name: "Catálogos",
          },
          {
            name: "Relación de materiales",
          },
        ],
        settingsList: [
          {
            name: "Usuarios",
            href: "/usuarios"
          },
          {
            name: "Bitacora",
            href: "/bitacora"
          }
        ]
      };
    },
    methods: {
        logout() {
        this.$http.post("/logout")
            .then(() => {
                this.$router.push("/authentication");
                location.reload()
            })
            .catch((error) => {
                console.error(error);
            });
        },
    }
  };
</script>
<style>
.v-btn{
  text-transform: none !important;
}
</style>
  