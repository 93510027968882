<template>
    <div>
      <v-card>
          <v-card-title>Precio de venta</v-card-title>
          <v-card-text>
              <v-simple-table>
                  <template v-slot:default>
                      <thead>
                          <tr>
                              <th class="text-left">
                                  Suma
                              </th>
                              <th class="text-left">
                                  Pesos con IVA
                              </th>
                              <th class="text-left">
                                  Dólares
                              </th>
                              <th class="text-left">
                                  Utilidad en pesos
                              </th>
                              <th class="text-left">
                                  Factor total
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <td>{{ bid.sum.sum | VMask(currencyMask)}}</td>
                            <td>{{ bid.sum.pesos_with_iva | VMask(currencyMask)}}</td>
                            <td>{{ bid.sum.dollars | VMask(currencyMask)}}</td>
                            <td>{{ bid.sum.utility_in_pesos | VMask(currencyMask)}}</td>
                            <td>{{ bid.sum.total_factor }}</td>
                          </tr>
                      </tbody>
                  </template>
              </v-simple-table>
          </v-card-text>
      </v-card>
    </div>
  </template>
  
<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default {
    props: {
        bid: Object
    },
    data() {
        return {
            currencyMask
        }
    },
}
</script>

<style>

</style>