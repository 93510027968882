<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col md="11" sm="6">
                    <h2>Relación de materiales</h2>
                </v-col>
                <v-col cols="12" md="1" sm="6">
                    <v-btn @click="$router.back()" outlined color="red">Regresar</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="6" sm="12">
                <equipment-information-component />
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <sums-of-materials-and-equipments-component ref="sum" />
            </v-col>
            <v-col cols="12" md="12" sm="12">
                <relation-to-materials-table-component @update="updateSums" />
            </v-col>
            </v-row>
        </v-container>
    </div>
  </template>
  
  <script>
  import EquipmentInformationComponent from "@/components/App/Equipments/RelationToMaterials/EquipmentInformationComponent.vue"
  import SumsOfMaterialsAndEquipmentsComponent from "@/components/App/Equipments/RelationToMaterials/SumsOfMaterialsAndEquipmentsComponent.vue"
  import RelationToMaterialsTableComponent from "@/components/App/Equipments/RelationToMaterials/RelationToMaterialsTableComponent.vue"

  export default {
      name: "relation-to-materials-layout",
      components: {
        EquipmentInformationComponent,
        SumsOfMaterialsAndEquipmentsComponent,
        RelationToMaterialsTableComponent
      },
      data() {
        return {

        }
      },
      methods: {
        updateSums() {
            this.$refs.sum.getSum();
        }
      }
  }
  </script>
  
  <style>
  
  </style>