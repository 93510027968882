<template>
  <div>
    <v-container>
      <v-row justify="center" align="center">
        <v-col
          cols="12"
        >
          <v-card elevation="4">
            <v-card-title style="padding-top: 10vh;" class="pb-3 text-h3 font-weight-bold justify-center text-info">
              <v-row justify="center" align="center">
                <v-col
                cols="7"
                >
                <v-img
                src="@/assets/img/illustrations/triaso/triaso_logo_color.png"
              >
              </v-img>
                </v-col>

              </v-row>
            </v-card-title>
            <v-card-subtitle class="text-h6">
              <v-row class="pb-6" justify="center">
                <v-col
                  cols="10"
                >
                  Ingresa tu correo y contraseña para iniciar sesión
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-text>
              <v-form ref="form" v-model="isFormValid">
                <v-container>
                  <v-row justify="center">
                    <v-col
                      cols="10"
                    >
                    <!-- <v-text-field
                        v-model="form.name"
                        :rules="nameRules"
                        required
                        label="Usuario"
                        hint="Ingresa el usuario"
                        outlined
                      ></v-text-field> -->
                      <v-text-field
                        v-model="form.email"
                        :rules="emailRules"
                        required
                        label="Correo"
                        hint="Ingresa el correo"
                        outlined
                      ></v-text-field>
                      <v-text-field
                        v-model="form.password"
                        :append-icon="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                        :rules="passRules"
                        :type="showPassword ? 'text' : 'password'"
                        name="input-password"
                        label="Contraseña"
                        hint="Ingresa la contraseña"
                        counter
                        outlined
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions style="padding-bottom: 10vh;">
              <v-row class="pb-5" justify="center">
                    <v-col
                      cols="9"
                    >
                    <v-btn dark depressed x-large color="primary" block @click="login()">Iniciar</v-btn>
                    </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.visibility" :vertical="true" absolute right bottom color="red" timeout="4000">
        {{ error  }}
        <v-btn x-small text @click="snackbar.visibility = false">
          x
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
      return {
        nameRules: [
          v => !!v || 'Ingresa el usuario'
        ],
        passRules: [
          v => !!v || 'Ingresa una contraseña'
        ],
        emailRules: [
          v => !!v || 'Ingresa un correo',
          v => /.+@.+/.test(v) || 'Ingresa un correo válido',
        ],
        isFormValid: false,
        showPassword: false,
        form: {
          // name: "",
          email: "",
          password: ""
        },
        errors: {},
        snackbar: {
          visibility: false
        },
        error: null
      };
    },
    methods: {
      login() {
        if (this.$refs.form.validate()) {
          this.$http.post("/login", this.form)
          .then((res) => {
              console.log(res);
              if (this.$route.path !== "/dashboard") {
                window.location.href = "/dashboard";
              }
          })
          .catch((error) => {
              console.error(error.email);
              this.errors = error.response.data.errors;
              if(this.errors){
                this.errors.email.forEach(element => {
                  this.error = element;
                });
                if(this.error = "These credentials do not match our records."){
                  this.error = "Las credenciales no son correctas."
                  this.snackbar.visibility = true;
                }
              }
          });
        }
      },
    }
}
</script>

<style>

</style>