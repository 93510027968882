/*
=========================================================
* Vuetify Material Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page:  https://www.creative-tim.com/product/vuetify-material-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import "@/assets/css/main.css";
import editIcon from './views/App/Layouts/Icons/Edit.vue';
import deleteIcon from './views/App/Layouts/Icons/Delete.vue';
import supplierIcon from './views/App/Layouts/Icons/Supplier.vue';
import materialIcon from './views/App/Layouts/Icons/Material.vue';

// Functions import
import Axios from "./functions/Axios"
import Csrf from "./api/Csrf";
import formatDate from "./functions/formatDate";
import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs'

Vue.config.productionTip = false;

//Functions
Vue.prototype.$http = Axios;
Vue.prototype.$csrf = Csrf;
Vue.prototype.formatDate = formatDate;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import VueCountryCode from "vue-country-code";
Vue.use(VueCountryCode);

import VueMask from 'v-mask'
Vue.use(VueMask);

// plugin setup
Vue.use(DashboardPlugin);

//components
Vue.component('editIcon', editIcon);
Vue.component('deleteIcon', deleteIcon);
Vue.component('supplierIcon', supplierIcon);
Vue.component('materialIcon', materialIcon);

//Laravel Permission
Vue.use(LaravelPermissionToVueJS);

function getPermissions (){
  window.Laravel = {};
  Axios.get('/get-permissions')
    .then(response => {
      window.Laravel.jsPermissions = response.data;
      new Vue({
        router,
        vuetify,
        render: (h) => h(App),
      }).$mount("#app");
    })
    .catch(error => {
      console.error("Error al obtener los permisos");
      new Vue({
        router,
        vuetify,
        render: (h) => h(App),
      }).$mount("#app");
    });
  
}

getPermissions();


