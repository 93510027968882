<template>
    <div>
      <v-container fluid>
          <v-row>
              <v-col cols="12">
                  <equipments-table-component />
              </v-col>
          </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  import EquipmentsTableComponent from "@/components/App/Equipments/EquipmentsTableComponent"
  export default {
      name: "equipment-layout",
      components: {
        EquipmentsTableComponent
      },
  }
  </script>
  
  <style>
  
  </style>