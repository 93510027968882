var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"padding-container",attrs:{"fluid":""}},[_c('v-card',{staticClass:"card-margin-plants"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"formEquipment",on:{"submit":function($event){$event.preventDefault();return _vm.getPlants()}}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"item-key":"id","items":_vm.plants.data,"group-by":"clasificate_as","hide-default-footer":true,"dense":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',{staticClass:"grey white--text",attrs:{"colspan":_vm.headers.length}},[_c('v-btn',{attrs:{"color":"white","icon":""}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")])],1),_c('span',[_vm._v(_vm._s(group))])],1)]}},{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12","lg":"3"}},[_c('div',{staticClass:"card-header-padding"},[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" Plantas ")]),_c('p',{staticClass:"text-sm text-body font-weight-light mb-0"},[_vm._v(" Registro de plantas ")])])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12","lg":"3"}},[_c('v-text-field',{staticClass:"ml-5",attrs:{"input-alternative":"","input-focused-alternative":"","input-icon":"","outlined":"","placeholder":"Buscar","background-color":"#f3f4f9"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("fas fa-search")])],1)],1),_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12","lg":"3"}}),_c('v-col',{staticClass:"card-header-padding d-flex justify-end",attrs:{"cols":"12","lg":"6"}},[(_vm.can('exportar lista de precio plantas'))?_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"green","title":"Exportar"},on:{"click":function($event){return _vm.downloadExport()}}},[_vm._v(" Exportar ")]):_vm._e(),(_vm.can('agregar factores generales lista de precio plantas') && _vm.hasfactor == false)?_c('add-factors-dialog-component',{staticClass:"mr-4",attrs:{"type":'ListaPrPlas'},on:{"update":_vm.updateFactor}}):_vm._e(),(_vm.can('agregar factores generales lista de precio plantas') && _vm.hasFactor == true)?_c('edit-factors-dialog-component',{attrs:{"type":'ListaPrPlas'},on:{"update":_vm.updateFactor}}):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.can('agregar factores individuales lista de precio plantas'))?_c('add-individual-factors-component',{attrs:{"disabled":!_vm.hasFactor ? true : false,"equipment":item,"type":'ListaPrPlas'},on:{"update":_vm.getPlants}}):_vm._e()]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"card-padding"},[_c('v-col',{attrs:{"cols":"12","lg":"3","sm":"12"}},[_c('span',{staticClass:"text-body me-3 text-sm d-flex"},[_vm._v("Mostrando del "+_vm._s(_vm.plants.from)+" de "+_vm._s(_vm.plants.total)+" registros")])]),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"12","lg":"9"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"blue","length":_vm.plants.last_page,"circle":"","total-visible":9},on:{"input":_vm.getPlants},model:{value:(_vm.plants.current_page),callback:function ($$v) {_vm.$set(_vm.plants, "current_page", $$v)},expression:"plants.current_page"}})],1)],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate.dayMonthAndYear(item.date))+" ")]}},_vm._l((_vm.materialTypes),function(type,index){return {key:("item.sum.types[" + index + "].total"),fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("VMask")(item.sum.types[index].total,_vm.currencyMask)))])]}}}),{key:"item.sum_equipment_factor[0].sum",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("VMask")(item.sum_equipment_factor[0] ? item.sum_equipment_factor[0].sum : "0",_vm.currencyMask)))])]}},{key:"item.sum_equipment_factor[0].pesos_with_iva",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("VMask")(item.sum_equipment_factor[0] ? item.sum_equipment_factor[0].pesos_with_iva : "0",_vm.currencyMask)))])]}},{key:"item.sum_equipment_factor[0].dollars",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("VMask")(item.sum_equipment_factor[0] ? item.sum_equipment_factor[0].dollars : "0",_vm.currencyMask)))])]}},{key:"item.sum_equipment_factor[0].utility_in_pesos",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("VMask")(item.sum_equipment_factor[0] ? item.sum_equipment_factor[0].utility_in_pesos: "0",_vm.currencyMask)))])]}},{key:"no-data",fn:function(){return [_vm._v(" No hay tipos de cambios registrados. ")]},proxy:true}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }