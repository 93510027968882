<template>
    <v-row justify="center">
        <v-dialog
            v-model="localShow"
            scrollable
            max-width="600px"
        >
            <v-card>
                <v-toolbar color="#f3f4f9" dark>
                    <v-toolbar-title class="title-color">Proveedor: {{ supplier.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn class="close-window-button" icon dark @click="localShow = false" color="black" rel="tooltip" title="Cerrar">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="margin-body-card">
                    <div class="px-6 pb-6">
                        <h5 class="text-h5 font-weight-bold text-typo">Información del proveedor</h5>
                    </div>
                    <div class="px-6 pb-6 d-flex justify-center">
                        <v-row>
                            <v-col cols="12">
                                <div class="text-body text-sm">
                                    <strong class="text-dark">Codigo:</strong>
                                    {{ supplier.code }}
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="text-body text-sm">
                                    <strong class="text-dark">Nombre:</strong>
                                    {{ supplier.name }}
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="text-body text-sm">
                                    <strong class="text-dark">Nombre del responsable:</strong>
                                    {{ supplier.name_manager }}
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="text-body text-sm">
                                    <strong class="text-dark">Web Site:</strong>
                                    {{ supplier.web_site }}
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="text-body text-sm" v-if="supplier.contact_information">
                                    <strong class="text-dark">Teléfono Principal:</strong>
                                    <template v-for="phone in supplierPhones" v-if="phone.isPrincipal == true">
                                        {{ phone.prefix }} {{ phone.phone }}
                                    </template>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="text-body text-sm" v-if="supplier.contact_information">
                                    <strong class="text-dark">Email Principal:</strong>
                                    <template v-for="email in supplierEmails" v-if="email.isPrincipal == true">
                                        {{ email.email }}
                                    </template>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="text-body text-sm" v-if="supplier.contact_information">
                                    <strong class="text-dark">Domicilio:</strong>
                                    {{ supplier.contact_information.address }}
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="text-body text-sm" v-if="supplier.contact_information">
                                    <strong class="text-dark">Observaciones:</strong>
                                    {{ supplier.contact_information.observations }}
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="text-body text-sm" v-if="supplier.contact_information">
                                    <strong class="text-dark">Teléfono adicionales:</strong>
                                    <template v-for="phone in supplierPhones" v-if="phone.isPrincipal == false">
                                        {{ phone.prefix }} {{ phone.phone }} <br>
                                    </template>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="text-body text-sm" v-if="supplier.contact_information">
                                    <strong class="text-dark">Emails adicionales:</strong>
                                    <template v-for="email in supplierEmails" v-if="email.isPrincipal == false">
                                        {{ email.email }} <br>
                                    </template>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    data() {
        return{
            localShow: false,
            supplier: [],
            supplierPhones: [],
            supplierEmails: [],
        }
    },
    props: {
        show: Boolean,
        supplier_id: Number,
    },
    watch: {
        show: function (value) {
            this.localShow = value;
        },
        localShow: function (value) {
            this.$emit('update:show', value)
        },
        supplier_id: function () {
            this.getSupplier();
        },
    },
    methods: {
        getSupplier(){
            this.$http.get("/get_suppliers_materials", {
                params: {
                    supplierId: this.supplier_id
                }
            })
            .then(response => {
                this.supplier = response.data;
                let phone = JSON.parse(response.data.contact_information.phone);
                let email = JSON.parse(response.data.contact_information.email);
                this.supplierPhones = phone;
                this.supplierEmails = email;
            })
            .catch((error) => {
                console.error(error);
            });
        },
    },
}
</script>
<style>
.title-color{
    color: black;
}
.margin-body-card{
    margin-top: 3vh;
}
.padding-col{
    padding: 0 !important;
    margin-top: 1vh;
}
.margin-btn{
    margin-top: 3vh;
}
</style>