<template>
    <v-container fluid>
        <v-row class="card-margin-permission">
            <v-col cols="4" class="padding-top">
                <v-card>
                    <div class="px-6 py-6">
                        <h5 class="text-h5 font-weight-bold text-typo">Información del usuario</h5>
                    </div>
                    <div class="px-6 pb-6 pt-0">
                        <v-row>
                           <v-list>
                                <v-list-item-group class="border-radius-sm">
                                    <v-list-item class="px-3 border-radius-sm">
                                        <v-list-item-content class="py-0">
                                            <div class="text-body text-sm">
                                                <strong class="text-dark">Nombre:</strong>
                                                {{ user.name }}
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                                <v-list-item-group class="border-radius-sm">
                                    <v-list-item class="px-3 border-radius-sm">
                                        <v-list-item-content class="py-0">
                                            <div class="text-body text-sm">
                                                <strong class="text-dark">Correo:</strong>
                                                {{ user.email }}
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                                <v-list-item-group class="border-radius-sm">
                                    <v-list-item class="px-3 border-radius-sm">
                                        <v-list-item-content class="py-0">
                                            <div class="text-body text-sm" v-for="roles in user.roles" :key="roles.name">
                                                <strong class="text-dark">Rol:</strong>
                                                {{ roles.name }}
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                           </v-list>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="8">
                <v-card>
                    <v-form 
                        ref="formPermission"
                        @submit.prevent="getPermissions()"
                    >
                        <v-data-table
                            :headers="headers"
                            item-key="id"
                            :items="permissions.data"
                            class="elevation-1 row-height"
                            :hide-default-footer="true"
                        >
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="card-header-padding">
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Permisos
                                            </h5>
                                            <p class="text-sm text-body font-weight-light mb-0">
                                            Permisos que tiene este usuario.
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="4" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="search"
                                            input-alternative input-focused-alternative input-icon
                                            class="ml-5"
                                            outlined
                                            placeholder="Buscar"
                                            background-color="#f3f4f9"
                                        >
                                            <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="8" class="btn-padding d-flex justify-end">
                                        <v-btn v-if="!addTemporalPermission && can('agregar permisos')" outlined color="blue" @click="createTemporalPermission()" title="Agregar permiso">
                                            Agregar permiso
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>
                                        <v-btn icon @click="deletePermission(item.id)" title="Borrar permiso" v-if="can('borrar permisos')">
                                            <deleteIcon class="icon-custom">
                                            </deleteIcon>
                                        </v-btn>    
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:body.prepend>
                                <tr v-if="temporalPermission.addTemporalPermission == true">
                                    <td>
                                        <v-autocomplete
                                            v-model="temporalPermission.name"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="permissions_all"
                                            item-text="name"
                                            item-value="name"
                                            :rules="inputRules"
                                        >
                                        <template v-slot:no-data>
                                            No permisos.
                                        </template>
                                        </v-autocomplete>
                                    </td>
                                    <td>
                                        <v-btn icon @click="createPermission(temporalPermission)" title="Crear permiso">
                                            <v-icon dark color="green" fab class="font-size-3k">
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelTemporalPermission()" title="Cancelar creación de permiso">
                                            <v-icon dark color="red" fab class="font-size-3k">
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:footer>
                                <v-row class="card-padding">
                                    <v-col cols="12" lg="3">
                                        <span class="text-body me-3 text-sm d-flex">Mostrando del {{ permissions.from }} de {{ permissions.total }} registros</span>
                                    </v-col>
                                    <v-col cols="12" lg="9" class="ml-auto d-flex justify-end">
                                        <v-pagination
                                        prev-icon="fa fa-angle-left"
                                        next-icon="fa fa-angle-right"
                                        class="pagination"
                                        color="blue"
                                        total-visible="5"
                                        v-model="permissions.current_page"
                                        :length="permissions.last_page"
                                        circle
                                        @input="getPermissions"
                                        :total-visible="9"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:no-data>
                                No hay permisos.
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import debounce from "lodash.debounce";
export default{
    data(){
        return {
            search: "",
            headers: [
                { text: 'Nombre', value: 'name', sortable: false },
                { text: 'Acciones', value: '', sortable: false },
            ],
            itemsPerPage: 10,
            permissions: [],
            permission: {},
            addTemporalPermission: false,
            inputRules: [
                value => !!value || 'Este campo es obligatorio.'
            ],
            temporalPermission: {},
            user: {},
            permissions_all: [],
            errors: {}
        }
    },
    methods: {
        getPermissions(page = 1){
            this.$http.get("/get_permission_user", {
                params: {
                    page: page,
                    query: this.search,
                    perPage: this.itemsPerPage,
                    userId: this.$route.params.user_id
                }
            })
            .then(response => {
                this.permissions = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        createTemporalPermission(){
            if(this.permissions_all == ""){
                this.$swal.fire("", "El usuario ya tiene todos los permisos asignados", "warning");
            }else{
                this.addTemporalPermission = true;
                var permission;
                if(this.permissions.data.length != 0){
                    permission = {
                        name: "",
                        addTemporalPermission: this.addTemporalPermission
                    }
                }else{
                    permission = { 
                        name: "",
                        addTemporalPermission: this.addTemporalPermission
                    }
                }
                this.temporalPermission = permission;
            }
        },
        cancelTemporalPermission(){
            this.temporalPermission = {};
            this.addTemporalPermission = false;
        },
        createPermission(permission){
            this.permission = permission;
            
            if(this.$refs.formPermission.validate() == true){
                this.$http.post(`/usuarios/storePermissions/${this.$route.params.user_id}`, this.permission)
                .then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.addTemporalPermission = false;
                        this.temporalPermission = {};
                        this.getPermissions();
                        this.getAllNotAssignedPermissionsByUser();
                        this.permission = {};
                        this.errors = {};
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors
                });
            }
        },
        deletePermission(id){
            this.$swal({
                title: "Confirmación",
                text: " ¿Estas seguro de borrar este permiso?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                }).then((result) => {
                if (result.value) {
                    this.$http.get(`/usuarios/permission/destroy/${this.$route.params.user_id}/${id}`)
                    .then(response => {
                        this.$swal.fire("", response.data.message, "success");
                        this.getPermissions();
                        this.getAllNotAssignedPermissionsByUser();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                } 
            });
        },
        getUser(){
            this.$http.get(`/usuarios/show/${this.$route.params.user_id}`)
            .then(response => {
                this.user = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        getAllNotAssignedPermissionsByUser(){
            this.$http.get(`/getAllNotAssignedPermissionsByUser/${this.$route.params.user_id}`)
            .then(response => {
                this.permissions_all = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        }
    },
    created(){
        this.getPermissions();
        this.getUser();
        this.debounceSearchPermissions = debounce(() => {
            this.getPermissions();
        }, 500);
        this.getAllNotAssignedPermissionsByUser();
    },
    watch: {
        search () {
            this.debounceSearchPermissions();
        },
    }
}
</script>
<style scoped>
.v-data-table >>> .v-data-table-header {
    background-color: #f3f4f9 !important;
    color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
    color: black !important;
}
.card-margin-permission{
    margin-top: 3vh !important;
}
.data-table{
    padding-top: 12px !important;
    margin-top: 10px !important;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
.padding-top{
    padding-top: 0px !important;
}
.v-data-table.row-height td{
  height: 18px !important;
}
</style>
