<template>
    <div>
      <v-card>
          <v-card-title>Sumas</v-card-title>
          <v-card-text>
              <v-simple-table>
                  <template v-slot:default>
                      <thead>
                          <tr>
                              <th v-for="item in sum.types" :key="item.id" class="text-center">
                                  {{ item.type }}: <br> {{ item.description }}
                              </th>
                              
                              <th class="text-center">
                                  Total
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <td v-for="item in sum.types" :key="item.id" class="text-center">
                                {{ item.total | VMask(currencyMask) }}
                            </td>
                            <td class="text-center">{{ sum.total_pesos | VMask(currencyMask) }}</td>
                          </tr>
                      </tbody>
                  </template>
              </v-simple-table>
          </v-card-text>
      </v-card>
    </div>
  </template>
  
  <script>
  import createNumberMask from 'text-mask-addons/dist/createNumberMask';
  const currencyMask = createNumberMask({
      prefix: '$',
      allowDecimal: true,
      includeThousandsSeparator: true,
      allowNegative: false,
  });
  export default {
      data() {
          return {
              sum: {},
              currencyMask
          }
      },
      methods: {
          async getSum(page = 1) {
              let equipmentId = this.$route.params.equipment_id;
              try {
                  this.$http.get("/getSumByEquipment/"+ equipmentId)
                  .then(response => {
                      this.sum = response.data;
                  })
                  
              } catch (error) {
                  console.error(error)
              }
              
          }
      },
      mounted() {
          this.getSum()
      }
  
  }
  </script>
  
  <style>
  
  </style>