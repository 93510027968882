<template>
    <v-container fluid>
        <v-card>
            <v-row class="card-margin-material">
                <v-col cols="12">
                    <v-form 
                        ref="formMaterial"
                        @submit.prevent="getMaterials()"
                    >
                        <v-data-table
                            :headers="headers"
                            item-key="id"
                            :items="materials.data"
                            class="elevation-1 row-height"
                            :hide-default-footer="true"
                        >
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="card-header-padding">
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Materiales
                                            </h5>
                                            <p class="text-sm text-body font-weight-light mb-0">
                                            Registro de materiales.
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="search"
                                            input-alternative input-focused-alternative input-icon
                                            class="ml-5"
                                            outlined
                                            placeholder="Buscar"
                                            background-color="#f3f4f9"
                                        >
                                            <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-btn icon @click="openFilterDialog" title="Filtros" class="mr-4">
                                            <v-icon dark>
                                                fas fa-filter
                                            </v-icon>
                                        </v-btn>
                                        <v-btn outlined color="black" @click="clearFilter" title="Filtros">
                                            Limpiar filtros
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" lg="6" class="btn-padding d-flex justify-end">
                                        <v-btn v-if="!addTemporalMaterial && !editMaterial && can('exportar material')" @click="downloadExport()" outlined color="green" class="mr-4" title="Exportar">
                                            Exportar
                                        </v-btn>
                                        <v-btn v-if="!addTemporalMaterial && !editMaterial && can('crear material')" outlined color="blue" @click="createTemporalMaterial()" title="Registrar Material">
                                            Registrar Material
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item="{ item}">
                                <tr v-if="item.editMaterial == true">
                                    <td>
                                        <v-combobox
                                            v-model="item.code"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="code_material"
                                            item-text="code"
                                            :search-input.sync="searchCodeMaterial"
                                            :error-messages="errors.code"
                                            :rules="codeRules"
                                            :return-object="false"
                                            ref="comboEditCode"
                                            placeholder="A10022"
                                        >
                                        </v-combobox>
                                    </td>
                                    <td>
                                        <v-combobox
                                            v-model="item.concept"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="concept_material"
                                            item-text="concept"
                                            :search-input.sync="searchConceptMaterial"
                                            :error-messages="errors.concept"
                                            :return-object="false"
                                            :rules="inputRules"
                                            ref="comboEditConcept"
                                        >
                                        </v-combobox>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                            v-model="item.supplier_id"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="suppliers"
                                            item-text="code"
                                            item-value="id"
                                            :rules="inputRules"
                                            :search-input.sync="searchSupplier"
                                        >
                                        <template v-slot:no-data>
                                            No hay proveedores.
                                        </template>
                                        </v-autocomplete>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                            v-model="item.type_material_id"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="types_materials"
                                            item-text="type"
                                            item-value="id"
                                            :rules="inputRules"
                                            :search-input.sync="searchTypeMaterial"
                                        >
                                        <template v-slot:no-data>
                                            No hay tipos de materiales.
                                        </template>
                                        </v-autocomplete>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                            v-model="item.unit_id"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="units"
                                            item-text="description"
                                            item-value="id"
                                            :search-input.sync="searchUnits"
                                        >
                                        <template v-slot:no-data>
                                            No hay unidades.
                                        </template>
                                        </v-autocomplete>
                                    </td>
                                    <td v-if="enable_dollars_edit == false">
                                        <v-text-field 
                                            v-model="item.prices.unit_price_pesos" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            :rules="rulesPrice"
                                            type="number"
                                            class="data-table"
                                            @input="disableDollarsEdit(item)"
                                        ></v-text-field>
                                    </td>
                                    <td v-if="enable_dollars_edit == true">
                                        {{ item.prices.unit_price_pesos = convertDollarsToPesos(item.prices.unit_price_dollars) }}
                                    </td>
                                    <td v-if="enable_pesos_edit == false">
                                        <v-text-field 
                                            v-model="item.prices.unit_price_dollars" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            :rules="rulesPrice"
                                            type="number"
                                            class="data-table"
                                            @input="disablePesosEdit(item)"
                                        ></v-text-field>
                                    </td>
                                    <td v-if="enable_pesos_edit == true">
                                        {{ item.prices.unit_price_dollars = 0 }}
                                    </td>
                                    <td v-if="enable_pesos_edit == false">
                                        {{ getParity() }}
                                    </td>
                                    <td v-else>
                                        {{ item.prices.parity = 0 }}
                                    </td>
                                    <td>
                                        <v-btn icon @click="updateMaterial(item)" :loading="btn_confirm" title="Editar material">
                                            <v-icon dark color="green" fab class="font-size-3k">
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelEditMaterial()" :loading="btn_confirm" title="Cancelar edición">
                                            <v-icon dark color="red" fab class="font-size-3k">
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td>
                                        {{ item.code }}
                                    </td>
                                    <td>
                                        <p class="text-hidden">
                                           <span class="text-hidden-color"> {{ item.concept }} </span>
                                        </p>
                                    </td>
                                    <td>
                                        {{ item.suppliers ? item.suppliers.code : 'El proveedor fue borrado asigne otro' }}
                                    </td>
                                    <td>
                                        {{ item.types_materials ? item.types_materials.type : 'El tipo de material fue borrado asigne otro' }}
                                    </td>
                                    <td>
                                        {{ item.units ? item.units.description : 'La unidad fue borrada asigne otra' }}
                                    </td>
                                    <td class="text-right">
                                        {{ item.prices.unit_price_pesos | VMask(currencyMask) }}
                                    </td>
                                    <td class="text-right">
                                        {{ item.prices.unit_price_dollars | VMask(currencyMask) }}
                                    </td>
                                    <td class="text-right">
                                        {{ item.prices.parity | VMask(currencyMask) }}
                                    </td>
                                    <td>
                                        <v-btn icon @click="editTemporalMaterial(item)" title="Editar Material" v-if="can('editar material')">
                                            <editIcon class="icon-custom">
                                            </editIcon>
                                        </v-btn>
                                        <v-btn icon @click="openSupplierDialog(item.supplier_id)" title="Ver proveedor" v-if="can('consultar proveedor por material')">
                                            <supplierIcon class="icon-custom">
                                            </supplierIcon>
                                        </v-btn>
                                        <v-btn icon @click="deleteMaterial(item.id)" title="Borrar material" v-if="can('borrar material')">
                                            <deleteIcon class="icon-custom">
                                            </deleteIcon>
                                        </v-btn>    
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:body.prepend>
                                <tr v-if="temporalMaterial.addTemporalMaterial == true">
                                    <td>
                                        <v-combobox
                                            v-model="temporalMaterial.code"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="code_material"
                                            item-text="code"
                                            :return-object="false"
                                            :search-input.sync="searchCodeMaterial"
                                            :error-messages="errors.code"
                                            :rules="codeRules"
                                            ref="comboCreateCode"
                                            placeholder="A10022"
                                        >
                                        </v-combobox>
                                    </td>
                                    <td>
                                        <v-combobox
                                            v-model="temporalMaterial.concept"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="concept_material"
                                            item-text="concept"
                                            :return-object="false"
                                            :rules="inputRules"
                                            :search-input.sync="searchConceptMaterial"
                                            :error-messages="errors.concept"
                                            ref="comboCreateConcept"
                                        >
                                        </v-combobox>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                            v-model="temporalMaterial.supplier_id"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="suppliers"
                                            item-text="code"
                                            item-value="id"
                                            :rules="inputRules"
                                            :search-input.sync="searchSupplier"
                                        >
                                        <template v-slot:no-data>
                                            No hay proveedores.
                                        </template>
                                        </v-autocomplete>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                            v-model="temporalMaterial.type_material_id"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="types_materials"
                                            item-text="type"
                                            item-value="id"
                                            :rules="inputRules"
                                            :search-input.sync="searchTypeMaterial"
                                        >
                                        <template v-slot:no-data>
                                            No hay tipos de materiales.
                                        </template>
                                        </v-autocomplete>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                            v-model="temporalMaterial.unit_id"
                                            outlined
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                            :items="units"
                                            item-text="description"
                                            item-value="id"
                                            :rules="inputRules"
                                            :search-input.sync="searchUnits"
                                        >
                                        <template v-slot:no-data>
                                            No hay unidades.
                                        </template>
                                        </v-autocomplete>
                                    </td>
                                    <td v-if="enable_dollars == false">
                                        <v-text-field 
                                            v-model="temporalMaterial.unit_price_pesos" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            :rules="rulesPrice"
                                            type="number"
                                            class="data-table"
                                            @input="disableDollars"
                                        ></v-text-field>
                                    </td>
                                    <td v-if="enable_dollars == true">
                                        {{ temporalMaterial.unit_price_pesos = convertDollarsToPesos(temporalMaterial.unit_price_dollars) }}
                                    </td>
                                    <td v-if="enable_pesos == false">
                                        <v-text-field 
                                            v-model="temporalMaterial.unit_price_dollars" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            :rules="rulesPrice"
                                            type="number"
                                            class="data-table"
                                            @input="disablePesos"
                                        ></v-text-field>
                                    </td>
                                    <td v-if="enable_pesos == true">
                                        {{ temporalMaterial.unit_price_dollars = 0 }}
                                    </td>
                                    <td v-if="enable_pesos == false">
                                        {{ getParity() }}
                                    </td>
                                    <td v-else>
                                        {{ temporalMaterial.parity = 0 }}
                                    </td>
                                    <td>
                                        <v-btn icon @click="createMaterial(temporalMaterial)" :loading="btn_confirm" title="Crear material">
                                            <v-icon dark color="green" fab class="font-size-3k">
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelTemporalMaterial()" :loading="btn_confirm" title="Cancelar creación de material">
                                            <v-icon dark color="red" fab class="font-size-3k">
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:footer>
                                <v-row class="card-padding">
                                    <v-col cols="12" lg="3">
                                        <span class="text-body me-3 text-sm d-flex">Mostrando del {{ materials.from }} de {{ materials.total }} registros</span>
                                    </v-col>
                                    <v-col cols="12" lg="9" class="ml-auto d-flex justify-end">
                                        <v-pagination
                                        prev-icon="fa fa-angle-left"
                                        next-icon="fa fa-angle-right"
                                        class="pagination"
                                        color="blue"
                                        v-model="materials.current_page"
                                        :length="materials.last_page"
                                        circle
                                        @input="getMaterials"
                                        :total-visible="9"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:no-data>
                                No hay materiales registrados.
                            </template>
                        </v-data-table>
                    </v-form>
                </v-col>
            </v-row>    
        </v-card>
        <DialogFilterComponent
          :show.sync="showFilterDialog"
          @updateSearch="getMaterialsFilter"
          :clear_filter.sync="clear_filter"
        ></DialogFilterComponent>
        <DialogSupplierMaterialComponent
          :show.sync="showSupplierDialog"
          :supplier_id.sync="supplier_id"
        ></DialogSupplierMaterialComponent>
    </v-container>
</template>
<script>
import DialogFilterComponent from './DialogFiltersMaterialComponent.vue';
import DialogSupplierMaterialComponent from './DialogSupplierMaterialComponent.vue';
import debounce from "lodash.debounce";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default{
    data(){
        return {
            search: "",
            headers: [
                { text: 'Clave', value: 'code',sortable: false },
                { text: 'Concepto', value: 'concept',sortable: false, width: '500px' },
                { text: 'Proveedor', value: 'suppliers.name',sortable: false },
                { text: 'Tipo', value: 'types_materials.type',sortable: false },
                { text: 'Unidad', value: 'units.name',sortable: false },
                { text: 'PU pesos', value: 'prices.unit_price_pesos',sortable: false },
                { text: 'PU Dólares', value: 'prices.unit_price_dollars',sortable: false },
                { text: 'Paridad', value: 'prices.parity',sortable: false },
                { text: 'Acciones', value: '', sortable: false},
            ],
            itemsPerPage: 10,
            materials: [],
            material: {},
            addTemporalMaterial: false,
            editMaterial: false,
            editMaterialId: null,
            inputRules: [
                value => !!value || 'Este campo es obligatorio.'
            ],
            codeRules: [
                v => !!v || 'Este campo es obligatorio.',
                v => ( v && v.length <= 6 ) || 'El campo no puede exceder más de 6 caracteres.'
            ],
            rulesPrice: [
                v => !!v || 'Este campo es obligatorio.',
                v => ( v && v.length <= 10 ) || 'El campo no puede exceder más de 10 numeros.'
            ],
            temporalMaterial: {},
            suppliers: [],
            types_materials: [],
            units: [],
            current_exchange: {},
            searchSupplier: "",
            searchTypeMaterial: "",
            searchUnits: "",
            searchConceptMaterial: "",
            searchCodeMaterial: "",
            concept_material: [],
            code_material: [],
            conceptExist: false,
            errors: {},
            sortBy: null,
            order: "Descendente",
            showFilterDialog: false,
            filterSupplier: null,
            filterUnit: null,
            filterTypeMaterial: null,
            editUnitPesos: 0,
            showSupplierDialog: false,
            supplier_id: null,
            clear_filter: false,
            btn_confirm: false,
            enable_dollars: false,
            enable_pesos: false,
            enable_pesos_edit: false,
            enable_dollars_edit: false,
            currencyMask
        }
    },
    components: {
        DialogFilterComponent,
        DialogSupplierMaterialComponent,
    },
    methods: {
        getMaterials(page = 1){
            this.clear_filter = false;
            this.$http.get("/materiales", {
                params: {
                    page: page,
                    query: this.search,
                    perPage: this.itemsPerPage,
                    sortBy: this.sortBy,
                    order: this.order,
                    filterSupplier: this.filterSupplier,
                    filterUnit: this.filterUnit,
                    filterTypeMaterial: this.filterTypeMaterial
                }
            })
            .then(response => {
                this.materials = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        createTemporalMaterial(){
            this.addTemporalMaterial = true;
            var material;
            if(this.materials.data.length != 0){
                material = {
                    code: "",
                    concept: "",
                    supplier_id: null,
                    type_material_id: null,
                    unit_id: null,
                    unit_price_pesos: null,
                    unit_price_dollars: null,
                    parity: null,
                    exchange_id: this.current_exchange.id,
                    addTemporalMaterial: this.addTemporalMaterial
                }
            }else{
                material = {
                    code: "",
                    concept: "",
                    supplier_id: null,
                    type_material_id: null,
                    unit_id: null,
                    unit_price_pesos: null,
                    unit_price_dollars: null,
                    parity: null,
                    exchange_id: this.current_exchange.id,
                    addTemporalMaterial: this.addTemporalMaterial
                }
            }
            this.temporalMaterial = material;
        },
        cancelTemporalMaterial(){
            this.temporalMaterial = {};
            this.addTemporalMaterial = false;
            this.enable_dollars = false;
            this.enable_pesos = false;
        },
        cancelEditMaterial(){
            this.editMaterial = false;
            this.materials.data.find((element) => element.id == this.editMaterialId).editMaterial = this.editMaterial;
            this.editMaterial = null;
            this.getMaterials(this.materials.current_page);
            this.enable_dollars_edit = false;
            this.enable_pesos_edit = false;
        },
        createMaterial(material){
            this.btn_confirm = true;
            this.$refs["comboCreateCode"].blur();
            this.$refs["comboCreateConcept"].blur();
            this.$nextTick(() => {
                this.material = material;
                
                if(this.$refs.formMaterial.validate() == true){
                    this.$http.post("/materiales/create", this.material)
                    .then(response => {
                        if(response.status == 200){
                            this.$swal.fire("", response.data.message, "success");
                            this.addTemporalMaterial = false;
                            this.temporalMaterial = {};
                            this.getMaterials();
                            this.material = {};
                            this.errors = {};
                            this.enable_dollars = false;
                            this.enable_pesos = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.errors = error.response.data.errors;
                    })
                    .finally(() => {
                        this.btn_confirm = false;                   
                    });
                }else{
                    this.btn_confirm = false;
                }
            });
        },
        editTemporalMaterial(material){
            this.editMaterial = true;
            this.editMaterialId = material.id;
            this.materials.data.find((element) => element.id == material.id).editMaterial = this.editMaterial;
        },
        updateMaterial(material){
            this.btn_confirm = true;
            this.$refs["comboEditCode"].blur();
            this.$refs["comboEditConcept"].blur();
            this.$nextTick(() => {
                var updateMaterial = {
                    id: material.id, 
                    code: material.code,
                    concept: material.concept,
                    supplier_id: material.supplier_id,
                    type_material_id: material.type_material_id,
                    unit_id: material.unit_id,
                    price_id: material.price_id,
                    unit_price_pesos: material.prices.unit_price_pesos,
                    unit_price_dollars: material.prices.unit_price_dollars,
                    parity: this.enable_pesos_edit == false ? this.getParity() : material.prices.parity,
                }
                this.$http.patch(`materiales/update/${material.id}`,updateMaterial)
                .then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.editMaterial = false;
                        this.getMaterials();
                        this.errors = {};
                        this.enable_pesos_edit = false;
                        this.enable_pesos_edit = false;
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors
                })
                .finally(() => {
                    this.btn_confirm = false;                   
                });
            });
            
        },
        deleteMaterial(id){
            this.$swal({
                title: "Confirmación",
                text: " ¿Estas seguro de borrar este registro?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                }).then((result) => {
                if (result.value) {
                    this.$http.delete(`/materiales/destroy/${id}`)
                    .then(response => {
                        this.$swal.fire("", response.data.message, "success");
                        this.getMaterials();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                } 
            });
        },
        getSuppliers(){
            this.$http.get("/all_name_proveedores", {
                params: {
                    query: this.searchSupplier,
                }
            })
            .then(response => {
                this.suppliers = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        getTypesMaterials(){
            this.$http.get("/all_name_tipo_materiales",  {
                params: {
                    query: this.searchTypeMaterial,
                }
            })
            .then(response => {
                this.types_materials = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        getUnits(){
            this.$http.get("/all_name_unidades",  {
                params: {
                    query: this.searchUnits,
                }
            })
            .then(response => {
                this.units = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        getCurrentExchange(){
            this.$http.get("/getCurrentExchange")
            .then(response => {
                this.current_exchange = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        getPriceDolars(price_pesos){
            if(this.current_exchange == {}){
                return 'No hay tipo de cambio asignado'
            }else{
                var price_dollars = price_pesos / this.current_exchange.dollar;

                this.temporalMaterial.unit_price_dollars = price_dollars.toFixed(2);
                this.editUnitPesos = price_dollars.toFixed(2);

                return price_dollars.toFixed(2);
            }
        },
        getParity(){
            var parity = this.current_exchange.dollar;

            this.temporalMaterial.parity = parity;

            if(parity == null){
                return "No hay tipo de cambio asignado"
            }else{
                return parity;
            }
        },
        getConceptMaterial(){
            this.$http.get("/get_concept_materiales",  {
                params: {
                    query: this.searchConceptMaterial,
                }
            })
            .then(response => {
                this.concept_material = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        getCodeMaterial(){
            this.$http.get("/get_code_materiales",  {
                params: {
                    query: this.searchCodeMaterial,
                }
            })
            .then(response => {
                this.code_material = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        openFilterDialog(){
            this.showFilterDialog = true;
        },
        openSupplierDialog(supplier_id){
            this.showSupplierDialog = true;
            this.supplier_id = supplier_id;
        },
        getMaterialsFilter(datos){
            this.order = datos.orderBy;
            this.sortBy = datos.orderType;
            this.filterSupplier = datos.supplier;
            this.filterTypeMaterial = datos.type_material;
            this.getMaterials();
        },
        clearFilter(){
            this.clear_filter = true;
        },
        disableDollars() {
            if (this.temporalMaterial.unit_price_pesos.length == 1) {
                this.temporalMaterial.unit_price_dollars = 0;
                this.enable_pesos = true;
            }else if (this.temporalMaterial.unit_price_pesos.length == 0) {
                this.temporalMaterial.unit_price_dollars = null;
                this.enable_pesos = false;
            }
        },
        disablePesos(){
            if (this.temporalMaterial.unit_price_dollars.length == 1) {
                this.temporalMaterial.unit_price_pesos = 0;
                this.enable_dollars = true;
            }else if (this.temporalMaterial.unit_price_dollars.length == 0) {
                this.temporalMaterial.unit_price_pesos = null;
                this.enable_dollars = false;
            }
        },
        disableDollarsEdit(material) {
            var pesos = this.materials.data.find((element) => element.id == material.id).prices.unit_price_pesos;
            if(pesos == ""){
                this.enable_pesos_edit = false;
            }else if(pesos.length >= 1){
                this.enable_pesos_edit = true;
            }
        },
        disablePesosEdit(material){
            var dollars = this.materials.data.find((element) => element.id == material.id).prices.unit_price_dollars;
            if(dollars == ""){
                this.enable_dollars_edit = false;
            }else if(dollars.length >= 1){
                this.enable_dollars_edit = true;
            }
        },
        convertDollarsToPesos(dollars){
            var pesos = (dollars * this.current_exchange.dollar);
            return pesos.toFixed(2);
        },
        downloadExport(){
            this.$http.get('/exportar_materiales', { responseType: 'blob' })
            .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'materiales.xlsx'); 
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            })
            .catch(error => {
            console.error('Error al descargar el archivo Excel:', error);
            });
        }
    },
    created(){
        this.getMaterials();
        this.debounceSearchMaterials = debounce(() => {
            this.getMaterials();
        }, 200);
        this.debounceSearchConceptMaterials = debounce(() => {
            this.getConceptMaterial();
        }, 200);
        this.debounceSearchSuppliers = debounce(() => {
            this.getSuppliers();
        }, 200);
        this.debounceSearchTypesMaterials = debounce(() => {
            this.getTypesMaterials();
        }, 200);
        this.debounceSearchUnits = debounce(() => {
            this.getUnits();
        }, 200);
        this.debounceSearchCodeMaterials = debounce(() => {
            this.getCodeMaterial();
        }, 200);
        this.getCurrentExchange();
    },
    watch: {
        search () {
            this.debounceSearchMaterials();
        },
        searchSupplier () {
            this.debounceSearchSuppliers();
        },
        searchTypeMaterial () {
            this.debounceSearchTypesMaterials();
        },
        searchUnits () {
            this.debounceSearchUnits();
        },
        searchConceptMaterial () {
            this.debounceSearchConceptMaterials();
        },
        searchCodeMaterial () {
            this.debounceSearchCodeMaterials();
        }
    }
}
</script>
<style scoped>
.v-data-table >>> .v-data-table-header {
    background-color: #f3f4f9 !important;
    color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
    color: black !important;
}
.card-margin-material{
    margin-top: 3vh !important;
}
.data-table{
    padding-top: 12px !important;
    margin-top: 10px !important;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
.v-data-table.row-height td{
  height: 18px !important;
}
</style>
