<template>
    <div>
        <v-col cols="12" class="margin-navbar-top">
            <v-card>
                <v-row>
                    <v-col cols="12">
                        <v-form ref="formExchangeRate">
                            <v-data-table
                                :headers="headers"
                                item-key="id"
                                :items="exchangeRates.data"
                                class="elevation-1 row-height"
                                :hide-default-footer="true"
                            >
                                <template v-slot:top>
                                    <v-row>
                                        <v-col cols="12" lg="12" sm="12">
                                            <div class="card-header-padding">
                                                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                                Tipos de cambio
                                                </h5>
                                                <p class="text-sm text-body font-weight-light mb-0">
                                                Registro detallados del tipo de cambio
                                                </p>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>  
                                        <v-col cols="12" lg="12" class="btn-padding d-flex justify-end btn-margin-type-exchange">
                                            <!-- <v-btn v-if="!addTemporalExchangeRate && !editExchangeRate && can('exportar tipos de cambio')" outlined color="green" class="mr-4">
                                                Exportar
                                            </v-btn> -->
                                            <v-btn v-if="!addTemporalExchangeRate && !editExchangeRate && can('crear tipos de cambio')" outlined color="blue" @click="createTemporalExchangeRate()">
                                                Actualizar tipo de cambio
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:item="{item}">
                                    <tr v-if="item.editExchangeRate == true">
                                        <td>
                                            {{ formatDate(item.created_at) }}
                                        </td>
                                        <td>
                                            <v-text-field 
                                                v-model="item.dollar" 
                                                type="number"
                                                outlined 
                                                background-color="#f3f4f9"
                                                :rules="inputRules"
                                                input-alternative input-focused-alternative input-icon
                                                class="data-table"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-btn icon @click="updateExchangeRate(item)" :loading="btn_confirm" title="Guardar cambios">
                                                <v-icon dark color="green" fab class="font-size-3k">
                                                    fas fa-check
                                                </v-icon>
                                            </v-btn>
                                            <v-btn icon @click="cancelEditExchangeRate()" :loading="btn_confirm" title="Cancelar cambios">
                                                <v-icon dark color="red" fab class="font-size-3k">
                                                    fas fa-times
                                                </v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td>
                                            {{ formatDate(item.created_at) }}
                                        </td>
                                        <td>
                                            {{ item.dollar | VMask(currencyMask) }}
                                        </td>
                                        <td>
                                            <v-btn icon @click="editTemporalExchangeRate(item)" title="Editar" v-if="can('editar tipos de cambio')">
                                                <editIcon class="icon-custom">
                                                </editIcon>
                                            </v-btn>
                                            <v-btn icon @click="deleteExchangeRate(item.id)" title="Borrar" v-if="can('borrar tipos de cambio')">
                                                <deleteIcon class="icon-custom">
                                                </deleteIcon>
                                            </v-btn>    
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:body.prepend>
                                    <tr v-if="temporalExchangeRate.addTemporalExchangeRate == true">
                                        <td>
                                            {{ getDateNow() }}
                                        </td>
                                        <td>
                                            <v-text-field 
                                                v-model="temporalExchangeRate.dollar" 
                                                type="number"
                                                outlined 
                                                background-color="#f3f4f9" 
                                                input-alternative input-focused-alternative input-icon
                                                :rules="inputRules"
                                                class="data-table"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-btn icon @click="createExchangeRate(temporalExchangeRate)" :loading="btn_confirm" title="Actualizar tipo de cambio">
                                                <v-icon dark color="green" fab class="font-size-3k">
                                                    fas fa-check
                                                </v-icon>
                                            </v-btn>
                                            <v-btn icon @click="cancelTemporalExchangeRate()" :loading="btn_confirm" title="Cancelar tipo de cambio">
                                                <v-icon dark color="red" fab class="font-size-3k">
                                                    fas fa-times
                                                </v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:footer>
                                    <v-row class="card-padding">
                                        <v-col cols="12" lg="3" sm="12">
                                            <span class="text-body me-3 text-sm d-flex">Mostrando del {{ exchangeRates.from }} de {{ exchangeRates.total }} registros</span>
                                        </v-col>
                                        <v-col cols="12" lg="9" class="ml-auto d-flex justify-end">
                                            <v-pagination
                                            prev-icon="fa fa-angle-left"
                                            next-icon="fa fa-angle-right"
                                            class="pagination"
                                            color="blue"
                                            v-model="exchangeRates.current_page"
                                            :length="exchangeRates.last_page"
                                            circle
                                            @input="getExchangeRates"
                                            :total-visible="9"
                                            ></v-pagination>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:no-data>
                                    No hay tipos de cambios registrados.
                                </template>
                            </v-data-table>
                        </v-form>
                    </v-col>
                </v-row>    
            </v-card>
        </v-col>
    </div>
</template>
<script>
import debounce from "lodash.debounce";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default{
    data(){
        return {
            headers: [
                { text: 'Fecha', value: '' },
                { text: 'Valor', value: '' },
                { text: 'Acciones', value: '' },
            ],
            itemsPerPage: 10,
            exchangeRates: [],
            exchangeRate: {},
            addTemporalExchangeRate: false,
            editExchangeRate: false,
            editExchangeRateId: null,
            inputRules: [
                value => !!value || 'Este campo es obligatorio.'
            ],
            temporalExchangeRate: {},
            btn_confirm: false,
            order: "desc",
            currencyMask
        }
    },
    methods: {
        formatDate(dateItem) {
            const date = new Date(dateItem);

            const month = (date.getMonth() + 1).toString().padStart(2, '0');

            const day = date.getDate();

            const year = date.getFullYear();
            
            return `${day}/${month}/${year}`;
           
        },
        getDateNow(){
            const date = new Date();

            const month = (date.getMonth() + 1).toString().padStart(2, '0');

            const day = date.getDate();

            const year = date.getFullYear();
            // return date;
            return `${day}/${month}/${year}`;
        },
        getExchangeRates(page = 1){
            this.$http.get("/exchange", {
                params: {
                    page: page,
                    perPage: this.itemsPerPage,
                    order: this.order
                }
            })
            .then(response => {
                this.exchangeRates = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        createTemporalExchangeRate(){
            this.addTemporalExchangeRate = true;
            var exchangeRate;
            if(this.exchangeRates.data.length != 0){
                const dataExchangeRate = this.exchangeRates.data[this.exchangeRates.data.length-1];
                const id = dataExchangeRate.id + 1;
                exchangeRate = {
                    id: id, 
                    dollar: "",
                    addTemporalExchangeRate: this.addTemporalExchangeRate
                }
            }else{
                exchangeRate = {
                    id: "1", 
                    dollar: "",
                    addTemporalExchangeRate: this.addTemporalExchangeRate
                }
            }
            this.temporalExchangeRate = exchangeRate;
        },
        cancelTemporalExchangeRate(){
            this.temporalExchangeRate = {};
            this.addTemporalExchangeRate = false;
        },
        cancelEditExchangeRate(){
            this.editExchangeRate = false;
            this.exchangeRates.data.find((element) => element.id == this.editExchangeRateId).editExchangeRate = this.editExchangeRate;
            this.editExchangeRate = null;
            this.getExchangeRates(this.exchangeRates.current_page);
        },
        createExchangeRate(exchangeRate){
            this.btn_confirm = true;
            this.exchangeRate = exchangeRate;
            
            if(this.$refs.formExchangeRate.validate() == true){
                this.$http.post("exchange/create", this.exchangeRate)
                .then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.addTemporalExchangeRate = false;
                        this.temporalExchangeRate = {};
                        this.getExchangeRates();
                        this.$emit('update');
                    }
                })
                .catch((error) => {
                    console.error(error);
                }).finally(() => {
                    this.btn_confirm = false;                   
                });
            }else{
                this.btn_confirm = false;
            }
        },
        editTemporalExchangeRate(exchangeRate){
            this.editExchangeRate = true;
            this.editExchangeRateId = exchangeRate.id;
            this.exchangeRates.data.find((element) => element.id == exchangeRate.id).editExchangeRate = this.editExchangeRate;
        },
        updateExchangeRate(exchangeRate){
            this.btn_confirm = true;
            var updateExchangeRate = {
                id: exchangeRate.id,
                dollar: exchangeRate.dollar,
            }
            this.$http.patch(`exchange/update/${exchangeRate.id}`,updateExchangeRate)
            .then(response => {
                if(response.status == 200){
                    this.$swal.fire("", response.data.message, "success");
                    this.editExchangeRate = false;
                    this.getExchangeRates();
                    this.$emit('update');
                }
            })
            .catch((error) => {
                console.error(error);
            }).finally(() => {
                this.btn_confirm = false;                   
            });
        },
        deleteExchangeRate(id){
            this.$swal({
                title: "Confirmación",
                text: " ¿Estas seguro de borrar este registro?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                }).then((result) => {
                if (result.value) {
                    this.$http.delete(`exchange/destroy/${id}`)
                    .then(response => {
                        this.$swal.fire("", response.data.message, "success");
                        this.getExchangeRates();
                        this.$emit('update');
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                } 
            });
        },
    },
    mounted(){
        this.getExchangeRates();
        this.debounceSearchExchangeRates = debounce(() => {
            this.getExchangeRates();
        }, 200);
    },
    watch: {
        search () {
            this.debounceSearchExchangeRates();
        },
        temporalExchangeRate () {
            this.temporalExchangeRate;
        },
    }
}
</script>
<style scoped>
.v-data-table >>> .v-data-table-header {
    background-color: #f3f4f9 !important;
    color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
    color: black !important;
}
.card-margin-type-material{
    margin-top: 20vh !important;
}
.data-table{
    padding-top: 12px !important;
    margin-top: 10px !important;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
.v-data-table.row-height td{
  height: 18px !important;
}
</style>
