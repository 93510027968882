<template>
    <div>
      <v-container fluid>
          <v-row>
              <v-col cols="12">
                <general-factors-information @getFactor="getFactor" :type="'ListaPrPlas'" ref="generalInformation" />
              </v-col>
              <v-col cols="12">
                  <plants-table-component :factor="factor" @update="updateFactor" />
              </v-col>
          </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  import PlantsTableComponent from "@/components/App/Plants/PlantsTableComponent"
  import GeneralFactorsInformation from "@/components/App/Factors/GeneralFactorsInformation.vue";
  export default {
      name: "plant-layout",
      components: {
        PlantsTableComponent,
        GeneralFactorsInformation
      },
      data() {
        return {
          factor: {}
        }
      },
      methods: {
        async getFactor(factor) {
          this.factor = await factor
        },
        updateFactor() {
          this.$refs.generalInformation.getFactor();
        },
      }
  }
  </script>
  
  <style>
  
  </style>