<template>
    <div>
        <v-btn outlined color="blue" title="Agregar Material" @click.stop="dialog = true">
            Agregar material
        </v-btn>

        <v-dialog
        persistent
        v-model="dialog"
        max-width="800"
        >
        <v-card>
            <v-card-title class="text-h5">
                Materiales
            </v-card-title>
            <v-card-subtitle>
                Selecciona los materiales y la cantidad
            </v-card-subtitle>
            <v-card-text>
                <v-toolbar
                    flat
                >
                    <v-autocomplete
                        v-model="materialSelected.item"
                        :loading="loading"
                        :items="materials.data"
                        :search-input.sync="searchMaterial"
                        :filter="customFilter"
                        color="blue"
                        class="mx-4"
                        flat
                        hide-no-data
                        hide-details
                        label="Buscar por clave, concepto o proveedor"
                        solo
                        background-color="#f3f4f9"
                    >
                    <template v-slot:selection="data">
                        Código: {{  data.item.code }} Concepto: {{ data.item.concept }}
                    </template>
                    <template v-slot:item="data">
                        <template>
                            <v-list-item-content>
                                <v-list-item-title v-html="'Código: '+data.item.code"></v-list-item-title>
                                <v-list-item-subtitle v-html="'Concepto: '+data.item.concept"></v-list-item-subtitle>
                                <v-list-item-subtitle v-html="'Proveedor: '+data.item.suppliers.code"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </template>
                    </v-autocomplete>
                    <v-text-field
                        flat
                        type="number"
                        color="blue"
                        class="mx-4 pt-7"
                        background-color="#f3f4f9"
                        solo
                        v-model="materialSelected.quantity"
                        label="Cantidad de materiales"
                        min="0"
                    ></v-text-field>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="blue" @click="addMaterialToSelectedMaterials(materialSelected)" icon :disabled="materialSelected.item == null || materialSelected.quantity == null || materialSelected.quantity <= 0">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Agregar material</span>
                    </v-tooltip>
                </v-toolbar>
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Material
                        </th>
                        <th class="text-left">
                            Cantidad
                        </th>
                        <th class="text-right">
                            Acciones
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="item in materialsSelected"
                        :key="item.id"
                        >
                        <td> Código: {{  item.item.code }}, Concepto: {{ item.item.concept }}, Proveedor: {{ item.item.suppliers.code }}</td>
                        <td>{{ item.quantity }}</td>
                        <td class="text-right">
                            <!-- <v-btn @click="editItem(item)" color="orange" icon>
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn> -->
                            <v-btn color="red" icon @click="remove(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            
                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="red"
                text
                @click="closeDialog()"
            >
                Cancelar
            </v-btn>

            <v-btn
                :disabled="materialsSelected.length == 0"
                color="green"
                outlined
                
                @click="saveMaterialsIds()"
            >
                Agregar
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

    </div>
</template>

<script>
import debounce from "lodash.debounce";
  export default {
    data () {
      return {
        materialsSelected: [],
        materialSelected: {
            item: null,
            quantity: null,
            sum: null
        },
        dialog: false,
        searchMaterial: "",
        materials: [],
        materialsRelationated: {}
      }
    },
    methods: {
        closeDialog() {
            this.materialSelected = {};
            this.materialsSelected =[]
            this.dialog = false
        },
        // editItem(item) {
        //     const index = this.materialsSelected.indexOf(item)
        //     if (index >= 0) this.materialsSelected[index].isEditingQuantity = true
        // },
        addMaterialToSelectedMaterials(item) {
            let materialObject = {
                item: item.item,
                quantity: item.quantity,
                sum: parseFloat(item.quantity) * parseFloat(item.item.prices.unit_price_pesos)
            }
            const exists = this.materialsSelected.some((material) => JSON.stringify(material.item.id) == JSON.stringify(item.item.id));
            if(!exists) {
                if (this.materialsRelationated.materials != null) {
                    
                    const existsInMaterialsRelationated = JSON.parse(this.materialsRelationated.materials).some((material) => JSON.stringify(material.item.id) == JSON.stringify(item.item.id));
                    if (!existsInMaterialsRelationated) {
                        this.materialsSelected.push(materialObject)
                        this.materialSelected = {
                            item: null,
                            quantity: null,
                            sum: null
                        }
                    }
                    else {
                        this.$swal.fire("", "Material repetido, el material ya se encuentra relacionado al equipo", "warning");
                    }
                }
                else {
                    this.materialsSelected.push(materialObject)
                    this.materialSelected = {
                        item: null,
                        quantity: null
                    };
                }
            }
            else {
                this.$swal.fire("", "Material repetido, no se puede agregar dos veces un material", "warning");
            }
        },
        // handleMaterialsChange() {
        //     if (this.materialsRelationated.materials != null) {
        //         this.materialsSelected.forEach((material) => {
        //             console.log(material);
        //             // this.materialsRelationated.materials.push(material);
        //         //     const exists = this.materialsRelationated.materials.some((material) => material.item.id === selectedItem.item.id);
        //         //     if (!exists) {
        //         //         this.materialsRelationated.materials.push(selectedItem);
        //         //     }
        //         })
        //     } else {
        //         this.materialsRelationated.materials = this.materialsSelected;
        //     }
        // },

        remove (item) {
            const index = this.materialsSelected.indexOf(item)
            if (index >= 0) this.materialsSelected.splice(index, 1)
        },
        saveMaterialsIds() {
            // this.handleMaterialsChange(this.materialsSelected);
            this.$http.patch(`/addMaterialsToEquipment/${this.materialsRelationated.id}`, {
                materials: this.materialsSelected,
                equipmentId: this.$route.params.equipment_id,
            })
            .then(response => {
                if(response.status == 200){
                    this.$swal.fire("", response.data.message, "success");
                    this.getMaterials();
                    this.getRelationToMaterials();
                    this.materialSelected = {};
                    this.materialsSelected =[]
                    this.$emit('update');
                }
            })
            .catch((error) => {
                this.$swal.fire("", error.response.data.errors, "error");
            })
            .finally(() => {
                this.dialog = false
            });
        },
        // getItemText(item) {
        // if (item.concept.toLowerCase().includes(this.searchMaterial.toLowerCase())) {
        //     return item.concept;
        // } else if (item.code.toLowerCase().includes(this.searchMaterial.toLowerCase())) {
        //     return item.code;
        // }
        // return item.concept;
        // },
        customFilter(item, queryText, itemText) {
            const includesConcept = item.concept.toLowerCase().includes(queryText.toLowerCase());
            const includesCode= item.code.toLowerCase().includes(queryText.toLowerCase());
            
            return includesConcept || includesCode ;
        },
        getRelationToMaterials() {
            let equipmentId = this.$route.params.equipment_id;
            this.$http.get(`/getRelationByEquipment/${equipmentId}`, {
            })
            .then(response => {
                this.materialsRelationated = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        getMaterials(){
            this.$http.get("/materiales", {
                params: {
                    query: this.searchMaterial,
                }
            })
            .then(response => {
                this.materials = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
    },
    mounted() {
        this.debounceSearchMaterials = debounce(() => {
            this.getMaterials();
        }, 500);
        this.getRelationToMaterials()
    },
    watch: {
        searchMaterial () {
            this.debounceSearchMaterials();
        },
        // materialsSelected () {
        //     // console.log("estoy siendo modificado");
        //     this.handleMaterialsChange()
        // }
    }
  }
</script>