<template>
  <v-app>
    <v-container v-if="loading">
      <v-row>
        <v-col
          cols="12"
          md="5"
        >
          <v-skeleton-loader
            :loading="loading"
            type="card-avatar, article, actions"
          ></v-skeleton-loader>

        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader
          :loading="loading"
            type="article, actions"
          ></v-skeleton-loader>

          <v-skeleton-loader
          :loading="loading"
            type="table-heading, list-item-two-line, image, table-tfoot"
          ></v-skeleton-loader>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-skeleton-loader
          :loading="loading"
            type="list-item-avatar, list-item-three-line, card-heading, image, actions"
          ></v-skeleton-loader>

          <v-skeleton-loader
          :loading="loading"
            type="list-item-avatar-three-line, image, article"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <router-view v-if="!loading"></router-view>
  </v-app>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    async checkIfUserIsAuth() {
      try {
        await this.$http.get('/user');
        if (this.$route.path === "/" || this.$route.path === "/authentication/sign-in") {
          window.location.href = '/dashboard';
        }
      } catch (error) {
        if (this.$route.path !== "/authentication") {
          this.$router.push("/authentication");
        }
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.$csrf.getCookie();
    this.checkIfUserIsAuth();
  }
}
</script>
