<template>
    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.53674 4.32931L3.55046 20.3936C3.59861 21.3218 4.28034 22 5.17241 22H14.4986C15.3943 22 16.0633 21.3218 16.1206 20.3936L17.1343 4.32931" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 4.32931H18.6707H1Z" fill="black"/>
        <path d="M1 4.32931H18.6707" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M6.53455 4.21286V2.20483C6.53407 2.04648 6.56576 1.8896 6.6278 1.74321C6.68984 1.59683 6.781 1.46383 6.89603 1.35185C7.01107 1.23988 7.14771 1.15116 7.2981 1.09077C7.4485 1.03039 7.60967 0.999541 7.77235 1.00001H11.8984C12.0611 0.999541 12.2222 1.03039 12.3726 1.09077C12.523 1.15116 12.6597 1.23988 12.7747 1.35185C12.8897 1.46383 12.9809 1.59683 13.0429 1.74321C13.105 1.8896 13.1367 2.04648 13.1362 2.20483V4.21286M9.83536 7.42572V18.6707M6.12195 7.42572L6.53455 18.6707M13.5488 7.42572L13.1362 18.6707" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {
    name: "Delete"
}
</script>