<template>
    <v-row justify="center">
        <v-dialog
            v-model="localShow"
            scrollable
            max-width="700px"
        >
            <v-form 
                ref="formSupplier"
                @submit.prevent="storeInformation()"
            >
            <v-card>
                <v-toolbar color="#f3f4f9" dark>
                    <v-toolbar-title class="title-color">Agregar información adicional</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn class="close-window-button" icon dark @click="localShow = false" color="black" rel="tooltip" title="Cerrar">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="margin-body-card">
                    <v-row>
                        <v-col cols="12" v-if="supplier.contact_information">
                            <v-textarea
                                outlined
                                input-alternative input-focused-alternative input-icon
                                label="Observaciones"
                                v-model="supplier.contact_information.observations"
                            >
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" class="card-header-padding d-flex justify-end">
                            <v-btn outlined color="blue" @click="addPhone()">Agregar teléfono</v-btn>
                        </v-col>
                        <v-col cols="12" v-if="supplier.contact_information">
                            <v-simple-table
                            dense
                            >
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Teléfonos adicionales
                                    </th>
                                    <th class="text-right">
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="phone,index in supplierPhones" :key="index"
                                >
                                    <td>
                                        <v-row>
                                            <v-col cols="3" class="d-flex align-center mr-3">  
                                                <vue-country-code 
                                                    v-model="phone.prefix"
                                                    :disabledFetchingCountry="true"
                                                    :enabledCountryCode="false"
                                                    :preferredCountries="preferredCountries"
                                                    @onSelect="selectCountryCode"
                                                    class="prefix-code"
                                                    defaultCountry="MX"
                                                />
                                            </v-col>
                                            <v-col cols="8" class="d-flex align-center">
                                                <v-text-field 
                                                class="mt-8"
                                                    v-model="phone.phone" 
                                                    solo
                                                    flat
                                                    v-mask="'(###) ###-####'"
                                                    background-color="#f3f4f9"
                                                    :rules="inputPhoneRules"
                                                    input-alternative input-focused-alternative input-icon
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </td>
                                    <td>
                                        <v-row>
                                            <v-col cols="12" class="card-header-padding d-flex justify-end">
                                                <v-btn icon @click="deletePhone(phone)" title="Borrar Correo">
                                                    <deleteIcon class="icon-custom">
                                                    </deleteIcon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                            <!-- <v-textarea
                                outlined
                                input-alternative input-focused-alternative input-icon
                                v-model="supplier.contact_information.phone"
                                label="Teléfonos adicionales"
                            >
                            </v-textarea> -->
                        </v-col>
                        <v-col cols="12" class="card-header-padding d-flex justify-end">
                            <v-btn outlined color="blue" @click="addEmail()">Agregar correo</v-btn>
                        </v-col>
                        <v-col cols="12" v-if="supplier.contact_information">
                            <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Correos adicionales
                                    </th>
                                    <th class="text-right">
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="email,index in supplierEmails" :key="index"
                                >
                                    <td>
                                        <v-text-field 
                                        class="mt-8"
                                            v-model="email.email" 
                                            solo
                                            flat
                                            background-color="#f3f4f9"
                                            input-alternative input-focused-alternative input-icon
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-row>
                                            <v-col cols="12" class="card-header-padding d-flex justify-end">
                                                <v-btn icon @click="deleteEmail(email)" title="Borrar Correo">
                                                    <deleteIcon class="icon-custom">
                                                    </deleteIcon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                            <!-- <v-textarea
                                outlined
                                input-alternative input-focused-alternative input-icon
                                v-model="supplier.contact_information.phone"
                                label="Teléfonos adicionales"
                            >
                            </v-textarea> -->
                        </v-col>
                    </v-row>
                    <v-row class="margin-btn">
                        <v-spacer></v-spacer>
                        <v-btn 
                            outlined color="red"    
                            @click="localShow = false"
                            class="mr-4"
                            >
                            Cancelar
                        </v-btn>
                        <v-btn 
                            outlined color="green"    
                            @click="storeInformation()"
                            >
                            Guardar
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
            </v-form>
        </v-dialog>
    </v-row>
</template>
<script>
import { json } from 'd3';
export default {
    data() {
        return{
            supplierPhones: [],
            supplierEmails: [],
            prefix: null,
            localShow: false,
            supplier: {},
            inputPhoneRules : [
                value => (value && value.length >= 14) || 'El teléfono debe tener 10 digitos.',
                value => (value && value.length <= 14) || 'El teléfono debe tener 10 digitos.',
            ],
            preferredCountries: [
                'MX',
                'US',
                'CA'
            ],
            phone_prefix_2: null,
            phone_prefix_3: null
        }
    },
    props: {
        show: Boolean,
        supplier_id: Number,
    },
    watch: {
        show: function (value) {
            this.localShow = value;
        },
        localShow: function (value) {
            this.$emit('update:show', value)
        },
        supplier_id: function () {
            this.getSupplier();
        },
    },
    methods: {
        deletePhone(phone) {
            this.$swal({
                title: "Confirmación",
                text: " ¿Estas seguro de borrar este registro?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                }).then((result) => {
                if (result.value) {
                    const index = this.supplierPhones.indexOf(phone)
                     if (index >= 0) this.supplierPhones.splice(index, 1)
                } 
            });
        },
        deleteEmail(email) {
            this.$swal({
                title: "Confirmación",
                text: " ¿Estas seguro de borrar este registro?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                }).then((result) => {
                if (result.value) {
                    const index = this.supplierEmails.indexOf(email)
                     if (index >= 0) this.supplierEmails.splice(index, 1)
                } 
            });
        },
        addEmail() {
            let email = {
                isPrincipal: false,
                email:""
            }
            this.supplierEmails.push(email)
        },
        addPhone() {
            let phone = {
                prefix: this.prefix ? this.prefix : "+52",
                isPrincipal: false,
                phone:""
            }
            this.supplierPhones.push(phone)
        },
        selectCountryCode: function ({ dialCode }) {
            this.prefix = "+"+dialCode;
        },
        getSupplier(){
            this.$http.get(`/get_suppliers_add_information/${this.supplier_id}`, )
            .then(response => {
                this.supplier = response.data;
                let phone = JSON.parse(response.data.contact_information.phone)
                let email = JSON.parse(response.data.contact_information.email)
                let phoneFiltered = phone.filter(item => item.isPrincipal == false)
                let emailFiltered = email.filter(item => item.isPrincipal == false)
                this.supplierPhones = phoneFiltered
                this.supplierEmails = emailFiltered
            })
            .catch((error) => {
                console.error(error);
            });
        },
        storeInformation(){
            if(this.$refs.formSupplier.validate() == true){
                let firstPhone = JSON.parse(this.supplier.contact_information.phone)[0];
                let firstEmail = JSON.parse(this.supplier.contact_information.email)[0];

                let phones = [firstPhone, ...this.supplierPhones]
                let emails = [firstEmail, ...this.supplierEmails]
                let contactInformation = {
                    ...this.supplier.contact_information,
                    phone: phones,
                    email: emails
                }

                
                this.$http.patch(`/proveedores/update_information/${this.supplier_id}`, contactInformation)
                .then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.localShow = false;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            }
        }
    },
}
</script>
<style>
.title-color{
    color: black;
}
.margin-body-card{
    margin-top: 3vh;
}
.margin-btn{
    margin-top: 3vh;
}
</style>