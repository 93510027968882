<template>
    <v-container fluid>
        <v-card class="card-margin-proveedores">
            <v-row>
                <v-col cols="12">
                    <v-form 
                        ref="formSupplier"
                        @submit.prevent="getSuppliers()"
                    >
                        <v-data-table
                            :headers="headers"
                            item-key="id"
                            :items="suppliers.data"
                            class="elevation-1 row-height"
                            :hide-default-footer="true"
                        >
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="card-header-padding">
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Proveedores
                                            </h5>
                                            <p class="text-sm text-body font-weight-light mb-0">
                                            Agregar a los proveedores
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="search"
                                            input-alternative input-focused-alternative input-icon
                                            class="ml-5"
                                            outlined
                                            placeholder="Buscar"
                                            background-color="#f3f4f9"
                                        >
                                            <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="3" class="d-flex justify-start" title="Filtros">
                                        <v-btn icon class="mr-4">
                                            <v-icon dark @click="openFilterDialog">
                                                fas fa-filter
                                            </v-icon>
                                        </v-btn>
                                        <v-btn outlined color="black" @click="clearFilter" title="Filtros">
                                            Limpiar filtros
                                        </v-btn>
                                    </v-col>    
                                    <v-col cols="12" lg="6" class="btn-padding d-flex justify-end">
                                        <v-btn v-if="!addTemporalSupplier && !editSupplier && can('exportar proveedores')" @click="downloadExport()" outlined color="green" class="mr-4">
                                            Exportar
                                        </v-btn>
                                        <v-btn v-if="!addTemporalSupplier && !editSupplier && can('crear proveedores')" outlined color="blue" @click="createTemporalSupplier()">
                                            Agregar Proveedor
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item="{ item}">
                                <tr v-if="item.editSupplier == true">
                                    <td>
                                        <v-text-field 
                                            v-model="item.code" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="item.name" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="item.name_manager" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="item.web_site" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-row>
                                            <v-col cols="3" class="d-flex align-center mr-3">                                              
                                                <vue-country-code 
                                                    v-model="item.contact_information.phone.prefix"
                                                    :disabledFetchingCountry="true"
                                                    :enabledCountryCode="false"
                                                    :preferredCountries="preferredCountries"
                                                    @onSelect="selectCountryCode"
                                                    class="prefix-code"
                                                />
                                            </v-col>
                                            <v-col cols="8">   
                                                <v-text-field 
                                                    v-model="item.contact_information.phone.phone" 
                                                    outlined
                                                    v-mask="'(###) ###-####'"
                                                    background-color="#f3f4f9"
                                                    :rules="inputPhoneRules"
                                                    input-alternative input-focused-alternative input-icon
                                                    class="data-table"
                                                ></v-text-field>
                                            </v-col> 
                                        </v-row>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="item.contact_information.email.email" 
                                            outlined 
                                            background-color="#f3f4f9"
                                            :rules="inputEmailRules"
                                            type="email"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-textarea 
                                            v-model="item.contact_information.address" 
                                            outlined 
                                            rows="1" 
                                            background-color="#f3f4f9"
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-textarea>
                                    </td>
                                    <td>
                                        <v-btn icon @click="updateSupplier(item)" :loading="btn_confirm" rel="tooltip" title="Guardar cambios">
                                            <v-icon dark color="green" fab class="font-size-3k">
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelEditSupplier()" :loading="btn_confirm" rel="tooltip" title="Cancelar edición">
                                            <v-icon dark color="red" fab class="font-size-3k">
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td>
                                        {{ item.code }}
                                    </td>
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>
                                        <p class="text-hidden">
                                           <span class="text-hidden-color"> {{ item.name_manager }} </span>
                                        </p>
                                    </td>
                                    <td>
                                        {{ item.web_site }}
                                    </td>
                                    <td>
                                       <v-chip>
                                        {{ item.contact_information.phone ? item.contact_information.phone.prefix : null}} {{ item.contact_information.phone ? item.contact_information.phone.phone : "S/N" }}
                                       </v-chip>
                                    </td>
                                    <td>
                                        {{ item.contact_information.email ? item.contact_information.email.email : null }}
                                    </td>
                                    <td>
                                        <p class="text-hidden">
                                           <span class="text-hidden-color"> {{ item.contact_information.address }} </span>
                                        </p>
                                    </td>
                                    <td>
                                        <v-btn icon @click="editTemporalSupplier(item)" title="Editar proveedor" v-if="can('editar proveedores')">
                                            <editIcon class="icon-custom">
                                            </editIcon>
                                        </v-btn>
                                        <v-btn icon :to="'/materiales_por_proveedor/'+item.id" title="Ver materiales" v-if="can('consultar material por proveedores')">
                                            <materialIcon class="icon-custom">
                                            </materialIcon>
                                        </v-btn>
                                        <v-btn icon @click="openAddInformationDialog(item.id)" title="Agregar información adicional" v-if="can('editar proveedores')">
                                            <v-icon color="black" dark fab>
                                                fas fa-plus
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="deleteSupplier(item.id)" title="Borrar proveedor" v-if="can('borrar proveedores')">
                                            <deleteIcon class="icon-custom">
                                            </deleteIcon>
                                        </v-btn>    
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:body.prepend>
                                <tr v-if="temporalSupplier.addTemporalSupplier == true">
                                    <td>
                                        <v-text-field 
                                            v-model="temporalSupplier.code" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            :rules="inputRules"
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="temporalSupplier.name" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            :rules="inputRules"
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="temporalSupplier.name_manager" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="temporalSupplier.web_site" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-row>
                                            <v-col cols="3" class="d-flex align-center mr-3">
                                                <vue-country-code 
                                                    v-model="temporalSupplier.phone[0].prefix"
                                                    :disabledFetchingCountry="true"
                                                    :enabledCountryCode="false"
                                                    :preferredCountries="preferredCountries"
                                                    @onSelect="selectCountryCode"
                                                    class="prefix-code"
                                                    defaultCountry="MX"
                                                />
                                            </v-col>
                                            <v-col cols="8">   
                                                <v-text-field 
                                                    v-model="temporalSupplier.phone[0].phone" 
                                                    outlined 
                                                    v-mask="'(###) ###-####'"
                                                    background-color="#f3f4f9" 
                                                    input-alternative input-focused-alternative input-icon
                                                    class="data-table"
                                                    :rules="inputPhoneRules"
                                                ></v-text-field>
                                            </v-col> 
                                        </v-row>
                                    </td>
                                    <td>
                                        <v-text-field 
                                            v-model="temporalSupplier.email[0].email" 
                                            outlined 
                                            background-color="#f3f4f9" 
                                            input-alternative input-focused-alternative input-icon
                                            type="email"
                                            class="data-table"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-textarea 
                                            v-model="temporalSupplier.address" 
                                            outlined 
                                            rows="1" 
                                            background-color="#f3f4f9" 
                                            input-alternative 
                                            input-focused-alternative input-icon
                                            class="data-table"
                                        ></v-textarea>
                                    </td>
                                    <td>
                                        <v-btn icon @click="createSupplier(temporalSupplier)" :loading="btn_confirm" rel="tooltip" title="Crear proveedor">
                                            <v-icon dark color="green" fab class="font-size-3k">
                                                fas fa-check
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="cancelTemporalSupplier()" rel="tooltip" :loading="btn_confirm" title="Cancelar creación proveedor">
                                            <v-icon dark color="red" fab class="font-size-3k">
                                                fas fa-times
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:footer>
                                <v-row class="card-padding">
                                    <v-col cols="12" lg="3">
                                        <span class="text-body me-3 text-sm d-flex">Mostrando del {{ suppliers.from }} de {{ suppliers.total }} registros</span>
                                    </v-col>
                                    <v-col cols="12" lg="9"  class="ml-auto d-flex justify-end">
                                        <v-pagination
                                        prev-icon="fa fa-angle-left"
                                        next-icon="fa fa-angle-right"
                                        class="pagination font-size-3k"
                                        color="blue"
                                        v-model="suppliers.current_page"
                                        :length="suppliers.last_page"
                                        circle
                                        @input="getSuppliers"
                                        :total-visible="9"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:no-data>
                                No hay proveedores registrados.
                            </template>
                        </v-data-table>
                    </v-form>
                </v-col>
            </v-row>    
        </v-card>
        <DialogFilterComponent
          :show.sync="showFilterDialog"
          @updateSearch="getSuppliersFilter"
          :clear_filter.sync="clear_filter"
        ></DialogFilterComponent>
        <DialogAddInformationComponent
          :show.sync="showAddInformationDialog"
          :supplier_id.sync="supplier_id"
        ></DialogAddInformationComponent>
    </v-container>
</template>
<script>
import DialogFilterComponent from './DialogFiltersSupplierComponent.vue';
import DialogAddInformationComponent from './DialogAddInformationComponent.vue';
import debounce from "lodash.debounce";
export default{
    data(){
        return {
            search: "",
            headers: [
                { text: 'Codigo', value: 'code', sortable: false },
                { text: 'Nombre', value: 'name', sortable: false },
                { text: 'Nombre del responsable', value: 'name_manager', sortable: false, width: '200px' },
                { text: 'Web Site', value: 'web_site', sortable: false },
                { text: 'Teléfono', value: 'contact_information.phone', sortable: false },
                { text: 'Correo', value: 'contact_information.email', sortable: false },
                { text: 'Domicilio', value: 'contact_information.address', sortable: false, width: '200px' },
                { text: 'Acciónes', value: '', sortable: false }
            ],
            itemsPerPage: 10,
            suppliers: {
                data: []
            },
            supplier: {},
            addTemporalSupplier: false,
            editSupplier: false,
            editSupplierId: null,
            inputRules: [
                value => !!value || 'Este campo es obligatorio.'
            ],
            inputPhoneRules : [
                value => !!value || 'Este campo es obligatorio.',
                value => (value && value.length >= 14) || 'El teléfono debe tener 10 digitos.',
                value => (value && value.length <= 14) || 'El teléfono debe tener 10 digitos.',
            ],
            inputEmailRules: [
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'El correo debe ser valido'
            ],
            preferredCountries: [
                'MX',
                'US',
                'CA'
            ],
            prefix: null,
            temporalSupplier: {},
            mask: ['(', /\d/, /\d/, /\d/, ') ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
            sortBy: null,
            order: "Descendente",
            showFilterDialog: false,
            clear_filter: false,
            showAddInformationDialog: false,
            supplier_id: null,
            btn_confirm: false
        }
    },
    components: {
        DialogFilterComponent,
        DialogAddInformationComponent,
    },
    methods: {
        getSuppliers(page = 1){
            this.clear_filter = false;
            let suppliers;
            this.$http.get("/proveedores", {
                params: {
                    page: page,
                    query: this.search,
                    perPage: this.itemsPerPage,
                    sortBy: this.sortBy,
                    order: this.order,
                }
            })
            .then(response => {
                 suppliers = response.data;
                 this.suppliers = response.data;
            })
            .then(() => {
                this.suppliers.data = suppliers.data.map(supplier => {
                    const phones = JSON.parse(supplier.contact_information.phone);
                    const emails = JSON.parse(supplier.contact_information.email);
                    const principalPhone = phones.find(phone => phone.isPrincipal);
                    const principalEmail = emails.find(email => email.isPrincipal)
                    return {
                        ...supplier,
                        contact_information: {
                            ...supplier.contact_information,
                            phone: principalPhone ? principalPhone : null,
                            email: principalEmail ? principalEmail : null,
                        },
                    };
                });
            })
            .catch((error) => {
                console.error(error);
            });
        },
        createTemporalSupplier(){
            this.addTemporalSupplier = true;
            var supplier;
            if(this.suppliers.data.length != 0){
                supplier = {
                    code: "",
                    name: "",
                    name_manager: "",
                    web_site: "",
                    description: "",
                    phone: [
                        {
                            isPrincipal: true,
                            phone: "",
                            prefix: this.prefix
                        },
                    ],
                    email: [
                        {
                            isPrincipal: true,
                            email: ""
                        },
                    ],
                    address: "",
                    addTemporalSupplier: this.addTemporalSupplier
                }
            }else{
                supplier = {
                    code: "",
                    name: "",
                    name_manager: "",
                    web_site: "",
                    description: "",
                    phone: [
                        {
                            isPrincipal: true,
                            phone: "",
                            prefix: this.prefix
                        },
                    ],
                    email: [
                        {
                            isPrincipal: true,
                            email: ""
                        },
                    ],
                    address: "",
                    addTemporalSupplier: this.addTemporalSupplier
                }
            }
            this.temporalSupplier = supplier;
        },
        cancelTemporalSupplier(){
            this.temporalSupplier = {};
            this.addTemporalSupplier = false;
        },
        cancelEditSupplier(){
            this.editSupplier = false;
            this.suppliers.data.find((element) => element.id == this.editSupplierId).editSupplier = this.editSupplier;
            this.editSupplierId = null;
            this.getSuppliers(this.suppliers.current_page);
        },
        createSupplier(supplier){
            this.btn_confirm = true;
            this.supplier = supplier;
            this.supplier.phone[0].prefix = this.prefix;
            
            if(this.$refs.formSupplier.validate() == true){
                this.$http.post("/proveedores/create", this.supplier)
                .then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.addTemporalSupplier = false;
                        this.temporalSupplier = {};
                        this.getSuppliers();
                        this.supplier = {};
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.btn_confirm = false;                   
                });
            }else{
                this.btn_confirm = false;
            }
        },
        editTemporalSupplier(supplier){
            this.editSupplier = true;
            this.editSupplierId = supplier.id;
            this.suppliers.data.find((element) => element.id == supplier.id).editSupplier = this.editSupplier;
        },
        updateSupplier(supplier){
            this.btn_confirm = true;
            supplier.contact_information.phone.prefix = this.prefix;
            var updateSupplier = {
                id: supplier.id,
                code: supplier.code,
                name: supplier.name,
                name_manager: supplier.name_manager,
                web_site: supplier.web_site,
                description: supplier.description,
                contact_information_id: supplier.contact_information_id,
                // phone_prefix: supplier.contact_information.phone_prefix,
                phone: supplier.contact_information.phone,
                email: supplier.contact_information.email,
                address: supplier.contact_information.address
            }
            this.$http.patch(`/proveedores/update/${supplier.id}`,updateSupplier)
            .then(response => {
                if(response.status == 200){
                    this.$swal.fire("", response.data.message, "success");
                    this.editSupplier = false;
                    this.getSuppliers();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.btn_confirm = false;                   
            });
        },
        deleteSupplier(id){
            this.$swal({
                title: "Confirmación",
                text: " ¿Estas seguro de borrar este registro?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                }).then((result) => {
                if (result.value) {
                    this.$http.delete(`/proveedores/destroy/${id}`)
                    .then(response => {
                        this.$swal.fire("", response.data.message, "success");
                        this.getSuppliers();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                } 
            });
        },
        selectCountryCode: function ({ dialCode }) {
            this.prefix = "+"+dialCode;
        },
        openFilterDialog(){
            this.showFilterDialog = true;
        },
        getSuppliersFilter(datos){
            this.order = datos.orderBy;
            this.sortBy = datos.orderType;
            this.getSuppliers();
        },
        clearFilter(){
            this.clear_filter = true;
        },
        openAddInformationDialog(supplier_id){
            this.showAddInformationDialog = true;
            this.supplier_id = supplier_id;
        },
        downloadExport(){
            this.$http.get('/exportar_proveedores', { responseType: 'blob' })
            .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'proveedores.xlsx'); 
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            })
            .catch(error => {
            console.error('Error al descargar el archivo Excel:', error);
            });
        }
    },
    created(){
        this.getSuppliers();
        this.debounceSearchSuppliers = debounce(() => {
            this.getSuppliers();
        }, 200);
    },
    watch: {
        search () {
            this.debounceSearchSuppliers();
        },
        temporalSupplier () {
            this.temporalSupplier;
        },
    }
}
</script>
<style scoped>
.v-data-table >>> .v-data-table-header {
    background-color: #f3f4f9 !important;
    color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
    color: black !important;
}
.card-margin-proveedores{
    margin-top: 3vh !important;
}
.data-table{
    padding-top: 12px !important;
    margin-top: 10px !important;
}
.prefix-code{
    margin-bottom: 8px;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
.v-data-table.row-height td{
  height: 18px !important;
}
</style>
