<template>
  <div>
    <v-data-table
        :headers="headers"
        item-key="id"
        :items="bidComponent.bid_equipments"
        class="elevation-0"
        :hide-default-footer="true"
        dense
    >
        <template v-slot:top>
            <v-row>
                <v-col cols="12" lg="3" class="d-flex justify-start">
                    <v-text-field
                        v-model="search"
                        input-alternative input-focused-alternative input-icon
                        class="ml-5"
                        outlined
                        placeholder="Buscar"
                        background-color="#f3f4f9"
                    >
                        <v-icon slot="prepend-inner">fas fa-search</v-icon>
                    </v-text-field>
                </v-col>
                <v-col cols="12" lg="9" class="d-flex justify-end">
                    <v-btn @click="downloadExport()" outlined color="green" class="mr-4" title="Exportar">
                        Exportar
                    </v-btn>
                    <add-equipment-to-price-quote-component class="mr-4" :bid="bid" @update="getBid()"/>
                </v-col>
            </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn icon @click="deleteBidEquipment(item)" v-if="can('eliminar equipo cotización')">
                <deleteIcon class="icon-custom" />
            </v-btn>
            <v-btn icon :to="'/equipos/relacion_materiales/'+item.equipment.id" title="Ver equipo">
                <materialIcon class="icon-custom" />
            </v-btn>
        </template>
        <template v-slot:item.equipment.date="{ item }">
            {{ formatDate.dayMonthAndYear(item.equipment.date) }}
        </template>
        <template v-for="(type, index) in materialTypes" v-slot:[`item.equipment.sum.types[${index}].total`]="{ item }">
            <div class="text-end">{{ item.equipment.sum.types[index].total | VMask(currencyMask) }}</div>
        </template>
        <template v-slot:item.sum_equipment_factor.sum="{ item }">
            <div class="text-end">{{  item.sum_equipment_factor ? item.sum_equipment_factor.sum : "0" | VMask(currencyMask) }}</div>
        </template>
        <template v-slot:item.sum_equipment_factor.pesos_with_iva="{ item }">
            <div class="text-end">{{  item.sum_equipment_factor ? item.sum_equipment_factor.pesos_with_iva : "0" | VMask(currencyMask) }}</div>
        </template>
        <template v-slot:item.sum_equipment_factor.dollars="{ item }">
            <div class="text-end">{{  item.sum_equipment_factor ? item.sum_equipment_factor.dollars : "0" | VMask(currencyMask) }}</div>
        </template>
        <template v-slot:item.sum_equipment_factor.utility_in_pesos="{ item }">
            <div class="text-end">{{  item.sum_equipment_factor ? item.sum_equipment_factor.utility_in_pesos : "0" | VMask(currencyMask) }}</div>
        </template>
        <template v-slot:no-data>
            No hay registros.
        </template>
    </v-data-table>
  </div>
</template>

<script>
import AddEquipmentToPriceQuoteComponent from '@/components/App/Quotations/AddEquipmentToPriceQuoteComponent.vue';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import debounce from "lodash.debounce";
const currencyMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default {
    components:{
        AddEquipmentToPriceQuoteComponent
    },
    props: {
        bid: Object,
        materialTypes: Array
    },
    data() {
        return {
            currencyMask,
            search: "",
            bidComponent: {}
        }
    },
    methods: {
        deleteBidEquipment(item) {
            this.$swal({
                title: "Confirmación",
                text: " ¿Estas seguro de borrar este registro?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                }).then((result) => {
                if (result.value) {
                    this.$http.delete(`/bid_equipments/destroy/${item.id}`,{
                        params: {
                            bid_id: this.bid.id
                        }
                    })
                    .then(response => {
                        this.$swal.fire("", response.data.message, "success");
                        this.$emit('update')
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                } 
            });
        },
        async downloadExport(){
            let bidId = this.bid.id;
            this.$http.get(`/exportarBid/${bidId}`, { responseType: 'blob' })
            .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'cotizacion.xlsx'); 
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            })
            .catch(error => {
            console.error('Error al descargar el archivo Excel:', error);
            });
        },
        refreshComponent(){
            this.emit('update');
        },
        getBid(){
            let bidId = this.bid.id;
            this.$http.get(`/bids/show/${bidId}`,{
                params: {
                    query: this.search,
                }
            })
            .then(response => {
                this.bidComponent = response.data;
                this.$emit('update');
            })
            .then(() => {
                try {
                    this.$http.get("/tipos_materiales")
                    .then(response => {
                        this.materialTypesComponent = response.data.data;
                    })
                    
                } catch (error) {
                    console.error(error)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        async searchDebounce(){
            let bidId = this.bid.id;
            await this.$http.get(`/bids/show/${bidId}`,{
                params: {
                    query: this.search,
                }
            })
            .then(response => {
                if(response.data == ""){
                    this.bidComponent = {};
                }else{
                    this.bidComponent = response.data;
                }
                
            })
            .catch((error) => {
                console.error(error);
            });
        },
    },
    mounted(){
        this.debounceSearchBids = debounce(() => {
            this.searchDebounce();
        }, 200);
    },
    computed: {
        headers() {
            let header = [
                { text: 'Clave', value: 'equipment.key', sortable: false},
                { text: 'Nombre', value: 'equipment.name', sortable: false},
                { text: 'Fecha', value: 'equipment.date', sortable: false},
            ]
            let materialTypes = this.materialTypes.map(item => ({
                text: item.description,
                value: `equipment.sum.types[${item.id - 1}].total`, 
                sortable: false,  
            }));

            header = header.concat(materialTypes);
            let objectTotal = {
                 'text': 'Suma', value: 'sum_equipment_factor.sum', 'sortable': false,
            }
            header.push(objectTotal)
            let objectFactorIndividual = {
                 'text': 'Factor individual', value: 'equipment_factor.individual_factor', 'sortable': false,
            }
            header.push(objectFactorIndividual)
            let objectPesosWithIva = {
                 'text': 'Pesos con IVA', value: 'sum_equipment_factor.pesos_with_iva', 'sortable': false,
            }
            header.push(objectPesosWithIva)
            let objectDollars = {
                 'text': 'Dólares', value: 'sum_equipment_factor.dollars', 'sortable': false,
            }
            header.push(objectDollars)
            let objectUtility = {
                 'text': 'Utilidad en pesos', value: 'sum_equipment_factor.utility_in_pesos', 'sortable': false,
            }
            header.push(objectUtility)
            let objectTotalPesos = {
                 'text': 'Factor total', value: 'equipment_factor.individual_total_factor', 'sortable': false,
            }
            header.push(objectTotalPesos)
            let objectActions = {
                text: 'Acciones', value: 'actions', 'sortable': false,
            }
            header.push(objectActions)
            return header
        },
    },
    watch: {
        search () {
            this.debounceSearchBids();
        },
        bid: {
            handler(newBid) {
                this.bidComponent = { ...newBid };
            },
            immediate: true
        }
    }
}
</script>

<style scoped>
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
</style>