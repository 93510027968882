<template>
    <v-container fluid>
        <v-card class="card-margin-quotations">
            <v-row>
                <v-col cols="12">
                    <v-form ref="formBids" @submit.prevent="getBids()">
                        <v-data-table
                            :headers="headers"
                            item-key="id"
                            :items="bids.data"
                            class="elevation-0"
                            :hide-default-footer="true"
                            dense
                        >
                            <template v-slot:group.header="{group, isOpen, toggle}">
                                <td :colspan="headers.length" class="grey white--text">
                                    <v-btn color="white" icon>
                                        <v-icon @click="toggle">
                                            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                                        </v-icon>
                                    </v-btn>
                                <span>{{ group }}</span>
                                </td>
                            </template>
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <div class="card-header-padding">
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Cotizaciones
                                            </h5>
                                            <p class="text-sm text-body font-weight-light mb-0">
                                            Registro de Cotizaciones
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="search"
                                            input-alternative input-focused-alternative input-icon
                                            class="ml-5"
                                            outlined
                                            placeholder="Buscar"
                                            background-color="#f3f4f9"
                                        >
                                            <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="3" class="d-flex justify-start">
                                        
                                    </v-col>    
                                    <v-col cols="12" lg="6" class="card-header-padding d-flex justify-end">
                                        <v-btn v-if="!addTemporalBid && !editingItem && can('crear cotización')" outlined color="blue" @click="createTemporalBid()" title="Crear Cotización">
                                            Crear cotización
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item.name="{ item }">
                                <v-text-field
                                    v-if="item.isTemporal == true || item.isEditing == true"
                                    outlined
                                    background-color="#f3f4f9"
                                    input-alternative input-focused-alternative input-icon
                                    v-model="item.name"
                                    class="data-table"
                                    required
                                    :rules="inputRules"
                                    clearable
                                    @input="addPrefix(item)"
                                ></v-text-field>
                                <span v-else>{{ item.name }}</span>
                            </template>
                            <template v-slot:item.description="{ item }">
                                <v-text-field v-if="item.isTemporal == true || item.isEditing == true" 
                                    outlined
                                    background-color="#f3f4f9"
                                    input-alternative input-focused-alternative input-icon
                                    v-model="item.description"
                                    class="data-table"
                                    required  
                                    :rules="inputRules"
                                    clearable
                                    ></v-text-field>
                                <span v-else>{{ item.description }}</span>
                            </template>
                            <template v-slot:item.date="{ item }">
                                <v-menu
                                    v-model="showPicker"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    full-width
                                    max-width="400px"
                                    min-width="400px"
                                    v-if="item.isTemporal == true || item.isEditing == true" 
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="formattedDate"
                                            readonly
                                            v-on="on"
                                            outlined
                                            background-color="#f3f4f9"
                                            :rules="inputRules"
                                            input-alternative input-focused-alternative input-icon
                                            prepend-inner-icon="mdi-calendar"
                                            class="data-table"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="item.date"
                                        no-title
                                        locale="es"
                                        width="400px"
                                        @input="showPicker = false, dayMonthAndYear(item.date)"
                                    ></v-date-picker>
                                </v-menu>
                                <span v-else>{{ formatDate.dayMonthAndYear(item.date) }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-btn v-if="!item.isTemporal && !item.isEditing && editingItem === null && can('editar cotización')" icon @click="editBid(item), dayMonthAndYear(item.date)">
                                    <editIcon class="icon-custom" />
                                </v-btn>
                                <v-btn v-if="!item.isTemporal && !item.isEditing && editingItem === null && can('ver equipos cotizaciones')" icon :to="'/cotizaciones/equipos/'+item.id">
                                    <v-icon fab color="black">
                                        mdi-sitemap
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="!item.isTemporal && !item.isEditing && editingItem === null && can('borrar cotización')" icon @click="deleteBid(item)">
                                    <deleteIcon class="icon-custom" />
                                </v-btn>
                                <v-btn v-if="item.isTemporal == true" icon @click="createBid(item)">
                                    <v-icon dark color="green" fab>
                                        fas fa-check
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="item.isTemporal == true" icon @click="cancelCreateBid()">
                                    <v-icon dark color="red" fab>
                                        fas fa-times
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="item.isEditing == true" icon @click="saveEditBid(item)">
                                    <v-icon dark color="green" fab>
                                        fas fa-check
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="item.isEditing == true" icon @click="cancelEditBid(item)">
                                    <v-icon dark color="red" fab>
                                        fas fa-times
                                    </v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:footer>
                                <v-row class="card-padding">
                                    <v-col cols="12" lg="3" sm="12">
                                        <span class="text-body me-3 text-sm d-flex">Mostrando del {{ bids.from }} de {{ bids.total }} registros</span>
                                    </v-col>
                                    <v-col cols="12" lg="9" class="ml-auto d-flex justify-end">
                                        <v-pagination
                                        prev-icon="fa fa-angle-left"
                                        next-icon="fa fa-angle-right"
                                        class="pagination"
                                        color="blue"
                                        v-model="bids.current_page"
                                        :length="bids.last_page"
                                        circle
                                        @input="getBids"
                                        :total-visible="9"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:no-data>
                                No hay tipos de cambios registrados.
                            </template>
                        </v-data-table>
                    </v-form>
                </v-col>
            </v-row>    
        </v-card>
    </v-container>
</template>

<script>
import AddEquipmentToPriceQuoteComponent from '@/components/App/Quotations/AddEquipmentToPriceQuoteComponent.vue';
import debounce from "lodash.debounce";
export default {
    components:{
        AddEquipmentToPriceQuoteComponent
    },
    props: {
        factor: Object
    },
    data() {
        return {
            editingItem: null,
            inputRules: [
                value => !!value || 'Este campo es obligatorio.'
            ],
            addTemporalBid: false,
            editingBid: false,
            materialTypes: [],
            search: "",
            itemsPerPage: 10,
            bids: [],
            sortBy: 'id',
            order: 'desc',
            showPicker: false,
            formattedDate: 'DD/MM/AAAA'
        }
    },
    methods: {
        addPrefix(bid) {
            const prefix = 'ListaPrCot';
            if (!bid.name.startsWith(prefix)) {
                bid.name = `${prefix}${bid.name}`;
            } else if (bid.name === prefix) {
                // Si el valor es igual al prefijo, no permitir borrar
                bid.name = prefix;
            }
        },
        deleteBid(item) {
            this.$swal({
                title: "Confirmación",
                text: " ¿Estas seguro de borrar este registro?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                }).then((result) => {
                if (result.value) {
                    this.$http.delete(`/bids/destroy/${item.id}`)
                    .then(response => {
                        this.$swal.fire("", response.data.message, "success");
                        this.getBids();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                } 
            });
        },
        saveEditBid(bid) {
            if (!bid.name || !bid.description || !bid.date) {
                this.$swal.fire("", "Hay campos sin llenar", "warning");
            return;
            }
            this.$http.patch("bids/update/"+bid.id, bid)
            .then(response => {
                if(response.status == 200){
                    this.$swal.fire("", response.data.message, "success");
                    this.editingItem = null;
                    this.getBids();
                    this.formattedDate = 'DD-MM-AAAA';
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        cancelEditBid(bid) {
            if (this.editingItem != null) {
                this.editingItem = null;
                this.$set(bid, 'isEditing', false);
                this.formattedDate = 'DD-MM-AAAA';
            }
        },
        editBid(bid) {
            if (this.editingItem === null) {
                this.editingItem = bid;
                this.$set(bid, 'isEditing', true);
            }
        },
        createBid(bid) {
            if (!bid.name || !bid.description || !bid.date) {
                this.$swal.fire("", "Hay campos sin llenar", "warning");
            return;
            }
            this.$http.post("bids/create", bid)
            .then(response => {
                if(response.status == 200){
                    this.$swal.fire("", response.data.message, "success");
                    this.addTemporalBid = false;
                    this.getBids();
                    this.formattedDate = 'DD-MM-AAAA';
                }
            })
            .catch((error) => {
                console.error(error);
            });

        },
        cancelCreateBid(){
            const index = this.bids.data.findIndex(bid => bid.isTemporal);
            if (index !== -1) {
                this.bids.data.splice(index, 1);
            }
            this.formattedDate = 'DD-MM-AAAA';
            this.addTemporalBid = this.bids.data.some(bid => bid.isTemporal);
        },
        createTemporalBid() {
            this.addTemporalBid = true
            let id; 
            if (this.bids.data.length != 0) {
                id = this.bids.data[this.bids.data.length -1].id +1
            }
            else {
                id = 1
            }
            let bid = {
                id: id,
                name: "",
                description: "",
                date: "",
                isTemporal: true
            }
            this.bids.data.unshift(bid);
        },
        async getBids(page = 1){
            await this.$http.get("/bids", {
                params: {
                    page: page,
                    query: this.search,
                    perPage: this.itemsPerPage,
                    sortBy: this.sortBy,
                    order: this.order
                }
            })
            .then(response => {
                this.bids = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        dayMonthAndYear(dateItem) {
            const date = new Date(dateItem);

            const month = (date.getMonth() + 1).toString().padStart(2, '0');

            const day = (date.getDate() + 1);

            const year = date.getFullYear();

            const formatted = `${day}/${month}/${year}`;
            this.formattedDate = formatted;
        }
    },
    computed: {
        headers() {
            let header = [
                { text: 'Nombre', value: 'name', sortable: false, width: '400px' },
                { text: 'Descripción', value: 'description', sortable: false, width: '400px'},
                { text: 'Fecha', value: 'date', sortable: false},
                { text: 'Acciones', value: 'actions', 'sorteable': false},
            ]
            return header
        }
    },
    mounted() {
        this.getBids();
        this.debounceSearchBids = debounce(() => {
            this.getBids();
        }, 200);
    },
    watch: {
        search () {
            this.debounceSearchBids();
        },
    }
}
</script>

<style scoped>
.v-data-table >>> .v-data-table-header {
    background-color: #f3f4f9 !important;
    color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
    color: black !important;
}
.data-table{
    padding-top: 12px !important;
    margin-top: 10px !important;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
    margin-top: 11px !important;
}
.card-margin-quotations{
    margin-top: 3vh !important;
}
</style>