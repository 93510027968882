<template>
  <v-container fluid class="padding-container"> 
        <v-card>
            <v-row>
                <v-col cols="12">
                  <v-data-table
                      :headers="headers"
                      item-key="id"
                      :items="materialsRelationated"
                      class="elevation-0"
                      :items-per-page="10"
                      hide-default-footer
                      dense
                      :page.sync="page"
                      @page-count="pageCount = $event"
                  >
                      <template v-slot:top>
                          <v-row>
                              <v-col cols="12" lg="3" class="d-flex justify-start">
                                  <!-- <v-text-field
                                      v-model="search"
                                      input-alternative input-focused-alternative input-icon
                                      class="ml-5"
                                      outlined
                                      placeholder="Buscar"
                                      background-color="#f3f4f9"
                                  >
                                      <v-icon slot="prepend-inner">fas fa-search</v-icon>
                                  </v-text-field> -->
                              </v-col>
                              <v-col cols="12" lg="3" class="d-flex justify-start">
                                  <!-- <v-btn icon @click="openFilterDialog" title="Filtros" >
                                      <v-icon dark>
                                          fas fa-filter
                                      </v-icon>
                                  </v-btn> -->
                              </v-col>    
                              <v-col cols="12" lg="6" class="card-header-padding d-flex justify-end">
                                  <v-btn v-if="!addTemporalEquipment && !editEquipment && can('exportar materiales equipos')" @click="downloadExport()" outlined color="green" class="mr-4" title="Exportar">
                                      Exportar
                                  </v-btn>
                                  <add-material-to-equipment-component ref="addMaterials" @update="updateRelationToMaterials()" v-if="can('agregar materiales equipos')" />
                              </v-col>
                          </v-row>
                      </template>
                      <template v-slot:footer>
                          <v-row class="card-padding">
                              <v-col cols="12" lg="3">
                                  <span class="text-body me-3 text-sm d-flex">Mostrando del {{ page }} de {{ materialsRelationated.length }} registros</span>
                              </v-col>
                              <v-col cols="12" lg="9" class="ml-auto d-flex justify-end">
                                  <v-pagination
                                  prev-icon="fa fa-angle-left"
                                  next-icon="fa fa-angle-right"
                                  class="pagination"
                                  color="blue"
                                  v-model="page"
                                  :length="pageCount"
                                  circle
                                  :total-visible="9"
                                  ></v-pagination>
                              </v-col>
                          </v-row>
                      </template>
                      <template v-slot:item.item.prices.unit_price_pesos="{ item }">
                        <div class="text-end">{{item.item.prices.unit_price_pesos | VMask(currencyMask) }}</div>
                      </template>
                      <template v-slot:item.item.prices.unit_price_dollars="{ item }">
                        <div class="text-end">{{item.item.prices.unit_price_dollars | VMask(currencyMask) }}</div>
                      </template>
                      <template v-slot:item.item.prices.parity="{ item }">
                        <div class="text-end">{{item.item.prices.parity | VMask(currencyMask) }}</div>
                      </template>
                      <template v-slot:item.actions="{ item }">
                          <v-btn icon @click="deleteMaterial(item)" v-if="can('eliminar materiales equipos')">
                              <deleteIcon class="icon-custom">
                              </deleteIcon>
                          </v-btn> 
                      </template>
                  </v-data-table>
                </v-col>
            </v-row>    
        </v-card>
  </v-container>
</template>

<script>
import debounce from "lodash.debounce";
import AddMaterialToEquipmentComponent from "@/components/App/Equipments/RelationToMaterials/AddMaterialToEquipmentComponent.vue"
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default {
  name: "relation-to-materials-table",
  components: {
    AddMaterialToEquipmentComponent
  },
  data() {
      return {
        materialsRelationated: [],
        page: 1,
        pageCount: 0,
        materialRelationatedId: null,
        currencyMask
      }
  },
  methods: {
    updateAddMaterials() {
        this.$refs.addMaterials.getMaterials();
        this.$refs.addMaterials.getRelationToMaterials();
        
    },
    deleteMaterial(item) {
        this.$swal({
            title: "Confirmación",
            text: " ¿Estas seguro de borrar este registro?",
            type: "warning",
            showCancelButton: true,
            customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            }).then((result) => {
            if (result.value) {
                const index = this.materialsRelationated.indexOf(item)
                if (index >= 0) this.materialsRelationated.splice(index, 1)
                
                this.$http.patch(`/deleteMaterialsToEquipment/${this.materialRelationatedId}`, {
                    materials: this.materialsRelationated,
                    equipmentId: this.$route.params.equipment_id,
                })
                .then(response => {
                    if(response.status == 200){
                        this.$swal.fire("", response.data.message, "success");
                        this.getRelationToMaterials();
                        this.updateAddMaterials()
                    }
                })
                .catch((error) => {
                    this.$swal.fire("", error.response.data.errors, "error");
                })
            } 
        });
    },

    async getRelationToMaterials(){
        let equipmentId = this.$route.params.equipment_id;
        await this.$http.get(`/getRelationByEquipment/${equipmentId}`)
        .then(response => {
           if(response.data.materials == null){
            this.materialRelationated = [];
           }else{
            this.materialsRelationated = JSON.parse(response.data.materials);
            this.materialRelationatedId = response.data.id;
           }
        })
        .then(() => {
            this.$emit('update');
        })
        .catch((error) => {
            console.error(error);
        });
    },
    deleteEquipment(id){
        this.$swal({
            title: "Confirmación",
            text: " ¿Estas seguro de borrar este registro?",
            type: "warning",
            showCancelButton: true,
            customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            }).then((result) => {
            if (result.value) {
                this.$http.delete(`/equipos/destroy/${id}`)
                .then(response => {
                    this.$swal.fire("", response.data.message, "success");
                    this.getEquipments();
                })
                .catch((error) => {
                    console.error(error);
                });
            } 
        });
    },
    async downloadExport(){
        let equipmentId = this.$route.params.equipment_id;
        this.$http.get(`/exportar_relacion_materiales/${equipmentId}`, { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'relacion materiales.xlsx'); 
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        })
        .catch(error => {
            console.error('Error al descargar el archivo Excel:', error);
        });
    },
    updateRelationToMaterials(){
        let equipmentId = this.$route.params.equipment_id;
        this.$http.get(`/getRelationByEquipment/${equipmentId}`)
        .then(response => {
            console.log(response);
        this.materialsRelationated = JSON.parse(response.data.materials);
        this.materialRelationatedId = response.data.id;
        })
        .then(() => {
            this.$emit('update');
        })
        .catch((error) => {
            console.error(error);
        });
    }
  },
  computed: {
      headers() {
          let header = [
              { text: 'Clave', value: 'item.code', sortable: false},
              { text: 'Concepto', value: 'item.concept', sortable: false},
              { text: 'Proveedor', value: 'item.suppliers.code', sortable: false},
              { text: 'Tipo', value: 'item.types_materials.type', sortable: false},
              { text: 'Cantidad', value: 'quantity', sortable: false},
              { text: 'Unidad', value: 'item.units.description', sortable: false},
              { text: 'Pesos', value: 'item.prices.unit_price_pesos', sortable: false},
              { text: 'Dolares', value: 'item.prices.unit_price_dollars', sortable: false},
              { text: 'Paridad', value: 'item.prices.parity', sortable: false},
              { text: 'Acciones', value: 'actions', sortable: false},
            //   { text: 'Suma', value: 'sum'},
              
          ]
          // let materialTypes =  this.materialTypes.map(item => item.description);
          // materialTypes.forEach(item => {
          //     let itemObject = {
          //         'text': item,
          //         'value': "",
          //         'sorteable': false,
          //     }
          //     header.push(itemObject)
          // })
          // let objectActions = {
          //      'text': 'Acciones', 'value': '', 'sorteable': false,
          // }
          // header.push(objectActions)
          return header
      }
  },
  mounted() {
      this.getRelationToMaterials();
  }

}
</script>

<style scoped>
.v-data-table >>> .v-data-table-header {
  background-color: #f3f4f9 !important;
  color: black !important;
}
.v-data-table >>> .v-data-table-header > tr > th > span{
  color: black !important;
}
.card-margin-type-material{
  margin-top: 20vh !important;
}
.data-table{
  padding-top: 12px !important;
  margin-top: 10px !important;
}
.v-text-field--enclosed >>> .v-input__prepend-inner{
  margin-top: 11px !important;
}
.padding-container{
    padding: 0px !important;
}
</style>