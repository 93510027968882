import AppLayout from "@/App.vue"
import AuthLayout from "@/views/App/Layouts/Authentication/AuthLayout.vue"
// import SignUp from "@/views/App/Layouts/Authentication/SignUp/SignUpLayout.vue"
import SignInLayout from "@/views/App/Layouts/Authentication/SignIn/SignInLayout.vue"
import HomeLayout  from "@/views/App/Layouts/Home/HomeLayout.vue"
import DashboardLayout from "@/views/App/Layouts/Dashboard/DashboardLayout.vue"
import Supplier from "@/views/App/Layouts/Suppliers/SupplierTableComponent.vue"
import Material from "@/views/App/Layouts/Materials/MaterialTableComponent.vue"
import TypeMaterial from "@/views/App/Layouts/TypeMaterial/TypeMaterialTableComponent.vue"
import MaterialForSupplier from "@/views/App/Layouts/Suppliers/MaterialTableSupplierComponent.vue"
import Unit from "@/views/App/Layouts/Unit/UnitTableComponent.vue"
import Log from "@/views/App/Layouts/Logs/LogsTableComponent.vue"
import User from "@/views/App/Layouts/Administrator/UserTableComponent.vue"
import UserForPermission from "@/views/App/Layouts/Administrator/PermissionTableComponent.vue"
import ExchangeRateLayout from "@/views/App/Layouts/ExchangeRate/ExchangeRateLayout.vue"
import EquipmentLayout from "@/views/App/Layouts/Equipments/EquipmentLayout.vue"
import RelationToMaterialsLayout from "@/views/App/Layouts/Equipments/RelationToMaterials/RelationToMaterialsLayout.vue"
import PlantLayout from "@/views/App/Layouts/Plants/PlantLayout.vue"
import CrusherLayout from "@/views/App/Layouts/Crushers/CrusherLayout.vue"
import NoPlantNoCrusherLayout from "@/views/App/Layouts/NoPlantsNoCrushers/NoPlantNoCrusherLayout.vue"
import PriceQuoteLayout from "@/views/App/Layouts/Quotations/PriceQuoteLayout.vue"
import EquipmentBidsLayout from "@/views/App/Layouts/Quotations/Equipments/EquipmentBidsLayout.vue"

const routes = [
    {
        path: "/",
        name: "AppLayout",
        component: AppLayout,
        children: [
            {
                path: "/authentication",
                name: "Authentication",
                component: AuthLayout,
                redirect: "authentication/sign-in",
                meta: {
                    groupName: "Authentications",
                    requiresAuth: false
                },
                children: [
                    // {
                    //     path: "sign-up",
                    //     name: "Authentication SignUp",
                    //     component: SignUp,
                    //     meta: {
                    //         groupName: "Authentications",
                    //         requiresAuth: false
                    //     },
                    // },
                    {
                        path: "sign-in",
                        name: "Authentication SignIn",
                        component: SignInLayout,
                        meta: {
                            groupName: "Authentications",
                            requiresAuth: false
                        },
                    },
            
                ]
            },
            {
                path: "/",
                name: "Home",
                component: HomeLayout,
                children: [
                    {
                        path: "/dashboard",
                        name: "Dashboard",
                        component: DashboardLayout,
                    },
                    {
                        path: "/proveedores",
                        name: 'Supplier',
                        component: Supplier,
                    },
                    {
                        path: "/materiales",
                        name: 'Material',
                        component: Material,
                    },
                    {
                        path: "/tipos_materiales",
                        name: 'TypeMaterial',
                        component: TypeMaterial,
                    },
                    {
                        path: "/unidades",
                        name: 'Unit',
                        component: Unit,
                    },
                    {
                        path: "/tipo_de_cambio",
                        name: 'ExchangeRateLayout',
                        component: ExchangeRateLayout,
                    },
                    {
                        path: "/materiales_por_proveedor/:supplier_id",
                        name: 'MaterialForSupplier',
                        component: MaterialForSupplier,
                    },
                    {
                        path: "/equipos",
                        name: 'EquipmentLayout',
                        component: EquipmentLayout,
                    },
                    {
                        path: "/equipos/relacion_materiales/:equipment_id",
                        name: 'RelationToMaterialsLayout',
                        component: RelationToMaterialsLayout,
                    },
                    {
                        path: "/bitacora",
                        name: 'Logs',
                        component: Log,
                    },
                    {
                        path: "/usuarios",
                        name: 'User',
                        component: User,
                    },
                    {
                        path: "/usuario_permisos/:user_id",
                        name: 'UserForPermission',
                        component: UserForPermission,
                    },
                    {
                        path: "/plantas",
                        name: 'PlantLayout',
                        component: PlantLayout,
                    },
                    {
                        path: "/trituradoras",
                        name: 'CrusherLayout',
                        component: CrusherLayout,
                    },
                    {
                        path: "/no_plantas_no_trituradoras",
                        name: 'NoPlantNoCrusherLayout',
                        component: NoPlantNoCrusherLayout,
                    },
                    {
                        path: "/cotizaciones",
                        name: 'PriceQuoteLayout',
                        component: PriceQuoteLayout,
                    },
                    {
                        path: "/cotizaciones/equipos/:bid_id",
                        name: 'EquipmentBidsLayout',
                        component: EquipmentBidsLayout,
                    },
                ]
            },
        ]
    },
    
    // {
    //     path: "/",
    //     component: AuthLayout,
    //     name: "Authentication SignUp",
    //     meta: { requiresAuth: false },
    //     children: [
    //       {
    //         path: "/sign-up",
    //         name: "Sign Up",
    //         component: SignUp,
    //       },
    //     ],
    //   },
    //   {
    //     path: "/",
    //     component: AuthLayout,
    //     name: "Authentication LogIn",
    //     meta: { requiresAuth: false },
    //     children: [
    //       {
    //         path: "/login",
    //         name: "Log In",
    //         component: LogIn,
    //       },
    //     ],
    //   },
];

export default routes;