var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"item-key":"id","items":_vm.bidComponent.bid_equipments,"hide-default-footer":true,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12","lg":"3"}},[_c('v-text-field',{staticClass:"ml-5",attrs:{"input-alternative":"","input-focused-alternative":"","input-icon":"","outlined":"","placeholder":"Buscar","background-color":"#f3f4f9"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("fas fa-search")])],1)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","lg":"9"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"green","title":"Exportar"},on:{"click":function($event){return _vm.downloadExport()}}},[_vm._v(" Exportar ")]),_c('add-equipment-to-price-quote-component',{staticClass:"mr-4",attrs:{"bid":_vm.bid},on:{"update":function($event){return _vm.getBid()}}})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.can('eliminar equipo cotización'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteBidEquipment(item)}}},[_c('deleteIcon',{staticClass:"icon-custom"})],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","to":'/equipos/relacion_materiales/'+item.equipment.id,"title":"Ver equipo"}},[_c('materialIcon',{staticClass:"icon-custom"})],1)]}},{key:"item.equipment.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate.dayMonthAndYear(item.equipment.date))+" ")]}},_vm._l((_vm.materialTypes),function(type,index){return {key:("item.equipment.sum.types[" + index + "].total"),fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("VMask")(item.equipment.sum.types[index].total,_vm.currencyMask)))])]}}}),{key:"item.sum_equipment_factor.sum",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("VMask")(item.sum_equipment_factor ? item.sum_equipment_factor.sum : "0",_vm.currencyMask)))])]}},{key:"item.sum_equipment_factor.pesos_with_iva",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("VMask")(item.sum_equipment_factor ? item.sum_equipment_factor.pesos_with_iva : "0",_vm.currencyMask)))])]}},{key:"item.sum_equipment_factor.dollars",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("VMask")(item.sum_equipment_factor ? item.sum_equipment_factor.dollars : "0",_vm.currencyMask)))])]}},{key:"item.sum_equipment_factor.utility_in_pesos",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("VMask")(item.sum_equipment_factor ? item.sum_equipment_factor.utility_in_pesos : "0",_vm.currencyMask)))])]}},{key:"no-data",fn:function(){return [_vm._v(" No hay registros. ")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }