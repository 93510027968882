export default {
    dayMonthAndYear(dateItem) {
        const date = new Date(dateItem);

        const month = (date.getMonth() + 1).toString().padStart(2, '0');

        const day = (date.getDate() + 1);

        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }
}